import { createApi } from "@reduxjs/toolkit/query/react";
import { accountApi } from '../account/account';
import customFetchBase from "../customFetchBase";
import { ILoginResponse } from "./interfaces/login-response.interface";
import Cookies from 'js-cookie';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  endpoints: build => ({
    loginUser: build.mutation<any, any>({
      query: (data) => ({
        url: '/auth/sign-in',
        method: 'POST',
        body: data,
      }),
      transformResponse: (result: ILoginResponse) => {
        Cookies.set('accessToken', result.accessToken);
        Cookies.set('refreshToken', result.refreshToken);
        return result;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(accountApi.endpoints.getMe.initiate(null));
        } catch (error) {}
      },
    }),
    logoutUser: build.mutation<void, void>({
      query() {
        return {
          url: 'auth/logout',
          method: 'DELETE',
          credentials: 'include',
        };
      },
      transformResponse: (result: any) => {
        Cookies.remove('accessToken', result.accessToken);
        Cookies.remove('refreshToken', result.refreshToken);
        return result;
      },
    }),
    forgotPassword: build.mutation<any, any>({
      query(body) {
        return {
          url: `auth/forgot-password`,
          method: 'POST',
          credentials: 'include',
          body,
        };
      },
    }),
    resetPassword: build.mutation<any, any>({
      query({ resetToken, password, passwordConfirm }) {
        return {
          url: `auth/reset-password/${resetToken}`,
          method: 'POST',
          body: { password, passwordConfirm },
          credentials: 'include',
        };
      },
    }),
  })
});

export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation
} = authApi
