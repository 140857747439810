import React, {useEffect, useState} from "react";
import {useCompanyManagersQuery, useCreateManagerMutation} from "../../store/api/users/users";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { CreateManagerInput, createManagerSchema } from "../../validation-schemas/create-manager.validation";
import { Add } from "@mui/icons-material";
import { Button, ModalDialog } from "@mui/joy";
import { Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "@mui/material/styles";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { IAvatar } from "../users/users.page";
import { IUser } from "../../store/api/users/interfaces/user.interface";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { timeFormat } from "../../shared/helpers/time-format.helper";

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: #15112B;
  color: #D1B657;

  &:hover {
    background-color: #15112B;
    transform: translateY(-1px);
  }
`;

const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'First name', width: 150 },
  { field: 'email', headerName: 'Email', width: 250 },
  { field: 'role', headerName: 'Role', width: 150 },
  { field: 'companyName', headerName: 'Company', width: 150 },
  {
    field: 'lastLogin',
    headerName: 'LastLogin',
    width: 200,
    renderCell: (params) => {
      if (params.row.lastLogin) {
        return <div className="rowitem">{moment(params.row.lastLogin).format(timeFormat)}</div>;
      } else {
        return <div className="rowitem">No login still</div>;
      }
    },
  },
  { field: 'isBlock', headerName: 'Block', width: 150 },
];

export function ManagersPage() {
  const navigate = useNavigate();

  const [createManager, CreateManagerResponse] = useCreateManagerMutation();
  const GetManagersResponse = useCompanyManagersQuery(undefined, { refetchOnMountOrArgChange: true });

  const [managers, setManagers] = useState<IUser[]>([]);
  const [openDialog, setOpen] = React.useState<boolean>(false);

  const [avatars] = React.useState<IAvatar[]>([
    { id: 1, label: 'Atelia', value: 'https://models.readyplayer.me/6501a7b246ad17f8b73279f8.glb', icon: 'https://readyplayer.me/gallery/6501a7b246ad17f8b73279f8' },
    { id: 2, label: 'Aurilia', value: 'https://models.readyplayer.me/6501a8ae46ad17f8b7327c59.glb', icon: 'https://readyplayer.me/gallery/6501a8ae46ad17f8b7327c59' },
    { id: 3, label: 'Dmitriy', value: 'https://models.readyplayer.me/6501a0f3613d018699346ac4.glb', icon: 'https://readyplayer.me/gallery/6501a0f3613d018699346ac4' },
    { id: 4, label: 'Derek', value: 'https://models.readyplayer.me/65019c03613d018699346389.glb', icon: 'https://readyplayer.me/gallery/65019c03613d018699346389' },
  ]);
  const [chosenAvatar, setAvatar] = React.useState<IAvatar>();
  const [customAvatarUrl, setCustomAvatarUrl] = React.useState<string>('');

  const handleAvatarChange = (e: any) => {
    setCustomAvatarUrl(e.target.value);
  }

  const updateAvatar = (newValue: string) => {
    setCustomAvatarUrl(newValue);
  }

  const onSubmitHandler: SubmitHandler<CreateManagerInput> = (manager: any) => {
    manager.avatar = customAvatarUrl;
    createManager(manager);
  };

  const methods = useForm<CreateManagerInput>({
    resolver: zodResolver(createManagerSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  const errorMessage = (methods: any, key: string) => {
    if(methods.formState.errors[key]) {
      return methods.formState.errors[key].message;
    }
    return '';
  }

  useEffect(() => {
    if (CreateManagerResponse.isSuccess) {
      toast.success('Manager created successfully');
      setManagers([...managers, CreateManagerResponse.data]);
      setOpen(false);
    }

    if (CreateManagerResponse.isError) {
      if (Array.isArray((CreateManagerResponse.error as any).data.error)) {
        (CreateManagerResponse.error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((CreateManagerResponse.error as any).data.message), {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    CreateManagerResponse.isSuccess,
    CreateManagerResponse.data,
    CreateManagerResponse.isError,
    CreateManagerResponse.error,
  ]);

  useEffect(() => {
    if (GetManagersResponse.isSuccess) {
      setManagers(GetManagersResponse.data);
    }
  }, [
    GetManagersResponse.isSuccess,
    GetManagersResponse.data
  ]);

  useEffect(() => {
    if (GetManagersResponse.isError) {
      if (Array.isArray((GetManagersResponse.error as any).data.error)) {
        (GetManagersResponse.error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((GetManagersResponse.error as any).data.message), {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    GetManagersResponse.isError,
    GetManagersResponse.error,
  ]);

  return (
    <>
      <div className={'flex flex-col'}>
        <div className={'flex justify-between items-center bg-gray-100 px-5 py-3'}>
          <div className={'text-2xl header-title'}>
            Manager list:
          </div>
          <div className={'add-manager'}>
            <Button
              variant="outlined"
              color="neutral"
              startDecorator={<Add />}
              onClick={() => setOpen(true)}
            >
              Create manager
            </Button>
          </div>
        </div>
        <div className={'bg-gray-100 px-5 pb-3 h-[80vh]'}>
          <div className={'parallax-table'}>
            { managers && (
              <DataGrid
                rows={managers}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                onCellClick={(e) => {
                  navigate(`/manager/${e.row._id}`);
                }}
              />
            ) }
          </div>
        </div>
      </div>
      <Modal open={openDialog} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 500, overflow: 'auto' }}
        >
          <FormProvider {...methods}>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmitHandler)}
              noValidate
              autoComplete='off'
              maxWidth='27rem'
              width='100%'
              sx={{
                backgroundColor: '#fff',
                p: { xs: '1rem', sm: '2rem' },
                borderRadius: 1,
              }}
            >
              <Typography
                textAlign='center'
                component='h2'
                sx={{
                  fontSize: "26px",
                  mb: 2,
                  color: '#15112B',
                  letterSpacing: 1,
                }}
              >
                New manager
              </Typography>
              <TextField
                type="text"
                id="Name"
                fullWidth
                label="Name"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("firstName")}
                error={!!methods.formState.errors.firstName}
                helperText={errorMessage(methods, "firstName")}
              />
              <TextField
                type="text"
                id="nickName"
                fullWidth
                label="Nick name"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("nickName")}
                error={!!methods.formState.errors.nickName}
                helperText={errorMessage(methods, "nickName")}
              />
              <TextField
                type="text"
                id="phone"
                fullWidth
                label="Contact"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("phone")}
                error={!!methods.formState.errors.phone}
                helperText={errorMessage(methods, "phone")}
              />
              <TextField
                type="email"
                id="email-input"
                fullWidth
                label="Email"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("email")}
                error={!!methods.formState.errors.email}
                helperText={errorMessage(methods, "email")}
              />
              <TextField
                type='text'
                id="password-input"
                fullWidth
                label="Password"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("password")}
                error={!!methods.formState.errors.password}
                helperText={errorMessage(methods, "password")}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Avatar</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={chosenAvatar?.value}
                  label="Avatar"
                  onChange={handleAvatarChange}
                  sx={{ marginBottom: '15px' }}
                >
                  { avatars && avatars.map((avatar) => (
                    <MenuItem
                      key={avatar.id}
                      value={avatar.value}
                    >
                      {avatar.label}
                    </MenuItem>
                  ))}
                </Select>

                <TextField
                  type="text"
                  id="avatar"
                  fullWidth
                  label="Avatar url"
                  variant="outlined"
                  value={customAvatarUrl}
                  sx={{ mb: "2rem" }}
                  onChange={(e) => updateAvatar(e.target.value)}
                />
              </FormControl>

              <LoadingButton
                variant='contained'
                sx={{ mt: 1 }}
                fullWidth
                disableElevation
                type='submit'
                loading={CreateManagerResponse.isLoading}
              >
                Create
              </LoadingButton>
            </Box>
          </FormProvider>
        </ModalDialog>
      </Modal>
    </>
  );
}
