import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Button, DialogContentText, Tooltip, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useGetMeetingByIdQuery, useUpdateMeetingMutation } from "../../store/api/meetings/meeting";
import { useGetCompanyByIdQuery } from "../../store/api/companies/company";
import { useGetUserByIdQuery } from "../../store/api/users/users";
import { IMeeting } from "../../store/api/meetings/interfaces/meeting.interface";
import { ICompany } from "../../store/api/companies/interfaces/company.interface";
import { ITransformedUser } from "../../store/api/users/interfaces/transformed-user.interface";
import { MeetingOptionKeysEnum } from "../../store/api/meetings/enums/meeting-option-keys.enum";
import { AgoraChanelTypesEnum } from "../../constants/agora-chanel-types.enum";
import { toast } from "react-toastify";
import ToastMessages from "../../constants/toast.messages";
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useGetAccessesForMeetingQuery } from "../../store/api/meeting-accesses/meeting-accesses";
import LogoutIcon from '@mui/icons-material/Logout';
import { IUnityBuildData, UnityContainerComponent } from "./components/unity-container.component";
import Cookies from 'js-cookie';
import { IUser } from "../../store/api/users/interfaces/user.interface";
import useAgora from "../../agora/agora.hooks";
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';
import MediaPlayerComponent from "./components/media-player.component";
import AgoraRTC, { IAgoraRTCClient, UID } from "agora-rtc-sdk-ng";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { RoleEnum } from "../../shared/role.enum";


export function MeetingContainerPage() {

  if (navigator.storage && navigator.storage.estimate) {
    navigator.storage.estimate().then((quota) => {
      // quota.usage -> Number of bytes used.
      // quota.quota -> Maximum number of bytes available.
      const percentageUsed = (quota.usage / quota.quota) * 100;
      console.log(`You've used ${percentageUsed}% of the available storage.`);
      const remaining = quota.quota - quota.usage;
      console.log(`You can write up to ${remaining} more bytes.`);
    });
  }

  const isDeviceDetected = false;
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const companyId = searchParams.get("companyId");
  const meetingId = searchParams.get("meetingId");

  const handle = useFullScreenHandle();

  const [updateMeeting, UpdateMeetingResponse] = useUpdateMeetingMutation();
  const GetMeetingResponse  = useGetMeetingByIdQuery(meetingId, { refetchOnMountOrArgChange: true });
  const GetCompanyResponse = useGetCompanyByIdQuery(companyId, { refetchOnMountOrArgChange: true });
  const GetUserResponse = useGetUserByIdQuery(userId, { refetchOnMountOrArgChange: true });
  const GetMeetingAccessesResponse = useGetAccessesForMeetingQuery({
   userId,
   meetingId
  }, { refetchOnMountOrArgChange: true });

  const [finishDialog, setFinishDialog] = useState<boolean>(false);
  const [currentUser, setUser] = useState<ITransformedUser>();
  const [currentMeeting, setMeeting] = useState<IMeeting>();
  const [currentCompany, setCompany] = useState<ICompany>();
  const [meetingParticipant, setMeetingParticipant] = useState<IUser[]>();
  const [controls, setControls] = useState<boolean>(true);
  const [fullScreenStatus, setFullScreenStatus] = useState<boolean>(false);
  const [unityBuildFilesList, setUnityBuildFilesList] = useState<IUnityBuildData>(null);
  const [publishing, setPublishing] = useState<boolean>(true);
  const [agoraClients, setAgoraClients] = useState<IAgoraRTCClient[]>([]);
  const [currentAgoraClient, setAgoraClient] = useState<IAgoraRTCClient>(null);
  const [direction, setDirection] = useState<boolean>(true);
  const [isShowVideoCards, setShowVideoCards] = useState<boolean>(true);
  const [unityCustomEvent, setUnityCustomEvent] = useState<boolean>(null);

  const {
    localAudioTrack,
    localVideoTrack,
    leave,
    muteVideo,
    muteAudio,
    muteVideoState,
    muteAudioState,
    join,
    remoteUsers,
    volumeIndicator,
    publishingClient
  } = useAgora();

  useEffect(() => {
    const newChannel = AgoraRTC.createClient({ codec: "h264", mode: "live" });
    agoraClients.length ? setAgoraClients([...agoraClients, newChannel]) : setAgoraClients([newChannel]);
    setAgoraClient(newChannel);

    // Масив з URL адресами скриптів
    const scriptUrls = [
      "https://mivr-builds.s3.eu-north-1.amazonaws.com/WebGL/production/microphone.js",
      "https://mivr-builds.s3.eu-north-1.amazonaws.com/WebGL/production/pdf.js",
      "https://mivr-builds.s3.eu-north-1.amazonaws.com/WebGL/production/pdf.js.map",
      "https://mivr-builds.s3.eu-north-1.amazonaws.com/WebGL/production/pdf.worker.js",
      "https://mivr-builds.s3.eu-north-1.amazonaws.com/WebGL/production/pdf.worker.js.map",
      "https://mivr-builds.s3.eu-north-1.amazonaws.com/WebGL/sandbox/test.js"
    ];
    console.log('scriptUrls', scriptUrls);
    // Масив для збереження створених елементів скрипта
    const scripts = scriptUrls.map(url => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      return script;
    });

    // Функція для очищення: видалення всіх скриптів, які були додані
    return () => {
      scripts.forEach(script => {
        document.body.removeChild(script);
      });
    }
  }, []);

  useEffect(() => {
    if (GetMeetingResponse.isSuccess && GetMeetingResponse.data) {
      setMeeting(GetMeetingResponse.data);
      let participant = [];
      participant.push(GetMeetingResponse.data.createdBy);
      participant = [...participant, ...GetMeetingResponse.data.members];
      setMeetingParticipant(participant);
    }
    if (GetCompanyResponse.isSuccess && GetCompanyResponse.data) {
      setCompany(GetCompanyResponse.data);
    }
    if (GetUserResponse.isSuccess && GetUserResponse.data) {
      setUser(GetUserResponse.data);
    }

    if (agoraClients.length && GetMeetingAccessesResponse.data && GetMeetingResponse.data) {
      const isMicroAllow = GetMeetingResponse.data.options.find((o) => o.key === MeetingOptionKeysEnum.micro).value;
      const isCameraAllow = GetMeetingResponse.data.options.find((o) => o.key === MeetingOptionKeysEnum.camera).value;
      setUnityBuildFilesList(GetMeetingAccessesResponse.data.unityBuildFilesList);
      agoraClients.forEach((cl) => {
        setPublishing(true);
        join(
          isMicroAllow,
          isCameraAllow,
          cl,
          GetMeetingAccessesResponse.data.agoraAppId,
          meetingId.toString(),
          publishing,
          GetMeetingAccessesResponse.data.agoraAccessToken,
          userId.toString(),
        );
      })
    }

    if (GetMeetingResponse.isError && GetMeetingResponse.error) {
      const error = GetMeetingResponse.error as undefined as any;
      toast.error(error?.data?.message ? error?.data?.message : 'Error here');
    }

    if (GetCompanyResponse.isError && GetCompanyResponse.error) {
      const error = GetCompanyResponse.error as undefined as any;
      toast.error(error?.data?.message ? error?.data?.message : 'Error here');
    }

    if (GetUserResponse.isError && GetUserResponse.error) {
      const error = GetUserResponse.error as undefined as any;
      toast.error(error?.data?.message ? error?.data?.message : 'Error here');
    }

    if (GetMeetingAccessesResponse.isError && GetMeetingAccessesResponse.error) {
      const error = GetMeetingAccessesResponse.error as undefined as any;
      toast.error(error?.data?.message ? error?.data?.message : 'Error here');
    }
  }, [
    agoraClients,
    GetMeetingResponse.isSuccess,
    GetMeetingResponse.data,
    GetMeetingResponse.isError,
    GetMeetingResponse.error,
    GetCompanyResponse.isSuccess,
    GetCompanyResponse.data,
    GetCompanyResponse.isError,
    GetCompanyResponse.error,
    GetUserResponse.isSuccess,
    GetUserResponse.data,
    GetUserResponse.isError,
    GetUserResponse.error,
    GetMeetingAccessesResponse.isSuccess,
    GetMeetingAccessesResponse.data,
    GetMeetingAccessesResponse.isError,
    GetMeetingAccessesResponse.error,
  ]);

  const toggleFullScreen = (value: boolean) => {
    if (value) {
      setFullScreenStatus(value);
      handle.enter();
    } else {
      setFullScreenStatus(value);
      handle.exit();
    }
  }

  const finishMeeting = () => {
    const accessToken = Cookies.get('accessToken');
    if (agoraClients.length === 1) {
      leave(currentAgoraClient, true);
      setPublishing(false);
      setAgoraClients([]);
      setAgoraClient(null);
      setMeetingParticipant([]);
    } else {
      leave(currentAgoraClient, false);
      setPublishing(false);
      setAgoraClients([]);
      setAgoraClient(null);
      setMeetingParticipant([]);
    }
    if (accessToken) {
      navigate('/');
    } else {
      navigate('/meeting-finished');
    }
  }

  const mute = async (type: AgoraChanelTypesEnum) => {
    if (type === AgoraChanelTypesEnum.AUDIO) {
      muteAudio();
    } else if (type === AgoraChanelTypesEnum.VIDEO) {
      muteVideo();
    }
  };

  const handleClose = (action?: string) => {
    setFinishDialog(false);
    if (action === 'leave') {
      setUnityCustomEvent(true); // send custom event to stop unity container
    } else if (action === 'finish') {
      updateMeeting({
        id: meetingId,
        query: {
          status: 'complete',
        }
      })
      setUnityCustomEvent(true); // send custom event to stop unity container
    }
  };
  const participantAvatar = (id: UID) => {
    if (meetingParticipant && meetingParticipant.length > 0) {
      const participant = meetingParticipant.find((p) => p._id === id);
      if(participant && participant.avatar) {
        return `https://api.readyplayer.me/v1/avatars/${participant?.avatar.split('.glb')[0].split('/').pop()}.png`
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  return (
    <>
      { currentMeeting && currentCompany && currentUser && (
        <Box className={'flex h-[100vh] w-full'}>
          <FullScreen handle={handle} className={'h-[100vh] w-full'}>
            <Box className={'relative'}>
              <Box className={'pt-5 flex flex-col justify-between absolute top-0 left-3 h-[100vh] z-50'}>
                <Box>
                  <Box
                    onClick={() => setControls(!controls)}
                    sx={{ marginBottom: '5px', cursor: 'pointer' }}
                  >
                    <Tooltip title="Hide controls">
                      <img src="/images/pages/meeting/controls.png" alt="controls"/>
                    </Tooltip>
                  </Box>
                  { controls && (
                    <Box>
                      {/*Audio*/}
                      <>
                        <Box
                          onClick={() => mute(AgoraChanelTypesEnum.AUDIO)}
                          sx={{ marginBottom: '5px', cursor: 'pointer' }}
                        >
                          { currentMeeting.options.find((o) => o.key === MeetingOptionKeysEnum.micro && o.value === true) && (
                            <Tooltip title="Microfone">
                              { muteAudioState ? <img src="/images/pages/meeting/micro-gold-off.png" alt="micro-ico"/> : <img src="/images/pages/meeting/micro-gold-on.png" alt="micro-ico"/> }
                            </Tooltip>
                          )}
                        </Box>
                        { currentMeeting.options.find((o) => o.key === MeetingOptionKeysEnum.micro && o.value === false) && (
                          <Tooltip title="Microfone" onClick={() => toast.error(ToastMessages.actionIsBlocked)}>
                            <img src="/images/pages/meeting/micro-white.png" alt="micro-ico" className={'mb-1'}/>
                          </Tooltip>
                        )}
                      </>
                      {/*Video*/}
                      <>
                        { currentMeeting.options.find((o) => o.key === MeetingOptionKeysEnum.camera && o.value === true) && (
                          <Box
                            onClick={() => mute(AgoraChanelTypesEnum.VIDEO)}
                            sx={{ marginBottom: '5px', cursor: 'pointer' }}
                          >
                            <Tooltip title="Camera">
                              { muteVideoState ? <img src="/images/pages/meeting/camera-gold-off.png" alt="camera-icon"/> : <img src="/images/pages/meeting/camera-gold-on.png" alt="camera-icon"/> }
                            </Tooltip>
                          </Box>
                        )}
                        { currentMeeting.options.find((o) => o.key === MeetingOptionKeysEnum.camera && o.value === false) && (
                          <Tooltip title="Camera" onClick={() => toast.error(ToastMessages.actionIsBlocked)}>
                            <img src="/images/pages/meeting/camera-white.png" alt="chat" className={'mb-1'}/>
                          </Tooltip>
                        )}
                      </>
                      {/*Spectrator*/}
                      <>
                        { currentMeeting.options.find((o) => o.key === MeetingOptionKeysEnum.spectatorMode && o.value === true) && (
                          <Tooltip title="Spectrator mode">
                            <img src="/images/pages/meeting/spectrator-gold.png" alt="spectator-mode" className={'mb-1'}/>
                          </Tooltip>
                        )}
                        { currentMeeting.options.find((o) => o.key === MeetingOptionKeysEnum.spectatorMode && o.value === false) && (
                          <Tooltip title="Spectator mode" onClick={() => toast.error(ToastMessages.actionIsBlocked)}>
                            <img src="/images/pages/meeting/spectrator-white.png" alt="chat" className={'mb-1'}/>
                          </Tooltip>
                        )}
                      </>
                      {/* Chat */}
                      <>
                        { currentMeeting.options.find((o) => o.key === MeetingOptionKeysEnum.textChat && o.value === true) && (
                          <Tooltip title="Chat">
                            <img src="/images/pages/meeting/chat-gold.png" alt="chat" className={'mb-1'}/>
                          </Tooltip>
                        )}
                        { currentMeeting.options.find((o) => o.key === MeetingOptionKeysEnum.textChat && o.value === false) && (
                          <Tooltip title="Spectator mode" onClick={() => toast.error(ToastMessages.actionIsBlocked)}>
                            <img src="/images/pages/meeting/chat-white.png" alt="chat" className={'mb-1'}/>
                          </Tooltip>
                        )}
                      </>
                    </Box>
                  )}
                </Box>
                <Box></Box>
                <Box
                  onClick={() => setFinishDialog(true)}
                  sx={{ marginBottom: '5px', cursor: 'pointer', textAlign: 'center' }}
                >
                  <Tooltip title="Leave meeting">
                    <LogoutIcon fontSize="large" />
                  </Tooltip>
                </Box>
              </Box>
              <Box className={'flex p-5 h-[200px] absolute top-0 left-20'}>
                <Box>
                  { meetingParticipant && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: direction ? 'row' : 'column',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: direction ? 'column' : 'row',
                          justifyContent: 'space-between',
                          borderRadius: '5px',
                          padding: '3px',
                          margin: '3px',
                          backgroundColor: 'white',
                          boxShadow: '0 0 2px black',
                        }}
                      >
                        <FlipCameraAndroidIcon
                          fontSize={'medium'}
                          sx={{
                            cursor: 'pointer',
                            color: '#D1B657'
                          }}
                          onClick={() => setDirection(!direction)}
                        />
                        { isShowVideoCards ?
                          (<SwitchRightIcon
                            fontSize={'medium'}
                            sx={{
                              cursor: 'pointer',
                              color: '#D1B657'
                            }}
                            onClick={() => setShowVideoCards(!isShowVideoCards)}
                          />) :
                          (<SwitchLeftIcon
                            fontSize={'medium'}
                            sx={{
                              cursor: 'pointer',
                              color: '#D1B657'
                            }}
                            onClick={() => setShowVideoCards(!isShowVideoCards)}
                          />)}
                      </Box>
                      <Box
                        className={'video-cards'}
                        sx={{
                          display: 'flex',
                          flexDirection: direction ? 'row' : 'column',
                          maxWidth: '720px',
                          maxHeight: '90vh',
                          overflow: 'auto',
                          opacity: isShowVideoCards ? '100%' : '0%',
                        }}
                      >
                        <Box
                          sx={{
                            width: '138px',
                            height: '165px',
                            borderRadius: '5px',
                            padding: '3px',
                            margin: '3px',
                            backgroundColor: 'whitesmoke',
                            boxShadow: '0 0 2px black',
                          }}
                        >
                          <Typography
                            component='p'
                            sx={{ fontWeight: 600, margin: '0', textAlign: 'center'}}
                          >
                            { meetingParticipant.find((u) => u._id === publishingClient?.uid)?.nickName.slice(0, 12) || "Loading..." }
                          </Typography>
                          <MediaPlayerComponent
                            videoTrack={localVideoTrack}
                            audioTrack={null}
                            uid={publishingClient?.uid}
                            avatar={`https://api.readyplayer.me/v1/avatars/${currentUser?.avatar.split('.glb')[0].split('/').pop()}.png`}
                            volumeIndicator={volumeIndicator}
                          ></MediaPlayerComponent>
                        </Box>
                        {remoteUsers.map((user) => (
                          <Box
                            key={user.uid}
                            sx={{
                              width: '138px',
                              height: '165px',
                              borderRadius: '5px',
                              padding: '3px',
                              margin: '3px',
                              backgroundColor: 'whitesmoke',
                              boxShadow: '0 0 2px black',
                            }}
                          >
                            <Typography
                              component='p'
                              sx={{ fontWeight: 600, margin: '0', textAlign: 'center'}}
                            >
                              { meetingParticipant.find((u) => u._id === user?.uid)?.nickName.slice(0, 12) || "Loading..." }
                            </Typography>
                            <MediaPlayerComponent
                              videoTrack={user.videoTrack}
                              audioTrack={user.audioTrack}
                              uid={user.uid}
                              avatar={participantAvatar(user.uid)}
                              volumeIndicator={volumeIndicator}
                            ></MediaPlayerComponent>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              { unityBuildFilesList && (
                <UnityContainerComponent customEvent={unityCustomEvent} unityBuildFilesList={unityBuildFilesList} finishMeeting={finishMeeting}/>
              )}
              <Box
                className={'absolute bottom-0 right-0 cursor-pointer'}
              >
                { fullScreenStatus && (
                  <Tooltip title="Enter fullscreen">
                    <FullscreenIcon color="primary" sx={{ fontSize: '60px' }} onClick={() => toggleFullScreen(false)}/>
                  </Tooltip>
                )}
                { !fullScreenStatus && (
                  <Tooltip title="Enter fullscreen">
                    <FullscreenExitIcon color="primary" sx={{ fontSize: '60px' }} onClick={() => toggleFullScreen(true)}/>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </FullScreen>
          <Dialog
            open={finishDialog}
            onClose={() => handleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Meeting actions?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                If you finish this meeting, it will no longer be available
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              { currentUser.role === RoleEnum.MANAGER && (
                <Button
                  onClick={() => handleClose('finish')}
                  sx={{
                    backgroundColor: '#15112B',
                    color: '#D1B657'
                  }}
                >
                  Finish
                </Button>
              )}
              <Button
                onClick={() => handleClose('leave')}
                sx={{
                  backgroundColor: '#15112B',
                  color: '#D1B657'
                }}
              >
                Leave
              </Button>
              {/*<Button*/}
              {/*  onClick={() => unloadContainer()}*/}
              {/*  sx={{*/}
              {/*    backgroundColor: '#15112B',*/}
              {/*    color: '#D1B657'*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Unload container*/}
              {/*</Button>*/}
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
}
