import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {useNavigate, useParams} from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { timeFormat } from "../../../shared/helpers/time-format.helper";
import { useMeetingListByUserIDQuery } from "../../../store/api/meetings/meeting";
import { IMeeting } from "../../../store/api/meetings/interfaces/meeting.interface";

export function UserMeetingsTablePage() {
  const userId = useParams<{ id: string }>().id as string;

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', width: 150 },
    {
      field: 'createdBy',
      headerName: 'Creator',
      width: 150,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.createdBy.firstName}</div>;
      },
    },
    {
      field: 'time.start',
      headerName: 'Start',
      width: 150,
      renderCell: (params) => {
        return <div className="rowitem">{moment(params.row.time.start).format(timeFormat)}</div>;
      },
    },
    {
      field: 'time.duration',
      headerName: 'Duration',
      width: 150,
      renderCell: (params) => {
        return <div className="rowitem">{new Date(params.row.time.duration * 1000).toISOString().substring(11, 16)}</div>;
      },
    },
    {
      field: 'members',
      headerName: 'Members',
      width: 150,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.members.length + 1}</div>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
    },
  ];

  const navigate = useNavigate();

  const [meetings, setMeetings] = useState<IMeeting[]>([]);

  const GetMeetingsResponse = useMeetingListByUserIDQuery(userId, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (GetMeetingsResponse.data) {
      setMeetings(GetMeetingsResponse.data);
    }
  }, [GetMeetingsResponse.isSuccess, GetMeetingsResponse.data]);

  useEffect(() => {
    if (GetMeetingsResponse.isError) {
      if (Array.isArray((GetMeetingsResponse.error as any).data.error)) {
        (GetMeetingsResponse.error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((GetMeetingsResponse.error as any).data.message), {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetMeetingsResponse.isLoading]);

  return (
    <>
      <div className={'parallax-table b-5'}>
        { meetings && (
          <DataGrid
            rows={meetings}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            onCellClick={(e) => {
              navigate(`/meeting/${e.row._id}`);
            }}
          />
        ) }
      </div>
    </>
  );
}
