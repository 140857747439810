import React, { useEffect } from "react";
import { Box, Modal, TextField, Typography} from "@mui/material";
import { Button, ModalDialog} from "@mui/joy";
import { Add } from "@mui/icons-material";
import { CompanyAdminTableComponent } from "./components/table.page";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "@mui/material/styles";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { createCompanyAdminSchema, CreateCompanyAdminInput } from "../../../validation-schemas/create-company-admin.validation";
import { useCreateCompanyAdminMutation } from "../../../store/api/users/users";

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: #15112B;
  color: #D1B657;

  &:hover {
    background-color: #15112B;
    transform: translateY(-1px);
  }
`;

export function CompanyAdminsPage() {
  const [open, setOpen] = React.useState<boolean>(false);

  const [createUser, { isLoading, isError, error, isSuccess, data: newCompanyAdmin }] = useCreateCompanyAdminMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success('Company admin created successfully');
      setOpen(false);
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((error as any).data.message), {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const methods = useForm<CreateCompanyAdminInput>({
    resolver: zodResolver(createCompanyAdminSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmitHandler: SubmitHandler<CreateCompanyAdminInput> = (user: any) => {
    createUser(user);
  };

  const errorMessage = (methods: any, key: string) => {
    if(methods.formState.errors[key]) {
      return methods.formState.errors[key].message;
    }
    return '';
  }

  return (
    <>
      <div>
        <Box className={'flex justify-between items-center mb-5'}>
          <p className={'text-2xl font-bold'}>
            Company admin list
          </p>
          <Button
            variant="outlined"
            color="neutral"
            startDecorator={<Add />}
            onClick={() => setOpen(true)}
          >
            Create company admin
          </Button>
        </Box>
        <CompanyAdminTableComponent newCompanyAdmin={newCompanyAdmin} />
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: 500, overflow: 'auto' }}
        >
          <FormProvider {...methods}>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmitHandler)}
              noValidate
              autoComplete='off'
              maxWidth='27rem'
              width='100%'
              sx={{
                backgroundColor: '#fff',
                p: { xs: '1rem', sm: '2rem' },
                borderRadius: 1,
              }}
            >
              <Typography
                textAlign='center'
                component='h1'
                sx={{
                  fontSize: "26px",
                  mb: 2,
                  color: '#15112B',
                  letterSpacing: 1,
                }}
              >
                New company admin
              </Typography>
              <TextField
                type="text"
                id="Name"
                fullWidth
                label="Name"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("firstName")}
                error={!!methods.formState.errors.firstName}
                helperText={errorMessage(methods, "firstName")}
              />
              <TextField
                type="text"
                id="phone"
                fullWidth
                label="Phone"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("phone")}
                error={!!methods.formState.errors.phone}
                helperText={errorMessage(methods, "phone")}
              />
              <TextField
                type="email"
                id="email-input"
                fullWidth
                label="Email"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("email")}
                error={!!methods.formState.errors.email}
                helperText={errorMessage(methods, "email")}
              />
              <TextField
                type='text'
                id="password-input"
                fullWidth
                label="Password"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("password")}
                error={!!methods.formState.errors.password}
                helperText={errorMessage(methods, "password")}
              />

              <LoadingButton
                variant='contained'
                sx={{ mt: 1 }}
                fullWidth
                disableElevation
                type='submit'
                loading={isLoading}
              >
                Create
              </LoadingButton>
            </Box>
          </FormProvider>
        </ModalDialog>
      </Modal>
    </>
  );
}
