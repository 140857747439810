import * as React from 'react';
import { toast } from "react-toastify";
import { Box, Typography, Modal, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Button, ModalDialog, Textarea } from "@mui/joy";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { useCreateCompanyMutation } from "../../../store/api/companies/company";
import { CreateCompanyInput, createCompanySchema } from "../../../validation-schemas/create-company.validation";
import { styled } from "@mui/material/styles";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import { CompanyTableComponent } from "./components/table.page";
import { ICompany } from "../../../store/api/companies/interfaces/company.interface";

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: #15112B;
  color: #D1B657;

  &:hover {
    background-color: #15112B;
    transform: translateY(-1px);
  }
`;

export function CompaniesPage() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [company, setNewCompany] = React.useState<ICompany | undefined>(undefined);

  const [createCompany, { isLoading, isError, error, isSuccess, data: newCompany }] = useCreateCompanyMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success('Company created successfully');
      setOpen(false);
      if (newCompany) {
        setNewCompany(newCompany);
      }
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((error as any).data.message), {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const methods = useForm<CreateCompanyInput>({
    resolver: zodResolver(createCompanySchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmitHandler: SubmitHandler<CreateCompanyInput> = (data: any) => {
    createCompany(data);
  };

  const errorMessage = (methods: any, key: string) => {
    if(methods.formState.errors[key]) {
      return methods.formState.errors[key].message;
    }
    return '';
  }

  return (
    <>
      <div>
        <Box className={'flex justify-between items-center mb-5'}>
          <p className={'text-2xl font-bold'}>
            Companies list
          </p>
          <Button
            variant="outlined"
            color="neutral"
            startDecorator={<Add />}
            onClick={() => setOpen(true)}
          >
            New company
          </Button>
        </Box>
        <CompanyTableComponent
          newCompany={company}
        />
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: 500, overflow: 'auto' }}
        >
          <FormProvider {...methods}>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmitHandler)}
              noValidate
              autoComplete='off'
              maxWidth='27rem'
              width='100%'
              sx={{
                backgroundColor: '#fff',
                p: { xs: '1rem', sm: '2rem' },
                borderRadius: 1,
              }}
            >
              <Typography
                textAlign='center'
                component='h1'
                sx={{
                  fontWeight: 600,
                  fontSize: "34px",
                  mb: 2,
                  color: '#15112B',
                  letterSpacing: 1,
                }}
              >
                Create new company
              </Typography>
              <TextField
                type="text"
                id="Title"
                fullWidth
                label="Title"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("title")}
                error={!!methods.formState.errors.title}
                helperText={errorMessage(methods, "title")}
              />
              <TextField
                type="textarea"
                id="Description"
                multiline
                rows={5}
                fullWidth
                label="Description"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("description")}
                error={!!methods.formState.errors.description}
                helperText={errorMessage(methods, "description")}
              />

              <LoadingButton
                variant='contained'
                sx={{ mt: 1 }}
                fullWidth
                disableElevation
                type='submit'
                loading={isLoading}
              >
                Submit
              </LoadingButton>
            </Box>
          </FormProvider>
        </ModalDialog>
      </Modal>
    </>
  );
}
