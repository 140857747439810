import { object, string, TypeOf } from 'zod';

const createTemplateSchema = object({
  title: string()
    .min(1, 'Title is required')
    .max(20, 'Too long'),
});

export type CreateTemplateInput = TypeOf<typeof createTemplateSchema>;

export { createTemplateSchema };
