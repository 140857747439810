import * as React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';

export function AdminPage() {
  return (
    <Box>
      <Typography paragraph>
        Admin main page
      </Typography>
    </Box>
  );
}
