import React, { useEffect, useState } from "react";
import { useContentListQuery, useCreateContentMutation } from "../../store/api/contents/content";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Add } from "@mui/icons-material";
import { Button, ModalDialog } from "@mui/joy";
import { Box, Modal, TextField, Typography } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "@mui/material/styles";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { CreateContentInput, createContentSchema } from "../../validation-schemas/create-content.validation";
import { toast } from "react-toastify";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { IContent } from "../../store/api/contents/interfaces/content.interface";

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: #15112B;
  color: #D1B657;

  &:hover {
    background-color: #15112B;
    transform: translateY(-1px);
  }
`;


const columns: GridColDef[] = [
  { field: 'title', headerName: 'title', width: 150 },
  {
    field: 'creatorId',
    headerName: 'Creator',
    width: 150,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.creatorId?.firstName}</div>;
    },
  },
  {
    field: 'amountOfFiles',
    headerName: 'Count/Size',
    width: 150,
    renderCell: (params) => {
      if (params.row.files.length === 0) {
        return <div className="rowitem">No files</div>;
      }
      const totalSize = params.row.files.map((f: any) => f.fileSize).reduce((x: number, y: number) => x + y);
      const fileSizeInMB = totalSize / (1024 * 1024);
      return <div className="rowitem">{`${params.row.files.length} / ${fileSizeInMB.toFixed(2)} mb`}</div>;
    },
  },
];

export function ContentsPage() {
  const navigate = useNavigate();

  const [createContent, CreateContentResponse] = useCreateContentMutation();
  const [openDialog, setOpen] = React.useState<boolean>(false);
  const [contents, setContents] = useState<IContent[]>([]);
  const GetContentsResponse = useContentListQuery(undefined, { refetchOnMountOrArgChange: true });

  const onSubmitHandler: SubmitHandler<CreateContentInput> = (content: any) => {
    createContent(content);
  };

  const methods = useForm<CreateContentInput>({
    resolver: zodResolver(createContentSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (CreateContentResponse.isSuccess && CreateContentResponse.data) {
      toast.success('Content created successfully');
      setContents([...contents, CreateContentResponse.data]);
      setOpen(false);
    }

    if (CreateContentResponse.isError) {
      if (Array.isArray((CreateContentResponse.error as any).data.error)) {
        (CreateContentResponse.error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((CreateContentResponse.error as any).data.message), {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    CreateContentResponse.isSuccess,
    CreateContentResponse.data,
    CreateContentResponse.isError,
    CreateContentResponse.error,
  ]);

  useEffect(() => {
    if (GetContentsResponse.isSuccess && GetContentsResponse.data) {
      setContents(GetContentsResponse.data);
    }
  }, [
    GetContentsResponse.isSuccess,
    GetContentsResponse.data
  ]);

  useEffect(() => {
    if (GetContentsResponse.isError) {
      if (Array.isArray((GetContentsResponse.error as any).data.error)) {
        (GetContentsResponse.error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((GetContentsResponse.error as any).data.message), {
          position: 'top-right',
        });
      }
    }
  }, [
    GetContentsResponse.isError,
    GetContentsResponse.error
  ]);

  const errorMessage = (methods: any, key: string) => {
    if(methods.formState.errors[key]) {
      return methods.formState.errors[key].message;
    }
    return '';
  }

  return (
    <>
      <div className={'flex flex-col'}>
        <div className={'flex justify-between items-center bg-gray-100 px-5 py-3'}>
          <div className={'text-2xl header-title'}>
            Content list:
          </div>
          <div className={'add-content'}>
            <Button
              variant="outlined"
              color="neutral"
              startDecorator={<Add />}
              onClick={() => setOpen(true)}
            >
              Create content
            </Button>
          </div>
        </div>
        <div className={'bg-gray-100 px-5 pb-3 h-[80vh]'}>
          <div className={'parallax-table'}>
            { contents && (
              <DataGrid
                rows={contents}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                onCellClick={(e) => {
                  navigate(`/content/${e.row._id}`);
                }}
              />
            ) }
          </div>
        </div>
      </div>
      <Modal open={openDialog} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: 500, overflow: 'auto' }}
        >
          <FormProvider {...methods}>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmitHandler)}
              noValidate
              autoComplete='off'
              maxWidth='27rem'
              width='100%'
              sx={{
                backgroundColor: '#fff',
                p: { xs: '1rem', sm: '2rem' },
                borderRadius: 1,
              }}
            >
              <Typography
                textAlign='center'
                component='h2'
                sx={{
                  fontSize: "26px",
                  mb: 2,
                  color: '#15112B',
                  letterSpacing: 1,
                }}
              >
                New content
              </Typography>
              <TextField
                type="text"
                id="Name"
                fullWidth
                label="Name"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("title")}
                error={!!methods.formState.errors.title}
                helperText={errorMessage(methods, "title")}
              />

              <LoadingButton
                variant='contained'
                sx={{ mt: 1 }}
                fullWidth
                disableElevation
                type='submit'
                loading={CreateContentResponse.isLoading}
              >
                Create
              </LoadingButton>
            </Box>
          </FormProvider>
        </ModalDialog>
      </Modal>
    </>
  );
}
