import { createApi } from "@reduxjs/toolkit/query/react";
import { setUser } from "../../features/userSlice";
import customFetchBase from "../customFetchBase";
import { IUser } from "../users/interfaces/user.interface";

export const accountApi = createApi({
  reducerPath: 'accountApi',
  baseQuery: customFetchBase,
  endpoints: build => ({
    getMe: build.query<IUser, null>({
      query() {
        return {
          url: "/auth/account/me",
          credentials: 'include',
        };
      },
      transformResponse: (result: IUser) => {
        return result;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {}
      },
    }),
  })
});
