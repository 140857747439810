import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from "../customFetchBase";
import { IUser } from "./interfaces/user.interface";
import { IAssignManager } from "./interfaces/assing-manager.interface";
import { ITransformedUser } from "./interfaces/transformed-user.interface";
import { IUpdateUser } from "./interfaces/update-user.interface";
import {IUserNpc} from "./interfaces/user-npc.interface";

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: customFetchBase,
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    createUser: builder.mutation<IUser, FormData>({
      query(user) {
        return {
          url: '/web-users/create-user',
          method: 'POST',
          credentials: 'include',
          body: user,
        };
      },
      transformResponse(user: IUser) {
        user.id = user._id;
        if (user.companyId) {
          const company = user.companyId as any;
          user.companyName = company.title;
        } else {
          user.companyName = 'Not assign';
        }
        return user;
      }
    }),
    createNpc: builder.mutation<IUser, IUserNpc>({
      query(npc) {
        return {
          url: '/web-users/create-npc',
          method: 'POST',
          credentials: 'include',
          body: npc,
        };
      },
      transformResponse(user: IUser) {
        user.id = user._id;
        if (user.companyId) {
          const company = user.companyId as any;
          user.companyName = company.title;
        } else {
          user.companyName = 'Not assign';
        }
        return user;
      }
    }),
    getUserByQuery: builder.mutation<IUser[], { query: any }>({
      query(query) {
        return {
          url: '/web-users/query',
          method: 'POST',
          credentials: 'include',
          body: query,
        };
      },
      transformResponse(res: IUser[]) {
        res.forEach((user: any) => {
          user.id = user._id;
          if (user.companyId) {
            user.companyName = user.companyId.title;
          } else {
            user.companyName = 'Not assign';
          }
        })
        return res;
      }
    }),
    createManager: builder.mutation<IUser, FormData>({
      query(user) {
        return {
          url: '/web-users/create-manager',
          method: 'POST',
          credentials: 'include',
          body: user,
        };
      },
      transformResponse(user: IUser) {
        user.id = user._id;
        if (user.companyId) {
          const company = user.companyId as any;
          user.companyName = company.title;
        } else {
          user.companyName = 'Not assign';
        }
        return user;
      }
    }),
    createCompanyAdmin: builder.mutation<IUser, FormData>({
      query(user) {
        return {
          url: '/web-users/create-company-admin',
          method: 'POST',
          credentials: 'include',
          body: user,
        };
      },
      transformResponse(user: IUser) {
        user.id = user._id;
        if (user.companyId) {
          const company = user.companyId as any;
          user.companyName = company.title;
        } else {
          user.companyName = 'Not assign';
        }
        return user;
      }
    }),
    updateUser: builder.mutation<ITransformedUser, { id: string; query: IUpdateUser }>(
      {
        query({ id, query }) {
          return {
            url: `/web-users/${id}`,
            method: 'PATCH',
            credentials: 'include',
            body: query,
          };
        },
        transformResponse(user: ITransformedUser) {
          if (user.companyId) {
            user.companyName = user.companyId.title;
          } else {
            user.companyName = 'Not assign';
          }
          return user;
        }
      }
    ),
    assignManagerToCompany: builder.mutation<ITransformedUser, IAssignManager>(
      {
        query(body) {
          return {
            url: `/web-users/assign/manager/to-company`,
            method: 'PATCH',
            credentials: 'include',
            body,
          };
        },
        transformResponse(user: ITransformedUser) {
          if (user.companyId) {
            user.companyName = user.companyId.title;
          } else {
            user.companyName = 'Not assign';
          }
          return user;
        }
      }
    ),
    getUserById: builder.query<ITransformedUser, string>({
      query(id) {
        return {
          url: `/web-users/${id}`,
          credentials: 'include',
        };
      },
      transformResponse(user: ITransformedUser): ITransformedUser {
        if (user.companyId) {
          user.companyName = user.companyId.title;
        } else {
          user.companyName = 'Not assign';
        }
        return user;
      }
    }),
    usersList: builder.query<IUser[], void>({
      query() {
        return {
          url: `/web-users/`,
          credentials: 'include',
        };
      },
      transformResponse(res: IUser[]) {
        res.forEach((user: any) => {
          user.id = user._id;
          if (user.companyId) {
            user.companyName = user.companyId.title;
          } else {
            user.companyName = 'Not assign';
          }
        })
        return res;
      }
    }),
    usersAndNpcForCompany: builder.query<IUser[], void>({
      query() {
        return {
          url: `/web-users/list/users-and-npc`,
          credentials: 'include',
        };
      },
      transformResponse(res: IUser[]) {
        res.forEach((user: any) => {
          user.id = user._id;
          if (user.companyId) {
            user.companyName = user.companyId.title;
          } else {
            user.companyName = 'Not assign';
          }
        })
        return res;
      }
    }),
    usersByRole: builder.query<IUser[], string>({
      query(role) {
        return {
          url: `/web-users/?role=${role}`,
          credentials: 'include',
        };
      },
      transformResponse(res: IUser[]) {
        res.forEach((user: any) => {
          user.id = user._id;
          if (user.companyId) {
            user.companyName = user.companyId.title;
          } else {
            user.companyName = 'Not assign';
          }
        })
        return res;
      }
    }),
    userListForMeeting: builder.query<IUser[], void>({
      query() {
        return {
          url: `/web-users/for-meeting`,
          credentials: 'include',
        };
      },
      transformResponse(res: IUser[]) {
        res.forEach((user: any) => {
          user.id = user._id;
          if (user.companyId) {
            user.companyName = user.companyId.title;
          } else {
            user.companyName = 'Not assign';
          }
        })
        return res;
      }
    }),
    companyAdmins: builder.query<IUser[], void>({
      query() {
        return {
          url: '/web-users/all-companies-admins',
          credentials: 'include',
        };
      },
      transformResponse(res: IUser[]) {
        res.forEach((user: any) => {
          user.id = user._id;
          if (user.companyId) {
            user.companyName = user.companyId.title;
          } else {
            user.companyName = 'Not assign';
          }
        })
        return res;
      }
    }),
    companyManagers: builder.query<IUser[], void>({
      query() {
        return {
          url: '/web-users/all-companies-managers',
          credentials: 'include',
        };
      },
      transformResponse(res: IUser[]) {
        res.forEach((user: any) => {
          user.id = user._id;
          if (user.companyId) {
            user.companyName = user.companyId.title;
          } else {
            user.companyName = 'Not assign';
          }
        })
        return res;
      }
    }),
    deleteUser: builder.mutation<IUser, string>({
      query(id) {
        return {
          url: `/web-users/${id}`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
    }),
  }),
});

export const {
  useCreateUserMutation,
  useCreateNpcMutation,
  useCreateManagerMutation,
  useCreateCompanyAdminMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetUserByIdQuery,
  useUsersAndNpcForCompanyQuery,
  useCompanyManagersQuery,
  useGetUserByQueryMutation,
  useUsersListQuery,
  useUserListForMeetingQuery,
  useUsersByRoleQuery,
  useCompanyAdminsQuery,
  useAssignManagerToCompanyMutation,
} = usersApi;
