import { ILocalVideoTrack, IRemoteVideoTrack, ILocalAudioTrack, IRemoteAudioTrack, UID } from "agora-rtc-sdk-ng";
import React, { useRef, useEffect, useState } from "react";
import { volume } from "../../../agora/agora.hooks";
import { Box } from "@mui/material";

export interface VideoPlayerProps {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined;
  audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined;
  muteVideoState?: boolean;
  uid: UID | undefined;
  avatar: string;
  volumeIndicator: Array<volume> | undefined
}
const MediaPlayerComponent = (props: VideoPlayerProps) => {
  const [activeSpeaker, setActiveSpeaker] = useState<volume | undefined>(undefined)
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!container.current) return;
    props.videoTrack?.play(container.current);
    return () => {
      props.videoTrack?.stop();
    };
  }, [container, props.videoTrack]);

  useEffect(() => {
    props.audioTrack?.play();
    return () => {
      props.audioTrack?.stop();
    };
  }, [props.audioTrack]);

  useEffect(() => {
    //clear active speaker indicator
    if(props.volumeIndicator === undefined) container.current?.classList.remove('active-speaker');
    props.volumeIndicator?.forEach(volume => {
      if(!activeSpeaker || (volume.level >= 3 && activeSpeaker.level < volume.level)){
        setActiveSpeaker(volume);
      }
      if(container.current?.id === `user-${activeSpeaker?.uid}`)
        container.current?.classList.add('active-speaker');
      else
        container.current?.classList.remove('active-speaker');
    });
  }, [props.volumeIndicator, activeSpeaker]);

  return (
    <>
      { (props.videoTrack && !props.muteVideoState) ? (
        <Box
          ref={container}
          id={`user-${props.uid}`}
          className="video-player-card"
          style={{ width: '130px', height: '130px' }}
        />
      ) : (
        <Box>
          <img
            width={130}
            height={130}
            src={props.avatar}
            alt="avatar"
          />
        </Box>
      )}
    </>
  );
}

export default MediaPlayerComponent;
