import * as React from "react";
import { Outlet, useNavigate } from 'react-router-dom';
import {
  AppBar, Avatar,
  Box, Button,
  CssBaseline, Divider,
  Drawer, IconButton,
  List,
  ListItem,
  ListItemButton, ListItemIcon,
  ListItemText, Menu, MenuItem,
  Toolbar, Tooltip,
  Typography
} from "@mui/material";
import { useLogoutUserMutation } from "../../store/api/auth/auth";
import {Email, ManageAccounts, SupervisorAccount} from "@mui/icons-material";
import {useAppSelector} from "../../store/store";

const drawerWidth = 240;
const menuItems = [
  { id: 1, label: 'Companies', url: '/admin/companies' },
  { id: 2, label: 'Company admins', url: '/admin/company-admins' },
  { id: 3, label: 'Locations', url: '/admin/locations' }
];

export function AdminLayout() {
  const [logoutUser] =
    useLogoutUserMutation();
  const user = useAppSelector((state) => state.userState.user);
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const onLogoutHandler = async () => {
    logoutUser();
    navigate('/login')
  };

  const navigateTo = async (url: string): Promise<void> => {
    navigate(url);
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            onClick={() => navigateTo('/admin')}
          >
            Admin dashboard
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                {user && (
                  <>
                    <div className={'flex-col'}>
                      <List>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              <ManageAccounts />
                            </ListItemIcon>
                            <ListItemText
                              primary={user.firstName}
                            />
                          </ListItemButton>
                        </ListItem>
                        <Divider/>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              <Email />
                            </ListItemIcon>
                            <ListItemText
                              primary={user.email}
                            />
                          </ListItemButton>
                        </ListItem>
                        <Divider/>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              <SupervisorAccount />
                            </ListItemIcon>
                            <ListItemText
                              primary={user.role}
                            />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </div>
                  </>
                )}
              </MenuItem>
              <Box className={'flex justify-end mx-2'}>
                <Button
                  color='primary'
                  variant="outlined"
                  onClick={onLogoutHandler}
                >
                  Logout
                </Button>
              </Box>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {menuItems.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
                onClick={() => navigateTo(item.url)}
              >
                <ListItemButton>
                  <ListItemText
                    primary={item.label}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <div className={'admin-outlet-wrapper'}>
          <Outlet />
        </div>
      </Box>
    </Box>
  );
}
