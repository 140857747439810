import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from "../customFetchBase";
import { ITemplate } from "./interfaces/template.interface";
import {IUpdateTemplate} from "./interfaces/update-template.interface";

export const templatesApi = createApi({
  reducerPath: 'templatesApi',
  baseQuery: customFetchBase,
  tagTypes: ['Templates'],
  endpoints: (builder) => ({
    createTemplate: builder.mutation<ITemplate, ITemplate>({
      query(template) {
        return {
          url: '/templates',
          method: 'POST',
          credentials: 'include',
          body: template,
        };
      },
      transformResponse(template: ITemplate) {
        template.id = template._id;
        return template;
      }
    }),
    updateTemplate: builder.mutation<ITemplate, { id: string; query: IUpdateTemplate }>(
      {
        query({ id, query }) {
          return {
            url: `/templates/${id}`,
            method: 'PATCH',
            credentials: 'include',
            body: query,
          };
        },
      }
    ),
    getTemplateById: builder.query<ITemplate, string>({
      query(id) {
        return {
          url: `/templates/${id}`,
          credentials: 'include',
        };
      },
    }),
    templateList: builder.query<ITemplate[], void>({
      query() {
        return {
          url: `/templates`,
          credentials: 'include',
        };
      },
      transformResponse(template: ITemplate[]): ITemplate[] {
        template.forEach((t) => {
          t.id = t._id;
        })
        return template;
      }
    }),
    deleteTemplate: builder.mutation<ITemplate, string>({
      query(id) {
        return {
          url: `/templates/${id}`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
    }),
  }),
});

export const {
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useUpdateTemplateMutation,
  useGetTemplateByIdQuery,
  useTemplateListQuery,
} = templatesApi;
