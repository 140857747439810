import {Box, Container, IconButton, InputAdornment, TextField, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton as _LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useResetPasswordMutation } from '../../store/api/auth/auth';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import { ResetPasswordInput, resetPasswordSchema } from "../../validation-schemas/reset-password.validation";

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: #15112B;
  color: #D1B657;

  &:hover {
    background-color: #15112B;
    transform: translateY(-1px);
  }
`;

const ResetPasswordPage = () => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const { resetToken } = useParams<{ resetToken: string }>();

  const methods = useForm<ResetPasswordInput>({
    resolver: zodResolver(resetPasswordSchema),
  });

  // 👇 API Login Mutation
  const [resetPassword, { isLoading, isError, error, isSuccess }] =
    useResetPasswordMutation();

  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      navigate('/login');
      toast.success('Password updated successfully, login', {
        position: 'top-right',
      });
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const errorMessage = (methods: any, key: string) => {
    if(methods.formState.errors[key]) {
      return methods.formState.errors[key].message;
    }
    return '';
  }

  const onSubmitHandler: SubmitHandler<ResetPasswordInput> = (values) => {
    resetPassword({ ...values, resetToken: resetToken! });
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'ghostwhite',
      }}
      className={"login-wrapper"}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <FormProvider {...methods}>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete='off'
            maxWidth='27rem'
            width='100%'
            sx={{
              backgroundColor: 'white',
              p: { xs: '1rem', sm: '2rem' },
              borderRadius: 2,
            }}
          >

            <Typography
              textAlign='center'
              component='h1'
              sx={{
                textTransform: "uppercase",
                fontWeight: 600,
                fontSize: "34px",
                mb: 2,
                letterSpacing: 1,
              }}
            >
              Reset Password
            </Typography>

            <Typography
              sx={{
                fontSize: 15,
                width: '100%',
                textAlign: 'center',
                mb: '1rem',
              }}
            >
              Please enter a new password
            </Typography>

            <TextField
              type={showPassword ? 'text' : 'password'}
              fullWidth
              label="Password"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>,
              }}
              sx={{ mb: "2rem" }}
              {...methods.register("password")}
              error={!!methods.formState.errors.password}
              helperText={errorMessage(methods, "password")}
            />
            <TextField
              type={showPassword ? 'text' : 'password'}
              fullWidth
              label="Confirm password"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>,
              }}
              sx={{ mb: "2rem" }}
              {...methods.register("passwordConfirm")}
              error={!!methods.formState.errors.password}
              helperText={errorMessage(methods, "passwordConfirm")}
            />

            <LoadingButton
              variant='contained'
              fullWidth
              disableElevation
              type='submit'
              loading={isLoading}
            >
              Reset Password
            </LoadingButton>
          </Box>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default ResetPasswordPage;
