import { Box, Container, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LoadingButton as _LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useForgotPasswordMutation } from '../../store/api/auth/auth';
import { ForgotPasswordInput, forgotPasswordSchema } from "../../validation-schemas/forgot-password.validation";

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: #15112B;
  color: #D1B657;

  &:hover {
    background-color: #15112B;
    transform: translateY(-1px);
  }
`;

const ForgotPasswordPage = () => {
  const methods = useForm<ForgotPasswordInput>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  // 👇 API Login Mutation
  const [forgotPassword, { isLoading, isError, error, isSuccess, data }] =
    useForgotPasswordMutation();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.message);
    }
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          })
        );
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<ForgotPasswordInput> = ({ email }) => {
    // 👇 Executing the forgotPassword Mutation
    forgotPassword({ email });
  };

  const errorMessage = (methods: any, key: string) => {
    if(methods.formState.errors[key]) {
      return methods.formState.errors[key].message;
    }
    return '';
  }

  if (isSuccess) {
    return (
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#2363eb',
        }}
        className={"login-wrapper"}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            maxWidth='27rem'
            width='100%'
            height='13rem'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#fff',
              p: { xs: '1rem', sm: '2rem' },
              borderRadius: 2,
            }}
          >
            <Typography textAlign='center' component='h5'>
              We have sent you a password recovery email.
            </Typography>
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#2363eb',
      }}
      className={"login-wrapper"}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <FormProvider {...methods}>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete='off'
            maxWidth='27rem'
            width='100%'
            sx={{
              backgroundColor: '#fff',
              p: { xs: '1rem', sm: '2rem' },
              borderRadius: 1,
            }}
          >
            <Typography
              textAlign='center'
              component='h1'
              sx={{
                fontWeight: 600,
                fontSize: "34px",
                mb: 2,
                color: '#15112B',
                letterSpacing: 1,
              }}
            >
              Forgot Password
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                width: '100%',
                textAlign: 'center',
                mb: '1rem',
              }}
            >
              Enter your email address and we’ll send you a link to reset your
              password.
            </Typography>
            <TextField
              type="email"
              id="email-input"
              fullWidth
              label="Email"
              variant="outlined"
              sx={{ mb: "2rem" }}
              {...methods.register("email")}
              error={!!methods.formState.errors.email}
              helperText={errorMessage(methods, "email")}
            />

            <LoadingButton
              variant='contained'
              sx={{ mt: 1 }}
              fullWidth
              disableElevation
              type='submit'
              loading={isLoading}
            >
              Retrieve Password
            </LoadingButton>

            <Typography
              sx={{ fontSize: '0.9rem', mt: '1rem', textAlign: 'center' }}
            >
              <Link to='/login' style={{ color: '#333' }}>
                Back to Login
              </Link>
            </Typography>
          </Box>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default ForgotPasswordPage;
