import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from "../customFetchBase";
import { IContent } from "./interfaces/content.interface";
import { IUpload } from "../../../pages/contents/interfaces/upload.interface";
import { IUpdate } from "../../../shared/interfaces/update.interface";

export const contentsApi = createApi({
  reducerPath: 'contentsApi',
  baseQuery: customFetchBase,
  tagTypes: ['Contents'],
  endpoints: (builder) => ({
    createContent: builder.mutation<IContent, FormData>({
      query(content) {
        return {
          url: '/contents',
          method: 'POST',
          credentials: 'include',
          body: content,
        };
      },
      transformResponse(content: IContent) {
        content.id = content._id;
        return content;
      }
    }),
    addFilesContent: builder.mutation<IContent, IUpload>({
      query(data: IUpload) {
        return {
          url: `/contents/add-files/${data.contentId}`,
          method: 'PATCH',
          credentials: 'include',
          body: data.files,
        };
      },
      transformResponse(content: IContent) {
        content.id = content._id;
        return content;
      },
    }),
    updateContent: builder.mutation<IContent, IUpdate>(
      {
        query({ id, query }) {
          return {
            url: `/contents/${id}`,
            method: 'PATCH',
            credentials: 'include',
            body: query,
          };
        },
      }
    ),
    getContentById: builder.query<IContent, string>({
      query(id) {
        return {
          url: `/contents/${id}`,
          credentials: 'include',
        };
      },
    }),
    contentList: builder.query<IContent[], void>({
      query() {
        return {
          url: `/contents`,
          credentials: 'include',
        };
      },
      transformResponse(contents: IContent[]): IContent[] {
        contents.forEach((c) => {
          c.id = c._id;
        })
        return contents;
      }
    }),
    deleteContent: builder.mutation<IContent, string>({
      query(id) {
        return {
          url: `/contents/${id}`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
    }),
  }),
});

export const {
  useCreateContentMutation,
  useAddFilesContentMutation,
  useDeleteContentMutation,
  useUpdateContentMutation,
  useGetContentByIdQuery,
  useContentListQuery,
} = contentsApi;
