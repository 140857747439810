import { object, string, TypeOf } from 'zod';

const createCompanySchema = object({
  title: string()
    .min(1, 'Title is required')
    .max(20, 'Too long'),
  description: string()
    .min(1, 'Description is required')
    .max(200, 'Too long'),
});

export type CreateCompanyInput = TypeOf<typeof createCompanySchema>;

export { createCompanySchema };
