import * as React from 'react';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { ILocationTable } from "../interfaces/location-table.interface";
import { useEffect, useState } from "react";
import { ILocation } from "../../../../store/api/locations/interfaces/location.interface";
import { useLocationsListQuery } from "../../../../store/api/locations/location";

const columns: GridColDef[] = [
  { field: 'title', headerName: 'Title', width: 130 },
];

export function LocationsTableComponent({ newLocation }: ILocationTable) {
  const navigate = useNavigate();
  const [locations, setLocations] = useState<ILocation[]>([]);
  const { data: initLocations } = useLocationsListQuery(undefined, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (initLocations) {
      setLocations(initLocations);
    }
  }, [initLocations]); // Guaranteed to be triggered *after* each update

  useEffect(() => {
    if (newLocation) {
      setLocations([...locations, newLocation]);
    }
  }, [newLocation]); // Guaranteed to be triggered *after* each update

  return (
    <>
      <div className={'parallax-table'}>
        { locations && (
          <DataGrid
            rows={locations}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            onCellClick={(e) => {
              navigate(`/admin/location/${e.row._id}`);
            }}
          />
        ) }
      </div>
    </>
  );
}
