import * as React from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Avatar,
  Button,
  MenuItem,
  ListItem,
  ListItemButton,
  Divider,
  List,
  Menu,
  Tooltip,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/store';
import { useLogoutUserMutation } from '../../store/api/auth/auth';
import "./loyaut.css"
import { RoleEnum } from "../../shared/role.enum";
import { INavElement } from "./interfaces/nav-element.interface";
import {Email, ManageAccounts, SupervisorAccount} from "@mui/icons-material";
import {ICompany} from "../../store/api/companies/interfaces/company.interface";

function HeaderComponent() {
  let pages: INavElement[] = [];

  const navigate = useNavigate();
  const user = useAppSelector((state) => state.userState.user);
  const currentCompany = user?.companyId as undefined as ICompany;

  if (user && user.role === RoleEnum.ADMIN) {
    // navigate('/admin');
  } else if(user && user.role === RoleEnum.COMPANY_ADMIN) {
    // navigate('/managers');
  } else if(user && user.role === RoleEnum.MANAGER){
    pages = [
      { id: 1, label: 'Overview', url: '/' },
      { id: 2, label: 'Meetings', url: '/meetings' },
      { id: 3, label: 'Template', url: '/templates' },
      { id: 4, label: 'Users', url: '/users' },
      { id: 5, label: 'Content', url: '/contents' },
    ];
  }

  const [logoutUser] = useLogoutUserMutation();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onLogoutHandler = async () => {
    logoutUser();
    navigate('/login')
  };

  const navigateTo = async (url: string): Promise<void> => {
    handleCloseNavMenu();
    navigate(url);
  }

  const navigateToRoot = () => {
    if (user && user.role === RoleEnum.ADMIN) {
      navigate('/admin');
    } else if(user && user.role === RoleEnum.COMPANY_ADMIN) {
      navigate('/managers');
    } else if(user && user.role === RoleEnum.MANAGER){
      navigate('/');
    }
  }

  return (
    <AppBar
      color="transparent"
      position="static"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' }
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="success"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page: INavElement) => (
                <MenuItem
                  key={page.id}
                  onClick={() => navigateTo(page.url)}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/*Desktop*/}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            { currentCompany && (
              <img
                className={"logo-img cursor-pointer"}
                src={currentCompany.logo}
                alt="logo"
                onClick={navigateToRoot}
              />
            )}
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {pages.map((page: INavElement) => (
                <Button
                  key={page.id}
                  onClick={() => navigateTo(page.url)}
                  sx={{ my: 2, mx: 2, color: 'white', display: 'block' }}
                >
                  {page.label}
                </Button>
              ))}
            </Box>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                {user && (
                  <>
                    <div className={'flex-col'}>
                      <List>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              <ManageAccounts />
                            </ListItemIcon>
                            <ListItemText
                              primary={user.firstName}
                            />
                          </ListItemButton>
                        </ListItem>
                        <Divider/>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              <Email />
                            </ListItemIcon>
                            <ListItemText
                              primary={user.email}
                            />
                          </ListItemButton>
                        </ListItem>
                        <Divider/>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              <SupervisorAccount />
                            </ListItemIcon>
                            <ListItemText
                              primary={user.role}
                            />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </div>
                  </>
                )}
              </MenuItem>
              <Box className={'flex justify-end mx-2'}>
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: '#D1B657',
                    color: '#15112B'
                  }}
                  onClick={onLogoutHandler}
                >
                  Logout
                </Button>
              </Box>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default HeaderComponent;
