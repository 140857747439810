import * as React from 'react';
import { useParams } from "react-router-dom";
import {
  useGetCompanyByIdQuery,
  useUpdateCompanyMutation,
  useUploadCompanyLogoMutation
} from "../../../store/api/companies/company";
import { useEffect, useState } from "react";
import { ICompany } from "../../../store/api/companies/interfaces/company.interface";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Divider,
  Tooltip,
  Button, TextField, DialogContentText
} from "@mui/material";
import TitleIcon from "@mui/icons-material/Title";
import DescriptionIcon from '@mui/icons-material/Description';
import KeyIcon from '@mui/icons-material/Key';
import TokenIcon from '@mui/icons-material/Token';
import { IUpdateCompany } from "../../../store/api/companies/interfaces/update-company.interface";
import { useDropzone } from "react-dropzone";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import BlockIcon from '@mui/icons-material/Block';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {toast} from "react-toastify";
import ToastMessages from "../../../constants/toast.messages";

export function CompanyPage() {
  const id = useParams<{ id: string }>().id as string;
  const [company, setCompany] = useState<ICompany | undefined>(undefined);
  const [blockCompanyDialog, setBlockCompanyDialog] = React.useState(false);
  const [files, setFiles] = useState<any>(null);
  const [reason, setReason] = useState<string>('');

  const [uploadLogo, uploadResponse] = useUploadCompanyLogoMutation();

  const GetCompanyResponse = useGetCompanyByIdQuery(id);

  const [updateCompany, UpdateCompanyResponse] = useUpdateCompanyMutation();

  const { acceptedFiles, getRootProps, getInputProps }: any = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    }
  });

  useEffect(() => {
    if (GetCompanyResponse.data) {
      setCompany(GetCompanyResponse.data);
    }
    if (uploadResponse.data) {
      setCompany(GetCompanyResponse.data);
      toast.success(ToastMessages.success);
    }
    if (UpdateCompanyResponse.data) {
      setCompany(UpdateCompanyResponse.data);
      toast.success(ToastMessages.success);
    }
  }, [
    GetCompanyResponse.data,
    uploadResponse.data,
    UpdateCompanyResponse.data,
  ]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setFiles(acceptedFiles);
    }
  }, [acceptedFiles]);

  const remove = () => {
    alert('Remove company');
  }

  const update = () => {
    const payload: IUpdateCompany = {
      title: company.title,
      logo: company.logo,
      description: company.description,
      credentials: company.credentials,
      statuses: {
        isBlock: company.statuses.isBlock,
        reason: reason ? reason : company.statuses.reason
      },
    }
    updateCompany({
      id,
      query: payload,
    });
    setBlockCompanyDialog(false);
  }

  const updateCompanyDetails = (newValue: string, key: string) => {
    setCompany((prevCompany) => {
      if (prevCompany) {
        return { ...prevCompany, [key]: newValue };
      }
      return prevCompany;
    });
  };

  const updateCompanyCredentials = (newValue: string, key: string) => {
    setCompany((prevCompany) => {
      if (prevCompany) {
        const updatedCredentials = { ...prevCompany.credentials, [key]: newValue };
        return { ...prevCompany, credentials: updatedCredentials };
      }
      return prevCompany;
    });
  };

  const uploadFiles = () => {
    const formData = new FormData();
    if (files) {
      files.forEach((f: any) => {
        formData.append('files', f);
        formData.append('fileType', f.fileType);
      });

      uploadLogo({
        companyId: id,
        files: formData
      });
    }
  }

  const updateStatus = (value: boolean) => {
    setBlockCompanyDialog(true);
    setCompany((prevCompany) => {
      if (prevCompany) {
        return {...prevCompany, statuses: { isBlock: value, reason: company.statuses.reason }};
      }
      return prevCompany;
    });
  }

  const handleClose = () => {
    setBlockCompanyDialog(false);
  };

  return (
    <>
      <div className={'bg-gray-100 h-[85vh] p-5'}>
        { company && (
          <Box className={'flex flex-row'}>
            <Box className={'shadow-2xl mr-1 px-5 py-5 basis-4/12 h-[81vh] max-w-sm'}>
              <Typography
                component='p'
                sx={{ fontWeight: 900, marginRight: '10px' }}
                paragraph
              >
                Company details:
              </Typography>
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="Company title">
                      <ListItemIcon>
                        <TitleIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={company.title} />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="Company description">
                      <ListItemIcon>
                        <DescriptionIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={company.description} />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="Is company blocked">
                      <ListItemIcon>
                        <BlockIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={company.statuses.isBlock ? 'Blocked' : 'Active'} />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="Reason of blocking company">
                      <ListItemIcon>
                        <AssignmentIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={company.statuses.reason ? company.statuses.reason : 'Empty...'} />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="Agora APP">
                      <ListItemIcon>
                        <TokenIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={company?.credentials?.agoraAppId  ? `${company?.credentials?.agoraAppId.slice(0, 23)}...` : 'Not setup'} />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="Agora token">
                      <ListItemIcon>
                        <KeyIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={company?.credentials?.agoraToken ? `${company?.credentials?.agoraToken.slice(0, 23)}...` : 'Not setup'} />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="Agora token">
                      <ListItemIcon>
                        <KeyIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={company?.credentials?.photonId ? `${company?.credentials?.photonId.slice(0, 23)}...` : 'Not setup'} />
                  </ListItemButton>
                </ListItem>
                <Divider/>
              </List>
            </Box>
            <Box className={'shadow-2xl ml-1 px-5 py-5 basis-8/12 flex flex-col justify-between h-[81vh] overflow-auto'}>
              <Box>
                <Typography
                  component='p'
                  sx={{ fontWeight: 900, marginRight: '10px' }}
                  paragraph
                >
                  Editing company details:
                </Typography>
                <Box className={'flex w-full h-full'}>
                  <Box className={'flex'}>
                    <Box className={'flex flex-col mr-4'}>
                      <TextField
                        size='small'
                        type="text"
                        id="Title"
                        fullWidth
                        label="Title"
                        variant="outlined"
                        value={company.title}
                        onChange={(e) => updateCompanyDetails(e.target.value, 'title')}
                        sx={{ mb: "2rem" }}
                      />
                      <TextField
                        size='small'
                        type="textarea"
                        id="Description"
                        multiline
                        rows={5}
                        fullWidth
                        label="Description"
                        variant="outlined"
                        value={company.description}
                        onChange={(e) => updateCompanyDetails(e.target.value, 'description')}
                        sx={{ mb: "2rem" }}
                      />
                    </Box>
                    <Box>
                      <Box {...getRootProps({className: 'dropzone relative'})}>
                        <Box className={'absolute top-0 right-0'}>
                          <img width={150} height={150}  src={company.logo} alt="Logo" />
                        </Box>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some file here, or click to select file</p>
                        <em>(Only *.jpeg and *.png images will be accepted)</em>
                      </Box>
                      <div>
                        { files && files.length > 0 && (
                          <>
                            <div className={'flex justify-between items-center mb-5'}>
                              <Box></Box>
                              <Button
                                size="small"
                                color="success"
                                variant="outlined"
                                onClick={() => uploadFiles()}
                              >UPLOAD</Button>
                            </div>
                          </>
                        )}
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography
                  component='p'
                  sx={{ fontWeight: 900, marginRight: '10px' }}
                  paragraph
                >
                  Editing company credentials:
                </Typography>
                <Box className={'flex w-full h-full'}>
                  <Box className={'flex'}>
                    <Box className={'flex flex-col mr-4'}>
                      <TextField
                        size='small'
                        type="text"
                        id="agora-app"
                        fullWidth
                        label="Agora app"
                        variant="outlined"
                        value={company?.credentials?.agoraAppId}
                        onChange={(e) => updateCompanyCredentials(e.target.value, 'agoraAppId')}
                        sx={{ mb: "2rem" }}
                      />
                      <TextField
                        size='small'
                        type="text"
                        id="agora-token"
                        fullWidth
                        label="Agora token"
                        variant="outlined"
                        value={company?.credentials?.agoraToken}
                        onChange={(e) => updateCompanyCredentials(e.target.value, 'agoraToken')}
                        sx={{ mb: "2rem" }}
                      />
                      <TextField
                        size='small'
                        type="text"
                        id="photonId"
                        fullWidth
                        label="Photon ID"
                        variant="outlined"
                        value={company?.credentials?.photonId}
                        onChange={(e) => updateCompanyCredentials(e.target.value, 'photonId')}
                        sx={{ mb: "2rem" }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={'flex justify-end w-full'}>
                { company && company.statuses.isBlock && (
                  <Button
                    size="small"
                    color="warning"
                    variant="outlined"
                    onClick={() => updateStatus(false)}
                    sx={{ marginRight: '10px' }}
                  >
                    UNBLOCK
                  </Button>
                )}
                { company && !company.statuses.isBlock && (
                  <Button
                    size="small"
                    color="warning"
                    variant="outlined"
                    onClick={() => updateStatus(true)}
                    sx={{ marginRight: '10px' }}
                  >
                    BLOCK
                  </Button>
                )}
                <Button
                  size="small"
                  color="success"
                  variant="outlined"
                  onClick={update}
                  sx={{ marginRight: '10px' }}
                >
                  UPDATE
                </Button>
                <Button
                  size="small"
                  color="error"
                  variant="outlined"
                  onClick={remove}
                >
                  DELETE
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </div>
      <Dialog open={blockCompanyDialog} onClose={handleClose}>
        <DialogTitle>Blocking...</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter a reason of blocking this company.
          </DialogContentText>
          { company && !company.statuses.isBlock && (
            <TextField
              autoFocus
              margin="dense"
              id="block-reason"
              label="Block reason"
              type="text"
              fullWidth
              variant="outlined"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              sx={{
                marginTop: '50px'
              }}
            />
          )}
          { company && company.statuses.isBlock && (
            <TextField
              autoFocus
              margin="dense"
              id="unblock-reason"
              label="Unblock reason"
              type="text"
              fullWidth
              variant="outlined"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              sx={{
                marginTop: '50px'
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => update()}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
