import { Box, Paper } from "@mui/material";
import * as React from "react";

export function NoDevicePage() {
  return (
    <>
      <Box className={'flex w-full h-[100vh] justify-center align-middle items-center'}>
        <Paper
          className={'w-[600px] h-[500px] p-10 flex flex-col justify-center align-middle'}
          elevation={1}
        >
          <Box className={'mb-5 font-bold  text-center text-red-400'}>MIVR issue notification</Box>
          <Box className={'mb-10 text-center'}>
            We cant detect audio or video device on your machine.
            Please connect devices and try again.
          </Box>
          <Box className={'flex justify-center'}>
            <img width={300} height={200} src="/images/pages/meeting-end/meeting-end.png" alt="end-meeting"/>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
