import { object, string, TypeOf } from 'zod';

const resetPasswordSchema = object({
  password: string()
    .min(1, 'Password is required')
    .min(6, 'Password must be more than 8 characters'),
  passwordConfirm: string().min(1, 'Please confirm your password'),
}).refine((data) => data.password === data.passwordConfirm, {
  message: 'Passwords do not match',
  path: ['passwordConfirm'],
});

export type ResetPasswordInput = TypeOf<typeof resetPasswordSchema>;

export { resetPasswordSchema };
