import React, {useEffect, useState} from "react";
import {Box, Button, Card, CardActions, CardContent, CardMedia, Typography, Slide } from "@mui/material";
import {useAppSelector} from "../../store/store";
import {
  useGetNextMeetingForUserQuery,
  useMeetingListQuery
} from "../../store/api/meetings/meeting";
import {IMeeting} from "../../store/api/meetings/interfaces/meeting.interface";
import moment from "moment";
import {timeFormat} from "../../shared/helpers/time-format.helper";
import { TransitionProps } from '@mui/material/transitions';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {ITemplateLocation} from "../../store/api/tempates/interfaces/template-location.interface";
import { useNavigate } from "react-router-dom";
import {ICompany} from "../../store/api/companies/interfaces/company.interface";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function HomePage() {
  const navigate = useNavigate();

  const currentUser = useAppSelector((state) => state.userState.user);

  const [nextMeetingForUser, setNextMeeting] = useState<IMeeting>();
  const [meetings, setMeetings] = useState<IMeeting[]>();

  const GetNextMeetingResponse = useGetNextMeetingForUserQuery(currentUser?._id, { refetchOnMountOrArgChange: true });
  const GetMeetingsResponse = useMeetingListQuery(undefined, { refetchOnMountOrArgChange: true });

  const [openMeetingDetails, setOpenMeetingDetailsDialog] = React.useState(false);
  const [openMeetingsList, setMeetingsDialog] = React.useState(false);

  const handleClose = () => {
    setOpenMeetingDetailsDialog(false);
    setMeetingsDialog(false);
  };

  useEffect(() => {
    setNextMeeting(GetNextMeetingResponse.data);
    setMeetings(GetMeetingsResponse.data);
  }, [
    GetNextMeetingResponse.data,
    GetMeetingsResponse.data,
  ])

  const showDetailsNextMeeting = () => {
    setOpenMeetingDetailsDialog(true);
  }

  const showMeetingsListDialog = () => {
    setMeetingsDialog(true);
  }

  const startMeeting = () => {
    if (currentUser && nextMeetingForUser) {
      const company = currentUser.companyId as unknown as ICompany;
      // const url = `${window.location.host}/meeting-container?userId=${currentUser._id}&companyId=${company._id}&meetingId=${nextMeetingForUser._id}`;
      // navigate(`/meeting-container?userId=${currentUser._id}&companyId=${company._id}&meetingId=${nextMeetingForUser._id}`);
      // window.open(url);
      // navigate(`/meeting-container?userId=${currentUser._id}&companyId=${company._id}&meetingId=${nextMeetingForUser._id}`);
      window.open(`https://mivr-dev.xrshowroom.net/meeting-container?userId=${currentUser._id}&companyId=${company._id}&meetingId=${nextMeetingForUser._id}`)
      // window.open(`http://localhost:3000/meeting-container?userId=${currentUser._id}&companyId=${company._id}&meetingId=${nextMeetingForUser._id}`)
    }
  }

  const selectNewMeeting = (meeting: IMeeting) => {
    setNextMeeting(meeting);
    setMeetingsDialog(false);
  }

  return (
    <>
      { currentUser && (
        <Box className={'flex flex-col justify-between items-center h-[88vh]'}>
          <h1 style={{color: 'white', textAlign: 'center', fontSize: '36px'}}>Welcome to your dashboard!</h1>
          <Box className={'flex justify-between w-full'}>
            { nextMeetingForUser && (
              <Box>
                <Card sx={{ width: 300 }}>
                  { nextMeetingForUser && nextMeetingForUser?.templateId && nextMeetingForUser?.templateId?.locations && (
                    <CardMedia
                      sx={{ height: 140 }}
                      image={
                        nextMeetingForUser?.templateId?.locations.find((l: ITemplateLocation) => l.isMain)?.locationId ?
                          nextMeetingForUser?.templateId?.locations.find((l: ITemplateLocation) => l.isMain)?.locationId?.previewImage :
                          "https://placehold.co/600x400"
                      }
                      title={nextMeetingForUser.title}
                    />
                  )}
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {nextMeetingForUser.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {moment(nextMeetingForUser.time.start).format(timeFormat)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Durations: {new Date(nextMeetingForUser.time.duration * 1000).toISOString().substring(11, 16)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Admin: {`${nextMeetingForUser.createdBy.firstName} ${nextMeetingForUser.createdBy.lastName}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Members: {nextMeetingForUser.members.length + 1}
                    </Typography>
                  </CardContent>
                  <CardActions className={'flex justify-center'}>
                    <Button
                      fullWidth
                      color='primary'
                      variant="outlined"
                      sx={{
                        backgroundColor: '#15112B',
                        color: '#D1B657'
                      }}
                      onClick={(e) => showDetailsNextMeeting()}
                    >
                      Details
                    </Button>
                    <Button
                      fullWidth
                      color='primary'
                      variant="outlined"
                      sx={{
                        backgroundColor: '#15112B',
                        color: '#D1B657'
                      }}
                      onClick={(e) => showMeetingsListDialog()}
                    >
                      Change
                    </Button>
                  </CardActions>
                </Card>
              </Box>
            )}
            { !nextMeetingForUser && (
              <Box>No meeting</Box>
            )}
            <Box>
              <img
                width={300}
                height={400}
                src={`https://api.readyplayer.me/v1/avatars/${currentUser?.avatar.split('.glb')[0].split('/').pop()}.png`}
                alt="avatar"
              />
            </Box>
            <Box
              sx={{ width: '300px' }}
            >
            </Box>
          </Box>
          <Box className={'flex justify-center'}>
            <Button
              fullWidth
              color='primary'
              variant="contained"
              sx={{
                width: '150px',
                marginBottom: '20px',
                backgroundColor: '#D1B657',
                color: '#15112B'
              }}
              onClick={(e) => startMeeting()}
            >
              Start
            </Button>
          </Box>
          <Dialog
            open={openMeetingDetails}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{`Meeting "${nextMeetingForUser?.title}" details`}</DialogTitle>
            <DialogContent>
              { nextMeetingForUser && (
                <Box>
                  <Box className={'flex items-center mb-2'}>
                    <Typography
                      component='h5'
                      sx={{
                        fontSize: "16px",
                        marginRight: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Title: {nextMeetingForUser.title}
                    </Typography>
                  </Box>
                  <Box className={'flex items-center mb-2'}>
                    <Typography
                      component='h5'
                      sx={{
                        fontSize: "16px",
                        marginRight: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Duration: {new Date(nextMeetingForUser.time.duration * 1000).toISOString().substring(11, 16)}
                    </Typography>
                  </Box>
                  <Box className={'flex items-center mb-2'}>
                    <Typography
                      component='h5'
                      sx={{
                        fontSize: "16px",
                        marginRight: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Date: {moment(nextMeetingForUser.time.start).format(timeFormat)}
                    </Typography>
                  </Box>
                  <Box className={'mb-2'}>
                    <Typography
                      component='h5'
                      sx={{
                        fontSize: "16px",
                        marginRight: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Admin:
                    </Typography>
                    <Box
                      sx={{
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      <Box>- {nextMeetingForUser.createdBy.firstName}({nextMeetingForUser.createdBy.email})</Box>
                    </Box>
                    <Typography
                      component='h5'
                      sx={{
                        fontSize: "16px",
                        marginRight: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Participants:
                    </Typography>
                    <Box
                      sx={{
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      { nextMeetingForUser.members.length === 0 && (
                        <>
                          <Box className={"text-red-400"}>No one user was added to this meeting</Box>
                        </>
                      ) }
                      { nextMeetingForUser.members.map((user) => (
                        <Box key={user._id}>- {user.firstName}({user.email})</Box>
                      ))}
                    </Box>
                  </Box>
                  <Box className={'flex items-center mb-2'}>
                    <Typography
                      component='h5'
                      sx={{
                        fontSize: "16px",
                        marginRight: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Template: {nextMeetingForUser.templateId ? nextMeetingForUser.templateId?.title : 'No template'}
                    </Typography>
                  </Box>
                  <Box className={'mb-2'}>
                    <Typography
                      component='h5'
                      sx={{
                        fontSize: "16px",
                        marginRight: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Allows:
                    </Typography>
                    <Box
                      sx={{
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      { nextMeetingForUser.options.map((o) => {
                        if (o.value) {
                          return (
                            <Box
                              key={o.key}
                              sx={{
                                fontSize: "14px",
                              }}
                            >
                              - { o.label }
                            </Box>
                          );
                        }
                        return "";
                      }) }
                    </Box>
                  </Box>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant='outlined'
                onClick={handleClose}
                sx={{
                  backgroundColor: '#15112B',
                  color: '#D1B657'
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            fullWidth
            open={openMeetingsList}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            className={'flex-wrap'}
          >
            <DialogTitle>Meetings:</DialogTitle>
            <DialogContent>
              <Box className={'flex flex-wrap'}>
                { meetings && meetings.map((meeting: IMeeting) => (
                  <Box key={meeting._id} className={'p-1 basis-6/12'}>
                    <Card
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'lightgrey',
                        },
                      }}
                    >
                      { meeting && meeting?.templateId && meeting?.templateId?.locations && (
                        <CardMedia
                          sx={{ height: 140 }}
                          image={
                            meeting.templateId.locations.find((l: any) => l.isMain).locationId ?
                              meeting.templateId.locations.find((l: any) => l.isMain).locationId?.previewImage :
                              'https://placehold.co/600x400'
                          }
                          title="Main location"
                        />
                      )}
                      <CardContent
                        sx={{
                          height: '150px',
                          wordBreak: 'break-word'
                        }}
                      >
                        {meeting.title}
                      </CardContent>
                      <CardActions className={'flex justify-between'}>
                        <Box></Box>
                        <Box></Box>
                        <Box>
                          <Button
                            variant='outlined'
                            onClick={() => selectNewMeeting(meeting)}
                            sx={{
                              backgroundColor: '#15112B',
                              color: '#D1B657'
                            }}
                          >
                            Select
                          </Button>
                        </Box>
                      </CardActions>
                    </Card>
                  </Box>
                ))}
                { !meetings && (
                  <Box>
                    You have no planned meetings
                  </Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant='outlined'
                onClick={handleClose}
                sx={{
                  backgroundColor: '#15112B',
                  color: '#D1B657'
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
}
