import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from "../customFetchBase";
import { RequestInterface } from "./interfaces/request.interface";
import { IMeetingAccess } from "./interfaces/meeting-access.interface";

export const meetingAccessesApi = createApi({
  reducerPath: 'meetingAccessesApi',
  baseQuery: customFetchBase,
  tagTypes: ['MeetingAccesses'],
  endpoints: (builder) => ({
    getAccessesForMeeting: builder.query<IMeetingAccess, RequestInterface>({
      query(payload: RequestInterface) {
        return {
          url: `/web-meetings/accesses/${payload.userId}/${payload.meetingId}`,
          credentials: 'include',
        };
      },
    }),
  }),
});

export const {
  useGetAccessesForMeetingQuery,
} = meetingAccessesApi;
