import { useAppSelector } from '../store/store';

export function ProfilePage() {
  const user = useAppSelector((state) => state.userState.user);

  return (
    <div>
      <h1>Profile Page</h1>
      <div>
        <strong>Full Name:</strong> {`${user?.firstName} ${user?.lastName}`}
        <strong>Email Address:</strong> {user?.email}
        <strong>Role:</strong> {user?.role}
      </div>
    </div>
  );
}
