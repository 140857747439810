import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import { useCompanyAdminsQuery } from "../../../../store/api/users/users";
import { IUser } from "../../../../store/api/users/interfaces/user.interface";
import moment from "moment";
import {timeFormat} from "../../../../shared/helpers/time-format.helper";


const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'First name', width: 150 },
  { field: 'email', headerName: 'Email', width: 250 },
  { field: 'role', headerName: 'Role', width: 150 },
  { field: 'companyName', headerName: 'Company', width: 150 },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 150,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.phone ? params.row.phone : 'Not found'}</div>;
    },
  },
  {
    field: 'lastLogin',
    headerName: 'LastLogin',
    width: 200,
    renderCell: (params) => {
      if (params.row.lastLogin) {
        return <div className="rowitem">{moment(params.row.lastLogin).format(timeFormat)}</div>;
      } else {
        return <div className="rowitem">No login still</div>;
      }
    },
  }
];

export function CompanyAdminTableComponent({ newCompanyAdmin }: any) {
  const navigate = useNavigate();

  const [companyAdmins, setManager] = useState<IUser[]>([]);

  const { isLoading, isError, error, data: initCompanyAdmins } = useCompanyAdminsQuery(undefined, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (initCompanyAdmins) {
      setManager(initCompanyAdmins);
    }
  }, [initCompanyAdmins]);

  useEffect(() => {
    if (newCompanyAdmin) {
      setManager([...companyAdmins, newCompanyAdmin]);
    }
  }, [newCompanyAdmin]);

  useEffect(() => {
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((error as any).data.message), {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <>
      <div className={'parallax-table'}>
        { companyAdmins && (
          <DataGrid
            rows={companyAdmins}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            onCellClick={(e) => {
              navigate(`/admin/company-admin/${e.row._id}`);
            }}
          />
        ) }
      </div>
    </>
  );
}
