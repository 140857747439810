import { object, string, TypeOf } from 'zod';

const createManagerSchema = object({
  firstName: string()
    .min(1, 'Name is required')
    .max(20, 'Too long'),
  nickName: string()
    .min(1, 'Nick name is required')
    .max(20, 'Too long'),
  avatar: string().optional(),
  phone: string().optional(),
  email: string()
    .min(1, 'Email address is required')
    .email('Email Address is invalid'),
  password: string()
    .min(1, 'Password is required')
    .min(6, 'Password must be more than 6 characters')
    .max(32, 'Password must be less than 32 characters'),
});

export type CreateManagerInput = TypeOf<typeof createManagerSchema>;

export { createManagerSchema };
