import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from "../customFetchBase";
import { ICompany } from "./interfaces/company.interface";
import { IUpdateCompany } from "./interfaces/update-company.interface";
import { IUploadLogo } from "./interfaces/upload-logo.interface";

export const companiesApi = createApi({
  reducerPath: 'companiesApi',
  baseQuery: customFetchBase,
  tagTypes: ['Companies'],
  endpoints: (builder) => ({
    createCompany: builder.mutation<ICompany, FormData>({
      query(company) {
        return {
          url: '/companies',
          method: 'POST',
          credentials: 'include',
          body: company,
        };
      },
      transformResponse(company: ICompany) {
        company.id = company._id;
        return company;
      }
    }),
    updateCompany: builder.mutation<ICompany, { id: string; query: IUpdateCompany }>(
      {
        query({ id, query }) {
          return {
            url: `/companies/${id}`,
            method: 'PATCH',
            credentials: 'include',
            body: query,
          };
        },
      }
    ),
    uploadCompanyLogo: builder.mutation<ICompany, IUploadLogo>({
      query(data: IUploadLogo) {
        return {
          url: `/companies/upload-logo/${data.companyId}`,
          method: 'PATCH',
          credentials: 'include',
          body: data.files,
        };
      },
      transformResponse(content: ICompany) {
        content.id = content._id;
        return content;
      },
    }),
    getCompanyById: builder.query<ICompany, string>({
      query(id) {
        return {
          url: `/companies/${id}`,
          credentials: 'include',
        };
      },
    }),
    companiesList: builder.query<ICompany[], void>({
      query() {
        return {
          url: `/companies`,
          credentials: 'include',
        };
      },
      transformResponse(res: ICompany[]): ICompany[] {
        res.forEach((company: ICompany) => {
          company.id = company._id;
        })
        return res;
      }
    }),
    companiesListForAdmin: builder.query<ICompany[], void>({
      query() {
        return {
          url: `/companies/for-admin`,
          credentials: 'include',
        };
      },
      transformResponse(res: ICompany[]): ICompany[] {
        res.forEach((company: ICompany) => {
          company.id = company._id;
        })
        return res;
      }
    }),
    deleteCompany: builder.mutation<ICompany, string>({
      query(id) {
        return {
          url: `/companies/${id}`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
    }),
  }),
});

export const {
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useUpdateCompanyMutation,
  useUploadCompanyLogoMutation,
  useGetCompanyByIdQuery,
  useCompaniesListQuery,
  useCompaniesListForAdminQuery,
} = companiesApi;
