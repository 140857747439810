import React, { useEffect, useState } from "react";
import { useCreateNpcMutation, useCreateUserMutation, useUsersAndNpcForCompanyQuery } from "../../store/api/users/users";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Add} from "@mui/icons-material";
import { Button, ModalDialog } from "@mui/joy";
import { Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import { zodResolver} from "@hookform/resolvers/zod";
import { CreateUserInput, createUserSchema } from "../../validation-schemas/create-user.validation";
import { styled} from "@mui/material/styles";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import ToastMessages from "../../constants/toast.messages";
import { CreateNpcInput, createNpcSchema } from "../../validation-schemas/create-npc.validation";
import { IUserNpc } from "../../store/api/users/interfaces/user-npc.interface";
import { RoleEnum } from "../../shared/role.enum";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IUser } from "../../store/api/users/interfaces/user.interface";
import moment from "moment";
import { timeFormat } from "../../shared/helpers/time-format.helper";
import { useAppSelector } from "../../store/store";
import { ICompany } from "../../store/api/companies/interfaces/company.interface";

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: #15112B;
  color: #D1B657;

  &:hover {
    background-color: #15112B;
    transform: translateY(-1px);
  }
`;
export interface IAvatar {
  id: number;
  label: string;
  value: string;
  icon: string;
}

export function UsersPage() {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.userState.user);
  const currentCompany = currentUser?.companyId as undefined as ICompany;

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First name', width: 150 },
    { field: 'nickName', headerName: 'Nick name', width: 150 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'role', headerName: 'Role', width: 150 },
    {
      field: 'createdBy',
      headerName: 'Creator',
      width: 150,
      renderCell: (params) => {
        let creatorName = null;
        params.row.createdBy.forEach((creator: IUser) => {
          if (creator.companyId === currentCompany._id) {
            creatorName = creator.firstName;
          }
        });

        return <div className="rowitem">{creatorName ? creatorName : 'Not found'}</div>;
      },
    },
    {
      field: 'npcParams',
      headerName: 'Npc params',
      width: 150,
      renderCell: (params) => {
        if (params.row.role === RoleEnum.NPC) {
          return <div className="rowitem">{`${params.row.npcParams.role} | ${params.row.npcParams.rules}`}</div>;
        } else {
          return <div className="rowitem">No data</div>;
        }
      },
    },
    {
      field: 'lastLogin',
      headerName: 'LastLogin',
      width: 200,
      renderCell: (params) => {
        if (params.row.lastLogin) {
          return <div className="rowitem">{moment(params.row.lastLogin).format(timeFormat)}</div>;
        } else {
          return <div className="rowitem">No login still</div>;
        }
      },
    }
  ];

  const [createUser, CreateUserResponse] = useCreateUserMutation();
  const [createNpc, CreateNpcResponse] = useCreateNpcMutation();
  const GetUserAndNpcResponse = useUsersAndNpcForCompanyQuery(undefined, { refetchOnMountOrArgChange: true });

  const [npcAvatars] = React.useState<IAvatar[]>([
    { id: 1, label: 'Victoria', value: 'https://models.readyplayer.me/64d1dd54fd49224c9eaa3696.glb', icon: 'https://readyplayer.me/gallery/64d1dd54fd49224c9eaa3696' },
    { id: 2, label: 'Olivia', value: 'https://models.readyplayer.me/64d0f8ddcbd7d40ae6da4d80.glb', icon: 'https://readyplayer.me/gallery/64d0f8ddcbd7d40ae6da4d80' },
    { id: 3, label: 'Adrian', value: 'https://models.readyplayer.me/64d10040cbd7d40ae6da6dfe.glb', icon: 'https://readyplayer.me/gallery/64d10040cbd7d40ae6da6dfe' },
    { id: 4, label: 'Robert', value: 'https://models.readyplayer.me/64d1e4fdea4eb6f0bf5f16e2.glb', icon: 'https://readyplayer.me/gallery/64d1e4fdea4eb6f0bf5f16e2' },
  ]);

  const [userAvatars] = React.useState<IAvatar[]>([
    { id: 1, label: 'Atelia', value: 'https://models.readyplayer.me/6501a7b246ad17f8b73279f8.glb', icon: 'https://readyplayer.me/gallery/6501a7b246ad17f8b73279f8' },
    { id: 2, label: 'Aurilia', value: 'https://models.readyplayer.me/6501a8ae46ad17f8b7327c59.glb', icon: 'https://readyplayer.me/gallery/6501a8ae46ad17f8b7327c59' },
    { id: 3, label: 'Dmitriy', value: 'https://models.readyplayer.me/6501a0f3613d018699346ac4.glb', icon: 'https://readyplayer.me/gallery/6501a0f3613d018699346ac4' },
    { id: 4, label: 'Derek', value: 'https://models.readyplayer.me/65019c03613d018699346389.glb', icon: 'https://readyplayer.me/gallery/65019c03613d018699346389' },
  ]);

  const [npcData, setNpcData] = React.useState<IUserNpc>({
    nickName: '',
    firstName: 'AI assistant',
    email: `${Date.now()}@vr-parallax.net`,
    role: RoleEnum.NPC
  });
  const [chosenAvatar, setAvatar] = React.useState<IAvatar>();
  const [customAvatarUrl, setCustomAvatarUrl] = React.useState<string>('');
  const [createUserDialog, setOpenCreateUserDialog] = React.useState<boolean>(false);
  const [createNpcDialog, setOpenCreateNpcDialog] = React.useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);

  const onCreateUserSubmitHandler: SubmitHandler<CreateUserInput> = (user: any) => {
    if (customAvatarUrl) {
      user.avatar = customAvatarUrl;
    }
    createUser(user);
  };

  const methods = useForm<CreateUserInput>({
    resolver: zodResolver(createUserSchema),
  });

  const onCreateNpcSubmitHandler: SubmitHandler<CreateNpcInput> = (user: CreateNpcInput) => {
    const payload: IUserNpc = {
      email: `${npcData.nickName}_${npcData.email}`,
      firstName: 'AI assistant',
      nickName: user.nickName,
      role: RoleEnum.NPC,
      npcParams: {
        role: user.npcRole,
        rules: user.npcRules,
      }
    }
    if (customAvatarUrl) {
      payload.avatar = customAvatarUrl;
    }
    createNpc(payload);
  };

  const npcMethods = useForm<CreateNpcInput>({
    resolver: zodResolver(createNpcSchema),
  });

  const createUserErrorMessage = (methods: any, key: string) => {
    if(methods.formState.errors[key]) {
      return methods.formState.errors[key].message;
    }
    return '';
  }

  const createNpcErrorMessage = (methods: any, key: string) => {
    // @ts-ignore
    if(npcMethods.formState.errors[key]) {
      return methods.formState.errors[key].message;
    }
    return '';
  }

  useEffect(() => {
    if (GetUserAndNpcResponse.isSuccess && GetUserAndNpcResponse.data) {
      setUsers(GetUserAndNpcResponse.data);
    }
  }, [
    GetUserAndNpcResponse.isSuccess,
    GetUserAndNpcResponse.data,
  ]);

  useEffect(() => {
    if (GetUserAndNpcResponse.isError) {
      if (Array.isArray((GetUserAndNpcResponse.error as any).data.error)) {
        (GetUserAndNpcResponse.error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((GetUserAndNpcResponse.error as any).data.message), {
          position: 'top-right',
        });
      }
    }
  }, [GetUserAndNpcResponse.isError, GetUserAndNpcResponse.error]);

  useEffect(() => {
    if (CreateUserResponse.isSuccess && CreateUserResponse.data) {
      toast.success(ToastMessages.createUser);
      setOpenCreateUserDialog(false);
      setUsers([...users, CreateUserResponse.data]);
    }

    if (CreateUserResponse.isError) {
      const error = CreateUserResponse.error as undefined as any;
      toast.error(error.data.message);
    }

    if (CreateNpcResponse.isSuccess) {
      toast.success(ToastMessages.createUser);
      setOpenCreateNpcDialog(false);
      setUsers([...users, CreateNpcResponse.data]);
    }

    if (CreateNpcResponse.isError) {
      const error = CreateNpcResponse.error as undefined as any;
      toast.error(error.data.message);
    }
  }, [
    CreateUserResponse.isSuccess,
    CreateUserResponse.data,
    CreateUserResponse.isError,
    CreateUserResponse.error,
    CreateNpcResponse.isSuccess,
    CreateNpcResponse.data,
    CreateNpcResponse.isError,
    CreateNpcResponse.error,
  ]);

  const updateAvatar = (newValue: string) => {
    setCustomAvatarUrl(newValue);
  }

  const handleAvatarChange = (e: any) => {
    setCustomAvatarUrl(e.target.value);
  }

  const updateNpcDetails = (newValue: string, key: string) => {
    setNpcData((prevNpcData) => {
      if (prevNpcData) {
        return { ...prevNpcData, [key]: newValue };
      }
      return prevNpcData;
    });
  };

  return (
    <>
      <Box className={'flex flex-col'}>
        <Box className={'flex justify-between items-center users-header-wrapper bg-gray-100 px-5 py-3'}>
          <Box className={'text-2xl header-title'}>
            User list:
          </Box>
          <Box>
            <Button
              variant="outlined"
              color="neutral"
              startDecorator={<Add />}
              onClick={() => setOpenCreateNpcDialog(true)}
              sx={{
                marginRight: '10px'
              }}
            >
              Create NPC
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              startDecorator={<Add />}
              onClick={() => setOpenCreateUserDialog(true)}
            >
              Create user
            </Button>
          </Box>
        </Box>
        <div className={'bg-gray-100 px-5 pb-3 h-[80vh]'}>
          <div className={'parallax-table'}>
            { users && (
              <DataGrid
                rows={users}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                onCellClick={(e) => {
                  navigate(`/user/${e.row._id}`);
                }}
              />
            ) }
          </div>
        </div>
      </Box>
      <Modal open={createUserDialog} onClose={() => setOpenCreateUserDialog(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: 500, overflow: 'auto' }}
        >
          <Typography
            textAlign='center'
            component='h2'
            sx={{
              fontSize: "26px",
              mb: 2,
              color: '#15112B',
              letterSpacing: 1,
            }}
          >
            New user
          </Typography>

          <FormProvider {...methods}>
            <Box
              component='form'
              onSubmit={methods.handleSubmit(onCreateUserSubmitHandler)}
              noValidate
              autoComplete='off'
              width='100%'
              sx={{ paddingX: '2rem' }}
            >
              <TextField
                type="text"
                id="name"
                fullWidth
                label="Name"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("firstName")}
                error={!!methods.formState.errors.firstName}
                helperText={createUserErrorMessage(methods, "firstName")}
              />
              <TextField
                type="text"
                id="nick-name"
                fullWidth
                label="Nick name"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("nickName")}
                error={!!methods.formState.errors.nickName}
                helperText={createUserErrorMessage(methods, "nickName")}
              />
              <TextField
                type="text"
                id="phone"
                fullWidth
                label="Contact"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("phone")}
                error={!!methods.formState.errors.phone}
                helperText={createUserErrorMessage(methods, "phone")}
              />
              <TextField
                type="email"
                id="email-input"
                fullWidth
                label="Email"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("email")}
                error={!!methods.formState.errors.email}
                helperText={createUserErrorMessage(methods, "email")}
              />

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Avatar</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={chosenAvatar?.value}
                  label="Avatar"
                  onChange={handleAvatarChange}
                  sx={{ marginBottom: '15px' }}
                >
                  { userAvatars && userAvatars.map((avatar) => (
                    <MenuItem
                      key={avatar.id}
                      value={avatar.value}
                    >
                      {avatar.label}
                    </MenuItem>
                  ))}
                </Select>

                <TextField
                  type="text"
                  id="avatar"
                  fullWidth
                  label="Avatar url"
                  variant="outlined"
                  value={customAvatarUrl}
                  sx={{ mb: "2rem" }}
                  onChange={(e) => updateAvatar(e.target.value)}
                />
              </FormControl>

              <LoadingButton
                variant='contained'
                sx={{ mt: 1 }}
                fullWidth
                disableElevation
                type='submit'
                loading={CreateUserResponse.isLoading}
              >
                Create
              </LoadingButton>
            </Box>
          </FormProvider>
        </ModalDialog>
      </Modal>
      <Modal open={createNpcDialog} onClose={() => setOpenCreateNpcDialog(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: 500, overflow: 'auto' }}
        >
          <Typography
            textAlign='center'
            component='h2'
            sx={{
              fontSize: "26px",
              mb: 2,
              color: '#15112B',
              letterSpacing: 1,
            }}
          >
            New NPC
          </Typography>
          {/*Create NPC*/}
          <FormProvider {...npcMethods}>
            <Box
              component='form'
              onSubmit={npcMethods.handleSubmit(onCreateNpcSubmitHandler)}
              noValidate
              autoComplete='off'
              width='100%'
              sx={{ paddingX: '2rem' }}
            >
              <TextField
                type="text"
                id="npc-nick-name"
                fullWidth
                label="Npc nick-name"
                variant="outlined"
                sx={{ mb: "2rem" }}
                value={npcData.nickName}
                onChange={(e) => {
                  const newValue = e.target.value;
                  updateNpcDetails(newValue, 'nickName');
                  npcMethods.setValue("nickName", newValue);
                  npcMethods.trigger("nickName");
                }}
                error={!!npcMethods.formState.errors.nickName}
                helperText={createNpcErrorMessage(npcMethods, "nickName")}
              />
              <TextField
                type="email"
                disabled
                id="email-input"
                fullWidth
                label="Email"
                variant="outlined"
                value={`${npcData.nickName}_${npcData.email}`}
                sx={{ mb: "2rem" }}
              />

              <TextField
                type="text"
                id="npc-role"
                fullWidth
                label="NPC role"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...npcMethods.register("npcRole")}
                error={!!npcMethods.formState.errors.npcRole}
                helperText={createNpcErrorMessage(npcMethods, "npcRole")}
              />

              <TextField
                type="text"
                id="npc-rules"
                fullWidth
                label="NPC rules"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...npcMethods.register("npcRules")}
                error={!!npcMethods.formState.errors.npcRules}
                helperText={createNpcErrorMessage(npcMethods, "npcRules")}
              />

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Avatar</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={chosenAvatar?.value}
                  label="Avatar"
                  onChange={handleAvatarChange}
                  sx={{ marginBottom: '15px' }}
                >
                  { npcAvatars && npcAvatars.map((avatar) => (
                    <MenuItem
                      key={avatar.id}
                      value={avatar.value}
                    >
                      {avatar.label}
                    </MenuItem>
                  ))}
                </Select>

                <TextField
                  type="text"
                  id="avatar"
                  fullWidth
                  label="Avatar url"
                  variant="outlined"
                  value={customAvatarUrl}
                  sx={{ mb: "2rem" }}
                  onChange={(e) => updateAvatar(e.target.value)}
                />
              </FormControl>

              <LoadingButton
                variant='contained'
                sx={{ mt: 1 }}
                fullWidth
                disableElevation
                type='submit'
                loading={CreateNpcResponse.isLoading}
              >
                Create NPC
              </LoadingButton>
            </Box>
          </FormProvider>
        </ModalDialog>
      </Modal>
    </>
  );
}
