import React from 'react'
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Layouts
import { Layout } from "./layouts/common/layout";
import { AdminLayout } from "./layouts/admin/admin-layout";

// Pages
import ForgotPasswordPage from "./pages/login/forgot-password.page";
import ResetPasswordPage from './pages/login/reset-password.page';
import { LoginPage } from "./pages/login/login.page";
import { HomePage } from "./pages/home/home.page";
import { ProfilePage } from "./pages/profile.page";
import { AdminPage } from "./pages/admin/admin.page";
import { UsersPage } from "./pages/users/users.page";
import { CompaniesPage } from "./pages/admin/companies/companies.page";
import { CompanyPage } from "./pages/admin/companies/company.page";
import { UserPage } from "./pages/users/user.page";
import { TemplatesPage } from "./pages/templates/templates.page";
import { TemplatePage } from "./pages/templates/template.page";
import { ContentsPage } from "./pages/contents/contents.page";
import { ContentPage } from "./pages/contents/content.page";
import { MeetingsPage } from "./pages/meetings/meetings.page";
import { MeetingPage } from "./pages/meetings/meeting.page";
import { ManagersPage } from "./pages/managers/managers.page";
import { ManagerPage } from "./pages/managers/manager.page";
import { CompanyAdminsPage } from "./pages/admin/company-admin/company-admins.page";
import { CompanyAdminPage } from "./pages/admin/company-admin/compnay-admin.page";
import { LocationPage } from "./pages/admin/locations/location.page";
import { LocationsPage } from "./pages/admin/locations/locations.page";
import { FinishMeetingPage } from "./pages/guest/finish-meeting.page";

// Components
import RequireUserComponent from './components/require-user.component';
import { FullScreenLoaderComponent } from "./components/full-screen-loader.component";
import { RoleEnum } from "./shared/role.enum";
import { useAppSelector } from "./store/store";
import { MeetingContainerCheckDevicesPage } from "./pages/meetings/meeting-container-check-devices.page";

function App() {
  const isLoading = useAppSelector((state) => state.appState.loading);

  return (
    <>
      <ToastContainer />
      { isLoading && (
        <FullScreenLoaderComponent />
      )}
      <Routes>
        <Route path='/' element={<Layout />}>
          {/* Private Route */}
          <Route element={<RequireUserComponent allowedRoles={[RoleEnum.MANAGER, RoleEnum.ADMIN, RoleEnum.COMPANY_ADMIN]} />}>
            <Route path="/" element={<HomePage />} />
          </Route>
          <Route element={<RequireUserComponent allowedRoles={[RoleEnum.MANAGER, RoleEnum.ADMIN, RoleEnum.COMPANY_ADMIN]} />}>
            <Route path='profile' element={<ProfilePage />} />
          </Route>
          <Route element={<RequireUserComponent allowedRoles={[RoleEnum.MANAGER, RoleEnum.ADMIN]} />}>
            <Route path='users' element={<UsersPage />} />
            <Route path='user/:id' element={<UserPage />} />
            <Route path='templates' element={<TemplatesPage />} />
            <Route path='template/:id' element={<TemplatePage />} />
            <Route path='contents' element={<ContentsPage />} />
            <Route path='content/:id' element={<ContentPage />} />
            <Route path='meetings' element={<MeetingsPage />} />
            <Route path='meeting/:id' element={<MeetingPage />} />
          </Route>
          <Route element={<RequireUserComponent allowedRoles={[RoleEnum.COMPANY_ADMIN]} />}>
            <Route path='managers' element={<ManagersPage />} />
            <Route path='manager/:id' element={<ManagerPage />} />
          </Route>
        </Route>
        <Route path='forgot-password' element={<ForgotPasswordPage />} />
        <Route path='reset-password' element={<ResetPasswordPage />}>
          <Route path=':resetToken' element={<ResetPasswordPage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<RequireUserComponent allowedRoles={[RoleEnum.ADMIN]} />}>
          <Route path='/admin' element={<AdminLayout />}>
            <Route path='/admin' element={<AdminPage />} />
            <Route path='/admin/companies' element={<CompaniesPage />} />
            <Route path='/admin/company/:id' element={<CompanyPage />} />
            <Route path='/admin/company-admins' element={<CompanyAdminsPage />} />
            <Route path='/admin/company-admin/:id' element={<CompanyAdminPage />} />
            <Route path='/admin/locations' element={<LocationsPage />} />
            <Route path='/admin/location/:id' element={<LocationPage />} />
          </Route>
        </Route>
        <Route path='meeting-container' element={<MeetingContainerCheckDevicesPage />} />
        <Route path='meeting-finished' element={<FinishMeetingPage />} />
      </Routes>
    </>
  )
}

export default App
