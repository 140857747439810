import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from "../customFetchBase";
import { ILocation } from "./interfaces/location.interface";
import { IUpdateLocation } from "./interfaces/update-location.interface";

export const locationsApi = createApi({
  reducerPath: 'locationsApi',
  baseQuery: customFetchBase,
  tagTypes: ['Locations'],
  endpoints: (builder) => ({
    createLocation: builder.mutation<ILocation, ILocation>({
      query(location: ILocation) {
        return {
          url: '/locations',
          method: 'POST',
          credentials: 'include',
          body: location,
        };
      },
      transformResponse(location: ILocation) {
        location.id = location._id;
        return location;
      }
    }),
    updateLocation: builder.mutation<ILocation, { id: string; query: IUpdateLocation }>(
      {
        query({ id, query }) {
          return {
            url: `/locations/${id}`,
            method: 'PATCH',
            credentials: 'include',
            body: query,
          };
        },
        transformResponse(location: ILocation) {
          if (location.companyId) {
            const company = location.companyId as any;
            location.companyName = company.title;
          }
          return location;
        }
      }
    ),
    getLocationById: builder.query<ILocation, string>({
      query(id) {
        return {
          url: `/locations/${id}`,
          credentials: 'include',
        };
      },
      transformResponse(location: ILocation) {
        if (location.companyId) {
          const company = location.companyId as any;
          location.companyName = company.title;
        }
        return location;
      }
    }),
    locationsList: builder.query<ILocation[], void>({
      query() {
        return {
          url: `/locations`,
          credentials: 'include',
        };
      },
      transformResponse(res: ILocation[]): ILocation[] {
        res.forEach((location: ILocation) => {
          location.id = location._id;
          if (location.companyId) {
            const company = location.companyId as any;
            location.companyName = company.title;
          }
        })
        return res;
      }
    }),
    deleteLocation: builder.mutation<ILocation, string>({
      query(id) {
        return {
          url: `/locations/${id}`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
    }),
  }),
});

export const {
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useUpdateLocationMutation,
  useGetLocationByIdQuery,
  useLocationsListQuery,
} = locationsApi;
