import React, { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { Button, ModalDialog } from "@mui/joy";
import {
  Box,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
  Chip
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import {useCreateMeetingMutation, useMeetingListQuery} from "../../store/api/meetings/meeting";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { IUser } from "../../store/api/users/interfaces/user.interface";
import { useUserListForMeetingQuery } from "../../store/api/users/users";
import { useTemplateListQuery } from "../../store/api/tempates/template";
import { ITemplate } from "../../store/api/tempates/interfaces/template.interface";
import { IMeetingOptions } from "../../store/api/meetings/interfaces/meeting-options.interface";
import { timeFormat } from "../../shared/helpers/time-format.helper";
import { toast } from "react-toastify";
import { MeetingOptionKeysEnum } from "../../store/api/meetings/enums/meeting-option-keys.enum";
import moment from "moment";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import {IMeeting} from "../../store/api/meetings/interfaces/meeting.interface";

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: #15112B;
  color: #D1B657;
  width: 150px;

  &:hover {
    background-color: #15112B;
    transform: translateY(-1px);
  }
`;

const columns: GridColDef[] = [
  { field: 'title', headerName: 'Title', width: 150 },
  {
    field: 'createdBy',
    headerName: 'Creator',
    width: 150,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.createdBy.firstName}</div>;
    },
  },
  {
    field: 'time.start',
    headerName: 'Start',
    width: 150,
    renderCell: (params) => {
      return <div className="rowitem">{moment(params.row.time.start).format(timeFormat)}</div>;
    },
  },
  {
    field: 'time.duration',
    headerName: 'Duration',
    width: 150,
    renderCell: (params) => {
      return <div className="rowitem">{new Date(params.row.time.duration * 1000).toISOString().substring(11, 16)}</div>;
    },
  },
  {
    field: 'members',
    headerName: 'Members',
    width: 150,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.members.length + 1}</div>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
  },
];

export function MeetingsPage() {
  const navigate = useNavigate();
  const minimumMeetingDurations = 15 * 60;

  const [createMeeting, CreateMeetingResponse] = useCreateMeetingMutation();
  const [meetings, setMeetings] = useState<IMeeting[]>([]);

  const GetMeetingResponse = useMeetingListQuery(undefined, { refetchOnMountOrArgChange: true });
  const GetUsersResponse = useUserListForMeetingQuery(undefined, { refetchOnMountOrArgChange: true });
  const GetTemplatesResponse = useTemplateListQuery(undefined, { refetchOnMountOrArgChange: true });
  const [users, setUsers] = useState<IUser[]>([]);
  const [templates, setTemplates] = useState<ITemplate[]>([]);

  const [openDialog, setOpen] = React.useState<boolean>(false);
  const [meetingTitle, setMeetingTitle] = React.useState('');
  const [meetingDuration, setMeetingDuration] = React.useState<{ hour: number; minutes: number }>({
    hour: 1,
    minutes: 0
  });
  const [meetingDate, setMeetingDate] = React.useState('');
  const [meetingTemplate, setMeetingTemplate] = useState<string>('');
  const [meetingUsers, setMeetingUsers] = useState<string[]>([]);
  const [meetingOptions, setOptions] = useState<IMeetingOptions[]>([
    { label: "Microphone", key: MeetingOptionKeysEnum.micro, value: true },
    { label: "Camera", key: MeetingOptionKeysEnum.camera, value: true },
    { label: "Spectator mode", key: MeetingOptionKeysEnum.spectatorMode, value: true },
    { label: "Text chat", key: MeetingOptionKeysEnum.textChat, value: false },
  ]);

  useEffect(() => {
    if (GetMeetingResponse.data) {
      setMeetings(GetMeetingResponse.data);
    }
  }, [
    GetMeetingResponse.isSuccess,
    GetMeetingResponse.data
  ]);

  useEffect(() => {
    if (GetMeetingResponse.isError) {
      if (Array.isArray((GetMeetingResponse.error as any).data.error)) {
        (GetMeetingResponse.error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((GetMeetingResponse.error as any).data.message), {
          position: 'top-right',
        });
      }
    }
  }, [
    GetMeetingResponse.isError,
    GetMeetingResponse.error,
  ]);

  useEffect(() => {
    if (GetUsersResponse.data) {
      setUsers(GetUsersResponse.data);
    }
  }, [
    GetUsersResponse.isSuccess,
    GetUsersResponse.data
  ]);

  useEffect(() => {
    if (GetTemplatesResponse.data) {
      setTemplates(GetTemplatesResponse.data);
    }
  }, [
    GetTemplatesResponse.isSuccess,
    GetTemplatesResponse.data
  ]);

  const creatingMeeting = () => {
    let creatingMeetingError: boolean = false;

    if (!meetingTitle) {
      toast.error(JSON.stringify('Title is required field'), {
        position: 'top-right',
      });
      creatingMeetingError = true;
    }

    if (!meetingTemplate) {
      toast.error(JSON.stringify('Meeting template is required option'), {
        position: 'top-right',
      });
      creatingMeetingError = true;
    }

    if (!meetingDate) {
      toast.error(JSON.stringify('Please set the meeting start date'), {
        position: 'top-right',
      });
      creatingMeetingError = true;
    }

    if (meetingDuration.minutes > 60) {
      toast.error(JSON.stringify('Meeting timeline is wrong'), {
        position: 'top-right',
      });
      creatingMeetingError = true;
    }

    if (meetingDuration.hour > 8) {
      toast.error(JSON.stringify('The meeting time should not exceed 8h'), {
        position: 'top-right',
      });
      creatingMeetingError = true;
    }

    const durationsInSeconds = meetingDuration.hour * 60 * 60 + meetingDuration.minutes * 60;

    if (durationsInSeconds === 0) {
      toast.error(JSON.stringify('Please set the meeting durations time'), {
        position: 'top-right',
      });
      creatingMeetingError = true;
    }

    if (durationsInSeconds < minimumMeetingDurations) {
      toast.error(JSON.stringify('The durations of meeting is too short'), {
        position: 'top-right',
      });
      creatingMeetingError = true;
    }

    const payload = {
      title: meetingTitle,
      templateId: meetingTemplate,
      time: {
        start: meetingDate,
        duration: durationsInSeconds,
      },
      members: meetingUsers,
      options: meetingOptions
    }
    if (!creatingMeetingError) {
      createMeeting(payload);
    }
  };

  useEffect(() => {
    if (CreateMeetingResponse.isSuccess && CreateMeetingResponse.data) {
      toast.success('Meeting created successfully');
      setMeetings([...meetings, CreateMeetingResponse.data]);
      setOpen(false);
      setMeetingTitle('');
      setMeetingDuration({
        hour: 1,
        minutes: 0
      });
      setMeetingDate('');
      setMeetingTemplate('');
      setMeetingUsers([]);
      setOptions([
        { label: "Microphone", key: MeetingOptionKeysEnum.micro, value: true },
        { label: "Camera", key: MeetingOptionKeysEnum.camera, value: true },
        { label: "Spectator mode", key: MeetingOptionKeysEnum.spectatorMode, value: true },
        { label: "Text chat", key: MeetingOptionKeysEnum.textChat, value: false },
      ]);
    }

    if (CreateMeetingResponse.isError) {
      if (Array.isArray((CreateMeetingResponse.error as any).data.error)) {
        (CreateMeetingResponse.error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((CreateMeetingResponse.error as any).data.message), {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    CreateMeetingResponse.isSuccess,
    CreateMeetingResponse.data,
    CreateMeetingResponse.isError,
    CreateMeetingResponse.error,
  ]);

  const updateOption = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    meetingOptions.forEach((o) => {
      if (o.key === key) {
        o.value = event.target.checked;
      }
    });
    setOptions([...meetingOptions]);
  };

  const updateMeetingTemplate = (event: SelectChangeEvent<typeof meetingTemplate>) => {
    const { target: { value }} = event;
    setMeetingTemplate(value);
  };
  const updateMeetingUsers = (event: SelectChangeEvent<typeof meetingUsers>) => {
    const { target: { value }} = event;
    setMeetingUsers(typeof value === 'string' ? value.split(',') : value);
  };

  const updateMeetingTitle = (event: any) => {
    const { target: { value }} = event;
    setMeetingTitle(value);
  }

  const updateMeetingDuration = (event: any, type: string) => {
    const newValue = event.target.value;
    if (type === 'minutes') {
      setMeetingDuration({ ...meetingDuration, ...{ minutes: newValue } });
    } else {
      setMeetingDuration({ ...meetingDuration, ...{ hour: newValue } });
    }
  }

  const updateMeetingDate = (event: any) => {
    setMeetingDate(new Date(event).toISOString());
  }

  // @ts-ignore
  return (
    <>
      <div className={'flex flex-col'}>
        <div className={'flex justify-between items-center users-header-wrapper bg-gray-100 px-5 py-3'}>
          <div className={'text-2xl header-title'}>
            Meeting list:
          </div>
          <div className={'add-user'}>
            <Button
              variant="outlined"
              color="neutral"
              startDecorator={<Add />}
              onClick={() => setOpen(true)}
            >
              Create meeting
            </Button>
          </div>
        </div>
        <div className={'bg-gray-100 px-5 pb-3 h-[80vh]'}>
          <div className={'parallax-table'}>
            { meetings && (
              <DataGrid
                rows={meetings}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                onCellClick={(e) => {
                  navigate(`/meeting/${e.row._id}`);
                }}
              />
            ) }
          </div>
        </div>
      </div>
      <Modal open={openDialog} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: 1200 }}
        >
          <Box
            width='100%'
            sx={{
              backgroundColor: '#fff',
              p: { xs: '1rem', sm: '2rem' },
              borderRadius: 1,
            }}
          >
            <Typography
              textAlign='center'
              component='h2'
              sx={{
                fontSize: "26px",
                mb: 2,
                color: '#15112B',
                letterSpacing: 1,
              }}
            >
              Create meeting
            </Typography>
            <Box className={'flex justify-between items-center mb-8'}>
              <TextField
                type="text"
                id="title"
                fullWidth
                label="Title"
                size="small"
                variant="outlined"
                sx={{
                  width: "250px",
                }}
                value={meetingTitle}
                onChange={updateMeetingTitle}
              />
              <LoadingButton
                variant='contained'
                fullWidth
                disableElevation
                type='submit'
                loading={CreateMeetingResponse.isLoading}
                onClick={creatingMeeting}
              >
                Create
              </LoadingButton>
            </Box>
            <Box className={'flex'}>
              <Box className={'basis-1/3 pr-5'}>
                <Typography
                  component='h5'
                  sx={{
                    fontSize: "16px",
                    color: '#15112B',
                    letterSpacing: 1,
                    marginBottom: "10px",
                  }}
                >
                  Allow for guest to:
                </Typography>
                <Box className={'flex flex-col mb-5'}>
                  <Box className={'flex items-center'}>
                    <Switch checked={meetingOptions.find((i) => i.key === "micro")?.value} onChange={(e) => updateOption(e, 'micro')}/>
                    <Typography component='h6' sx={{ fontSize: "14px" }}>Guest Microphone</Typography>
                  </Box>
                  <Box className={'flex items-center'}>
                    <Switch checked={meetingOptions.find((i) => i.key === "camera")?.value} onChange={(e) => updateOption(e, 'camera')}/>
                    <Typography component='h6' sx={{ fontSize: "14px" }}>Guest Camera</Typography>
                  </Box>
                  <Box className={'flex items-center'}>
                    <Switch checked={meetingOptions.find((i) => i.key === "spectatorMode")?.value} onChange={(e) => updateOption(e, 'spectatorMode')}/>
                    <Typography component='h6' sx={{ fontSize: "14px" }}>Spectator mode</Typography>
                  </Box>
                  <Box className={'flex items-center'}>
                    <Switch checked={meetingOptions.find((i) => i.key === "textChat")?.value} onChange={(e) => updateOption(e, 'textChat')}/>
                    <Typography component='h6' sx={{ fontSize: "14px" }}>Text Chat</Typography>
                  </Box>
                </Box>
                <Box className={'flex flex-col'}>
                  <Box className={'flex'}>
                    <TextField
                      type="number"
                      id="hour-duration"
                      fullWidth
                      label="Duration"
                      variant="outlined"
                      inputProps={{
                        inputMode: 'numeric',
                        min: 0,
                        max: 100000,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">hour</InputAdornment>,
                        inputProps: { min: 0, max: 8 }
                      }}
                      sx={{
                        marginBottom: "15px",
                        marginRight: "5px"
                      }}
                      value={meetingDuration.hour}
                      onChange={(e) => updateMeetingDuration(e, 'hour')}
                    />
                    <TextField
                      type="number"
                      id="min-duration"
                      fullWidth
                      label="Duration"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">min</InputAdornment>,
                        inputProps: { min: 0, max: 60 }
                      }}
                      sx={{
                        marginBottom: "15px",
                        marginLeft: "5px"
                      }}
                      value={meetingDuration.minutes}
                      onChange={(e) => updateMeetingDuration(e, 'minutes')}
                    />
                  </Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                      <DateTimePicker
                        label="Please pick the date"
                        value={meetingDate}
                        onChange={(newValue) => updateMeetingDate(newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box className={'basis-1/3 flex'}>
                <Box className={'flex w-full px-2'}>
                  { users && templates && (
                    <div className={'flex flex-col w-full'}>
                      <Select
                        sx={{ height: "40px", marginBottom: "10px" }}
                        fullWidth
                        onChange={(e) => updateMeetingTemplate(e)}
                        value={meetingTemplate}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <em>Select template...</em>
                        </MenuItem>
                        {templates.map((template) => (
                          <MenuItem
                            key={template._id}
                            value={template._id}
                          >
                            {template.title}
                          </MenuItem>
                        ))}
                      </Select>
                      <Select
                        sx={{ height: "40px" }}
                        fullWidth
                        multiple
                        displayEmpty
                        onChange={updateMeetingUsers}
                        value={meetingUsers}
                        renderValue={(selected: any) => {
                          if (selected.length === 0) {
                            return <em>Add user to meet...</em>;
                          } else {
                            return `Users selected ${selected.length}`;
                          }
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        {users.map((user) => (
                          <MenuItem
                            key={user._id}
                            value={user._id}
                          >
                            <div className={'flex justify-between w-full'}>
                              <div>
                                <Chip
                                  label={user.role.slice(0,1)}
                                  variant="outlined"
                                  size="small"
                                  color="success"
                                  className={'mr-2'}
                                />
                                {user.firstName}
                              </div>
                              <div>{user.email}</div>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  )}
                </Box>
              </Box>
              <Box className={'basis-1/3 h-[47vh] overflow-auto'}>
                <Box className={'w-full shadow-xl bg-gray-50 p-3 h-[47vh] overflow-auto'}>
                  <Typography
                    component='h5'
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Summary information:
                  </Typography>
                  <Box>
                    <Box className={'flex items-center mb-2'}>
                      <Typography
                        component='h5'
                        sx={{
                          fontSize: "16px",
                          marginRight: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        Title:
                      </Typography>
                      <Typography
                        component='h6'
                        sx={{
                          fontSize: "14px",
                        }}
                      >
                        { !meetingTitle && (
                          <>
                            <Box className={"text-red-400"}>plz set the title</Box>
                          </>
                        ) }
                        { meetingTitle && (
                          <>
                            <Box>{meetingTitle}</Box>
                          </>
                        )}
                      </Typography>
                    </Box>
                    <Box className={'flex items-center mb-2'}>
                      <Typography
                        component='h5'
                        sx={{
                          fontSize: "16px",
                          marginRight: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        Duration:
                      </Typography>
                      <Box>
                        <Typography
                          component='h6'
                          sx={{
                            fontSize: "14px",
                          }}
                        >
                          <Box>Hour: {meetingDuration.hour} min: {meetingDuration.minutes}</Box>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={'flex items-center mb-2'}>
                      <Typography
                        component='h5'
                        sx={{
                          fontSize: "16px",
                          marginRight: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        Date:
                      </Typography>
                      <Typography
                        component='h6'
                        sx={{
                          fontSize: "14px",
                        }}
                      >
                        { !meetingDate && (
                          <>
                            <Box className={"text-red-400"}>plz set the meeting date</Box>
                          </>
                        ) }
                        { meetingDate && (
                          <>
                            <Box>{moment(meetingDate).format(timeFormat)}</Box>
                          </>
                        )}
                      </Typography>
                    </Box>
                    <Box className={'mb-2'}>
                      <Typography
                        component='h5'
                        sx={{
                          fontSize: "16px",
                          marginRight: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        Participants:
                      </Typography>
                      <Box
                        sx={{
                          fontSize: "14px",
                          marginLeft: "10px",
                        }}
                      >
                        { meetingUsers.length === 0 && (
                          <>
                            <Box className={"text-red-400"}>plz add users to the meeting</Box>
                          </>
                        ) }
                        { meetingUsers.length > 0 && (
                          <>
                            { meetingUsers.map((id) => {
                              const user = users.find((u) => u._id === id);
                              if (user) {
                                return (
                                  <Box key={id}>- {user.firstName}({user.email})</Box>
                                )
                              }
                              return '';
                            })}
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box className={'flex items-center mb-2'}>
                      <Typography
                        component='h5'
                        sx={{
                          fontSize: "16px",
                          marginRight: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        Template:
                      </Typography>
                      <Box
                        sx={{
                          fontSize: "14px",
                        }}
                      >
                        { !meetingTemplate && (
                          <>
                            <Box className={"text-red-400"}>plz select template</Box>
                          </>
                        ) }
                        { meetingTemplate && (
                          <>
                            <Box>{templates.find((t) => t._id === meetingTemplate)?.title}</Box>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box className={'mb-2'}>
                      <Typography
                        component='h5'
                        sx={{
                          fontSize: "16px",
                          marginRight: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        Allows:
                      </Typography>
                      <Box
                        sx={{
                          fontSize: "14px",
                          marginLeft: "10px",
                        }}
                      >
                        { meetingOptions.map((o) => {
                          if (o.value) {
                            return (
                              <Box
                                key={o.key}
                                sx={{
                                  fontSize: "14px",
                                }}
                              >
                                - { o.label }
                              </Box>
                            );
                          }
                          return '';
                        }) }
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
}
