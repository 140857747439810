import { Box, Paper } from "@mui/material";
import * as React from "react";

export function FinishMeetingPage() {
  return (
    <>
      <Box className={'flex w-full h-[100vh] justify-center align-middle items-center'}>
        <Paper
          className={'w-[600px] h-[500px] p-10 flex flex-col justify-center align-middle'}
          elevation={1}
        >
          <Box className={'mb-5 font-bold  text-center'}>MIVR greetings</Box>
          <Box className={'mb-10 text-center'}>
            Thank you for your active participation in our VR meeting. We truly appreciate your engagement and valuable input.

            Should you have any additional questions or require further information, please do not hesitate to get in touch with us - <a className={'text-blue-600 underline'} href='mailto:info@vr-parallax.net'>info@vr-parallax.net</a>.
            We are here to assist you and provide any necessary clarification or support.
          </Box>
          <Box className={'flex justify-center'}>
            <img width={300} height={200} src="/images/pages/meeting-end/meeting-end.png" alt="end-meeting"/>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
