import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  IconButton, InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Autocomplete,
  Tooltip,
  Typography, Chip
} from "@mui/material";
import { ManageAccounts, MoreTime } from "@mui/icons-material";
import { IMeeting } from "../../store/api/meetings/interfaces/meeting.interface";
import {
  useAddOrRemoveUserMutation,
  useGetMeetingByIdQuery,
  useUpdateMeetingMutation,
  useUpdateMeetingTimelinesMutation
} from "../../store/api/meetings/meeting";
import moment from "moment/moment";
import { timeFormat } from "../../shared/helpers/time-format.helper";
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import TitleIcon from '@mui/icons-material/Title';
import DeleteIcon from '@mui/icons-material/Delete';
import { IUser } from "../../store/api/users/interfaces/user.interface";
import { ITemplate } from "../../store/api/tempates/interfaces/template.interface";
import { useUserListForMeetingQuery } from "../../store/api/users/users";
import { useTemplateListQuery } from "../../store/api/tempates/template";
import { MeetingOptionKeysEnum } from "../../store/api/meetings/enums/meeting-option-keys.enum"
import { IMeetingOptions } from "../../store/api/meetings/interfaces/meeting-options.interface";
import { MeetingEmailEnum } from "../../store/api/meetings/enums/meeting-email.enum";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import EditIcon from '@mui/icons-material/Edit';
import { toast } from "react-toastify";
import dayjs from "dayjs";


export function MeetingPage() {
  const id = useParams<{ id: string }>().id as string;

  const [isEditTimeLine, toggleEditTimeline] = useState<boolean>(false);
  const [meeting, setMeeting] = useState<IMeeting | null>(null);
  const [users, setUsers] = useState<IUser[]>();
  const [templates, setTemplates] = useState<ITemplate[]>();
  const [meetingDate, setMeetingDate] = useState<string | null>(null);
  const [meetingDuration, setMeetingDuration] = React.useState<{ hour: number; minutes: number }>({
    hour: 0,
    minutes: 0
  });

  const GetMeetingResponse = useGetMeetingByIdQuery(id, { refetchOnMountOrArgChange: true });
  const GetUsersResponse = useUserListForMeetingQuery(undefined, { refetchOnMountOrArgChange: true });
  const GetTemplatesResponse = useTemplateListQuery(undefined, { refetchOnMountOrArgChange: true });

  const [updateMeeting, UpdateMeetingResponse] = useUpdateMeetingMutation();
  const [updateMeetingTimelines, UpdateMeetingTimelinesResponse] = useUpdateMeetingTimelinesMutation();
  const [addOrRemoveUser, AddOrRemoveUserResponse] = useAddOrRemoveUserMutation();

  useEffect(() => {
    if (GetMeetingResponse.isSuccess && GetUsersResponse.isSuccess && GetTemplatesResponse.isSuccess) {
      setUsers(GetUsersResponse.data);
      setMeeting(GetMeetingResponse.data);
      setTemplates(GetTemplatesResponse.data);
    }
  }, [
    GetMeetingResponse.isSuccess,
    GetMeetingResponse.data,
    GetUsersResponse.isSuccess,
    GetUsersResponse.data,
    GetTemplatesResponse.isSuccess,
    GetTemplatesResponse.data,
  ]);

  useEffect(() => {
    if (UpdateMeetingTimelinesResponse.isSuccess) {
      setMeeting(UpdateMeetingTimelinesResponse.data);
      toast.success('Meeting timelines was changed successful');
    }
    if (UpdateMeetingTimelinesResponse.isError) {
      const error = UpdateMeetingTimelinesResponse.error as undefined as any;
      toast.error(error.data.message);
    }
  }, [
    UpdateMeetingTimelinesResponse.data,
    UpdateMeetingTimelinesResponse.isSuccess,
    UpdateMeetingTimelinesResponse.error,
    UpdateMeetingTimelinesResponse.isError
  ])

  useEffect(() => {
    if (UpdateMeetingResponse.isSuccess) {
      setMeeting(UpdateMeetingResponse.data);
      toast.success('Meeting data was updated');
    }
    if (UpdateMeetingResponse.isError) {
      const error = UpdateMeetingResponse.error as undefined as any;
      toast.error(error.data.message);
    }
  }, [
    UpdateMeetingResponse.data,
    UpdateMeetingResponse.isSuccess,
    UpdateMeetingResponse.error,
    UpdateMeetingResponse.isError
  ]);

  const updateOption = (event: React.ChangeEvent<HTMLInputElement>, key: MeetingOptionKeysEnum) => {
    if (meeting && meeting.options) {
      const op: IMeetingOptions[] = [
        { label: "Microphone", key: MeetingOptionKeysEnum.micro, value: meeting.options.find((o) => o.key === MeetingOptionKeysEnum.micro)?.value as boolean },
        { label: "Camera", key: MeetingOptionKeysEnum.camera, value: meeting.options.find((o) => o.key === MeetingOptionKeysEnum.camera)?.value as boolean },
        { label: "Spectator mode", key: MeetingOptionKeysEnum.spectatorMode, value: meeting.options.find((o) => o.key === MeetingOptionKeysEnum.spectatorMode)?.value as boolean },
        { label: "Text chat", key: MeetingOptionKeysEnum.textChat, value: meeting.options.find((o) => o.key === MeetingOptionKeysEnum.textChat)?.value as boolean },
      ];
      op.forEach((o) => {
        if (o.key === key) {
          o.value = event.target.checked;
        }
      });
      updateMeeting({
        id,
        query: {
          options: op
        }
      })
    }
  };

  const updateMeetingTemplate = (event: SelectChangeEvent<string>) => {
    const { target: { value }} = event;
    updateMeeting({
      id,
      query: {
        templateId: value
      }
    })
  };

  const removeUser = (memberId: string) => {
    if (meeting && meeting.members.length > 0) {
      const userIds = meeting.members.map((m) => m._id);
      const filteredIds = userIds.filter((i) => i !== memberId);
      addOrRemoveUserFromMeeting(memberId, MeetingEmailEnum.decline);
      updateMeeting({
        id,
        query: {
          members: filteredIds
        }
      });
    }
  }

  const addOrRemoveUserFromMeeting = (userId: string, type: MeetingEmailEnum) => {
    const payload = { userId, meetingId: id, type };
    addOrRemoveUser(payload);
  }

  const updateMeetingUsers = (userEmail: string) => {
    const user = users.find((user) => user.email === userEmail);
    const isMemberExist = meeting.members.find((u: any) => user._id === u._id);
    if (meeting) {
      if (!isMemberExist) {
        addOrRemoveUserFromMeeting(user._id, MeetingEmailEnum.invite)
        updateMeeting({
          id,
          query: {
            members: [...meeting.members.map((m) => m._id), user._id],
          }
        });
      } else {
        toast.error('This user already added to meeting');
      }
    } else {
      toast.error('Some error');
    }
  };

  const updateMeetingDate = (event: any) => {
    setMeetingDate(new Date(event).toISOString());
  }

  const updateMeetingDuration = (event: any, type: string) => {
    const newValue = event.target.value;
    if (type === 'minutes') {
      setMeetingDuration({ ...meetingDuration, ...{ minutes: newValue } });
    } else {
      setMeetingDuration({ ...meetingDuration, ...{ hour: newValue } });
    }
  }

  const updateMeetingStartAndDurations = (event: any) => {
    if (meetingDuration.minutes > 60) {
      toast.error(JSON.stringify('Meeting timeline is wrong'), {
        position: 'top-right',
      });
    }

    if (meetingDuration.hour > 8) {
      toast.error(JSON.stringify('The meeting time should not exceed 8h'), {
        position: 'top-right',
      });
    }
    const durationsInSeconds = meetingDuration.hour * 60 * 60 + meetingDuration.minutes * 60;

    if (durationsInSeconds > 0) {
      updateMeetingTimelines({
        id,
        query: {
          time: {
            duration: durationsInSeconds,
            start: meetingDate || meeting.time.start
          },
        }
      })
    } else {
      toast.error("Error in meeting timelines", {
        position: 'top-right',
      })
    }
  }

  return (
    <>
      <div className={'bg-gray-100 h-[85vh] p-10'}>
        { meeting && (
          <Box className={'flex mt-5'}>
            <Box className={'shadow-2xl mr-1 px-5 py-10 basis-4/12 h-[74vh]'}>
              <Typography paragraph>
                Meeting details:
              </Typography>
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="Template title">
                      <ListItemIcon>
                        <TitleIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={meeting.title ? meeting.title : 'no data'}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="Created by...">
                      <ListItemIcon>
                        <ManageAccounts />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={meeting.createdBy ? meeting.createdBy.firstName : 'no data'}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="Start date">
                      <ListItemIcon>
                        <MoreTime />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={meeting.time ? moment(meeting.time.start).format(timeFormat) : 'no data'}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="Meetin duration">
                      <ListItemIcon>
                        <TimelapseIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={meeting.time ? new Date(meeting.time.duration * 1000).toISOString().substring(11, 16) : 'no data'}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="Template title">
                      <ListItemIcon>
                        <ViewComfyIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={meeting.templateId ? meeting.templateId.title : 'no data'}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider/>
              </List>
            </Box>
            <Box className={'shadow-2xl ml-1 px-5 py-10 basis-8/12 h-[74vh] overflow-auto'}>
              { meeting && (
                <Box className={'flex'}>
                  <Box className={'flex flex-col basis-1/2'}>
                    {/* Update meeting options */}
                    <Box className={'mb-5'}>
                      <Typography
                        component='h5'
                        sx={{ fontSize: "16px", color: '#15112B', marginBottom: "10px" }}
                      >
                        Allow for guest to:
                      </Typography>
                      <Box className={'flex items-center'}>
                        <Switch checked={meeting.options.find((i) => i.key === "micro")?.value} onChange={(e) => updateOption(e, MeetingOptionKeysEnum.micro)}/>
                        <Typography component='h6' sx={{ fontSize: "14px" }}>Guest Microphone</Typography>
                      </Box>
                      <Box className={'flex items-center'}>
                        <Switch checked={meeting.options.find((i) => i.key === "camera")?.value} onChange={(e) => updateOption(e, MeetingOptionKeysEnum.camera)}/>
                        <Typography component='h6' sx={{ fontSize: "14px" }}>Guest Camera</Typography>
                      </Box>
                      <Box className={'flex items-center'}>
                        <Switch checked={meeting.options.find((i) => i.key === "spectatorMode")?.value} onChange={(e) => updateOption(e, MeetingOptionKeysEnum.spectatorMode)}/>
                        <Typography component='h6' sx={{ fontSize: "14px" }}>Spectator mode</Typography>
                      </Box>
                      <Box className={'flex items-center'}>
                        <Switch checked={meeting.options.find((i) => i.key === "textChat")?.value} onChange={(e) => updateOption(e, MeetingOptionKeysEnum.textChat)}/>
                        <Typography component='h6' sx={{ fontSize: "14px" }}>Text Chat</Typography>
                      </Box>
                    </Box>
                    {/* Update templates */}
                    <Box className={"mr-2"}>
                      <Typography
                        component='h5'
                        sx={{ fontSize: "16px", color: '#15112B', marginBottom: "10px" }}
                      >
                        Update meeting template:
                      </Typography>
                      <Select
                        sx={{ height: "40px", marginBottom: "10px" }}
                        fullWidth
                        onChange={(e) => updateMeetingTemplate(e)}
                        value={meeting.templateId ? meeting.templateId._id : ''}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        { templates && templates.map((template) => (
                          <MenuItem
                            key={template._id}
                            value={template._id}
                          >
                            {template.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                  <Box className={'flex flex-col basis-1/2'}>
                    {/*Meeting participants*/}
                    <Box className={'mb-5'}>
                      <Typography component='h5' sx={{ fontSize: "16px", color: '#15112B', marginBottom: "10px" }}>
                        Meeting participants:
                      </Typography>
                      <List dense className={'h-[227px] overflow-auto'}>
                        { meeting.members.map((user) => {
                          return (
                            <ListItem
                              disablePadding
                              key={user._id}
                              secondaryAction={
                                <IconButton
                                  onClick={() => removeUser(user._id)}
                                  edge="end"
                                  aria-label="delete"
                                  color="warning"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              }
                            >
                              <ListItemButton>
                                <ListItemText  primary={user.firstName} secondary={user.email}/>
                              </ListItemButton>
                            </ListItem>
                          )
                        }) }
                        { meeting.members.length === 0 && (
                          <div className={'text-red-400'}>
                            No one user is a part of the meeting
                          </div>
                        )}
                      </List>
                    </Box>
                    {/*Update meeting participants*/}
                    <Box className={"ml-2 mb-2"}>
                      <Typography component='h5' sx={{ fontSize: "16px", color: '#15112B', marginBottom: "10px" }}>
                        Update meeting participants:
                      </Typography>
                      <Autocomplete
                        freeSolo
                        size='small'
                        id="add-members"
                        disableClearable
                        options={users.map((user) => user.email)}
                        renderOption={(props, email) => {
                          return (
                            <MenuItem
                              key={email}
                              value={email}
                              onClick={(value) => {
                                updateMeetingUsers(email); // Викликати вашу функцію при виборі рядка
                              }}
                              sx={{ backgroundColor: meeting.members.find((user) => user.email === email) ? 'lightgray' : 'white'}}
                            >
                              <div className={'flex justify-between w-full'}>
                                <div>
                                  <Chip
                                    label={users.find((user) => user.email === email).role.slice(0,1)}
                                    variant="outlined"
                                    size="small"
                                    color="success"
                                    className={'mr-2'}
                                  />
                                  {users.find((user) => user.email === email).firstName}
                                </div>
                                <div>{email}</div>
                              </div>
                            </MenuItem>
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search user"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box className={"ml-2"}>
                      <Box className={'flex items-center justify-between my-5'}>
                        <Typography
                          component='h5'
                          sx={{ fontSize: "16px", color: '#15112B' }}
                        >
                          Update meeting timelines:
                        </Typography>
                        <Box>
                          <EditIcon
                            color='primary'
                            className={'cursor-pointer'}
                            onClick={() => toggleEditTimeline(!isEditTimeLine)}
                          />
                        </Box>
                      </Box>
                      { isEditTimeLine && (
                        <Box className={'flex flex-col'}>
                          <Box className={'flex'}>
                            <TextField
                              type="number"
                              id="hour-duration"
                              fullWidth
                              label="Duration"
                              variant="outlined"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">hour</InputAdornment>,
                                inputProps: { min: 0, max: 8 }
                              }}
                              sx={{
                                marginBottom: "15px",
                                marginRight: "5px"
                              }}
                              value={meetingDuration.hour}
                              onChange={(e) => updateMeetingDuration(e, 'hour')}
                            />
                            <TextField
                              type="number"
                              id="min-duration"
                              fullWidth
                              label="Duration"
                              variant="outlined"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">min</InputAdornment>,
                                inputProps: { min: 0, max: 60 }
                              }}
                              sx={{
                                marginBottom: "15px",
                                marginLeft: "5px"
                              }}
                              value={meetingDuration.minutes}
                              onChange={(e) => updateMeetingDuration(e, 'minutes')}
                            />
                          </Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DateTimePicker']}>
                              <DateTimePicker
                                label="Please pick the date"
                                value={dayjs(meeting?.time?.start)}
                                onChange={(newValue) => updateMeetingDate(newValue)}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <Button
                            fullWidth
                            color='primary'
                            variant="outlined"
                            sx={{
                              marginTop: '15px',
                              backgroundColor: '#15112B',
                              color: '#D1B657'
                            }}
                            onClick={(e) => updateMeetingStartAndDurations(e)}
                          >
                            Change
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </div>
    </>
  );
}
