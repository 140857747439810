import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemButton,
  ListItemIcon, Tooltip, TextField, DialogContentText
} from "@mui/material";
import {
  useAssignManagerToCompanyMutation, useDeleteUserMutation,
  useGetUserByIdQuery,
  useUpdateUserMutation
} from "../../../store/api/users/users";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useCompaniesListQuery } from "../../../store/api/companies/company";
import { Email, ManageAccounts } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ITransformedUser } from '../../../store/api/users/interfaces/transformed-user.interface';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import {IUpdateManager} from "../../../store/api/users/interfaces/update-manager.interface";
import {ICompany} from "../../../store/api/companies/interfaces/company.interface";
import {toast} from "react-toastify";
import ToastMessages from "../../../constants/toast.messages";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

export function CompanyAdminPage() {
  const id = useParams<{ id: string }>().id as string;
  const navigate = useNavigate();

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [user, setUser] = useState<ITransformedUser>();
  const [companies, setCompanies] = useState<ICompany[]>();

  const GetUserResponse = useGetUserByIdQuery(id);
  const GetCompaniesResponse = useCompaniesListQuery();

  const [assignManager, AssignAdminResponse] = useAssignManagerToCompanyMutation();

  const [updateUser, UpdateUserResponse] = useUpdateUserMutation();
  const [deleteUser, DeleteUserResponse] = useDeleteUserMutation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (GetUserResponse.data) {
      setUser(GetUserResponse.data);
    }
    if (GetCompaniesResponse.data) {
      setCompanies(GetCompaniesResponse.data);
    }
    if (AssignAdminResponse.data) {
      setUser(AssignAdminResponse.data);
      toast.success(ToastMessages.success);
    }
    if (UpdateUserResponse.data) {
      setUser(UpdateUserResponse.data);
      toast.success(ToastMessages.success);
    }
    if (DeleteUserResponse.isSuccess) {
      navigate('/admin/company-admins')
    }
  }, [
    navigate,
    GetUserResponse.isSuccess,
    GetUserResponse.data,
    GetCompaniesResponse.isSuccess,
    GetCompaniesResponse.data,
    AssignAdminResponse.isSuccess,
    AssignAdminResponse.data,
    UpdateUserResponse.isSuccess,
    UpdateUserResponse.data,
    DeleteUserResponse.isSuccess,
    DeleteUserResponse.data
  ]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (companyId?: string) => {
    if (companyId) {
      assignManager({
        companyId: companyId,
        userId: id,
      });
    }
    setAnchorEl(null);
  };

  const update = () => {
    const payload: IUpdateManager = {
      firstName: user.firstName,
      avatar: user.avatar,
      phone: user.phone,
    }
    updateUser({
      id,
      query: payload,
    });
  }

  const updateAdminDetails = (newValue: string, key: string) => {
    setUser((prevAdmin) => {
      if (prevAdmin) {
        return { ...prevAdmin, [key]: newValue };
      }
      return prevAdmin;
    });
  };

  const handleCloseDeleteDialog = (isDelete: boolean) => {
    if (isDelete) {
      setOpenDeleteDialog(false);
      deleteUser(id);
    } else {
      setOpenDeleteDialog(false);
    }
  };

  return (
    <>
      { user && companies && (
        <Box className={'flex flex-row bg-gray-100 p-5 h-[87vh]'}>
          <Box className={'shadow-2xl mr-1 px-5 py-10 basis-4/12'}>
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <Tooltip title="Admin first name">
                    <ListItemIcon>
                      <ManageAccounts />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={user.firstName}
                  />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding>
                <ListItemButton>
                  <Tooltip title="Admin contact">
                    <ListItemIcon>
                      <ContactPhoneIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={user.phone}
                  />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding>
                <ListItemButton>
                  <Tooltip title="Admin email">
                    <ListItemIcon>
                      <Email />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={user.email}
                  />
                </ListItemButton>
              </ListItem>
              <Divider/>
            </List>
          </Box>
          <Box className={'shadow-2xl ml-1 px-5 py-5 basis-8/12 flex flex-col justify-between overflow-auto'}>
            <Box>
              <Box className={'flex flex-row justify-between mb-5'}>
                <Button
                  id="couriers"
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Assign to company
                </Button>
                <Menu
                  id="couriers"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => handleClose()}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {
                    companies.map((company, i) => {
                      return (
                        <MenuItem
                          onClick={() => handleClose(company._id)}
                          key={company._id}
                        >
                          {company.title}
                        </MenuItem>
                      )
                    })
                  }
                </Menu>
                {
                  !user.companyId && (
                    <div className={'text-2xl font-bold text-red-400'}>
                      Company is not assign
                    </div>
                  )
                }
                {
                  user.companyId && (
                    <div className={'text-2xl font-bold'}>
                      Assigned to company: {user.companyName}
                    </div>
                  )
                }
              </Box>
              <Box className={'ml-2'}>
                <TextField
                  size='small'
                  type="text"
                  id="firstName"
                  fullWidth
                  label="First name"
                  variant="outlined"
                  value={user.firstName}
                  onChange={(e) => updateAdminDetails(e.target.value, 'firstName')}
                  sx={{ mb: "2rem" }}
                />
                <TextField
                  size='small'
                  type="text"
                  id="phone"
                  fullWidth
                  label="Contact"
                  variant="outlined"
                  value={user.phone}
                  onChange={(e) => updateAdminDetails(e.target.value, 'phone')}
                  sx={{ mb: "2rem" }}
                />
              </Box>
            </Box>
            <Box className={'flex justify-end w-full'}>
              <Button
                size="small"
                color="success"
                variant="outlined"
                onClick={update}
                sx={{ marginRight: '10px' }}
              >
                UPDATE
              </Button>
              <Button
                size="small"
                color="error"
                variant="outlined"
                onClick={() => setOpenDeleteDialog(true)}
              >
                DELETE
              </Button>
            </Box>
          </Box>
          <Dialog
            open={openDeleteDialog}
            onClose={() => handleCloseDeleteDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure to delete this account?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deleting this account will lead to its complete loss and it will be impossible to restore it
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => handleCloseDeleteDialog(false)}
              >
                NO
              </Button>
              <Button
                size="small"
                color="error"
                variant="outlined"
                onClick={() => handleCloseDeleteDialog(true)}
              >
                YES
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
}
