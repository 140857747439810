import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Button,
  DialogContentText,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Menu,
} from "@mui/material";
import {
  useDeleteLocationMutation,
  useGetLocationByIdQuery,
  useUpdateLocationMutation
} from "../../../store/api/locations/location";
import { useCompaniesListQuery } from "../../../store/api/companies/company";
import { Subtitles } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ILocation } from "../../../store/api/locations/interfaces/location.interface";
import { IUpdateLocation } from "../../../store/api/locations/interfaces/update-location.interface";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import ApartmentIcon from '@mui/icons-material/Apartment';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export function LocationPage() {
  const id = useParams<{ id: string }>().id as string;
  const [companyName, setCompanyName] = useState<string>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const { data: initLocation } = useGetLocationByIdQuery(id);
  const { data: companies } = useCompaniesListQuery();

  const [location, setLocation] = useState<ILocation>();
  const [updateLocation, updateResponse] = useUpdateLocationMutation();
  const [deleteLocation, deleteResponse] = useDeleteLocationMutation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (companyId?: string) => {
    if (companyId) {
      updateLocation({
        id,
        query: { companyId }
      });
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (initLocation) {
      setLocation(initLocation);
      if (initLocation.companyName) {
        setCompanyName(initLocation.companyName);
      }
    }
  }, [initLocation]);

  useEffect(() => {
    if (deleteResponse.isSuccess) {
      toast.success('Location deleted successfully');
      navigate('/admin/locations');
    }
    if (updateResponse.isSuccess) {
      setLocation(updateResponse.data);
      if (updateResponse.data.companyName) {
        setCompanyName(updateResponse.data.companyName);
      }
      toast.success('Location updated successfully');
    }
  }, [
    deleteResponse.isSuccess,
    updateResponse.isSuccess,
  ]);

  const update = () => {
    if (location && location._id) {
      const payload: IUpdateLocation = {
        companyId: location.companyId,
        title: location.title,
        environment: location.environment,
        presenter: location.presenter
      }
      updateLocation({
        id: location._id,
        query: payload
      });
    }
  }

  const handleCloseDeleteDialog = (isDelete: boolean) => {
    if (isDelete) {
      setOpenDeleteDialog(false);
      deleteLocation(id);
    } else {
      setOpenDeleteDialog(false);
    }
  };

  return (
    <>
      { location && companies && (
        <Box className={'flex flex-row bg-gray-100 p-5 h-[87vh]'}>
          <Box className={'shadow-2xl mr-1 px-5 py-10 basis-4/12'}>
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <Tooltip title="Title">
                    <ListItemIcon>
                      <Subtitles />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={location.title}
                  />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding>
                <ListItemButton>
                  <Tooltip title="Company">
                    <ListItemIcon>
                      <ApartmentIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={location.companyName ? location.companyName : 'Not assign'}
                  />
                </ListItemButton>
              </ListItem>
              <Divider/>
            </List>
          </Box>
          <Box className={'shadow-2xl ml-1 px-5 py-5 basis-8/12 flex flex-col justify-between overflow-auto'}>
            <Box>
              { location && (
                <>

                  <Box className={'flex flex-row justify-between mb-5'}>
                    <Button
                      id="couriers"
                      aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      variant="contained"
                      disableElevation
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Assign to company
                    </Button>
                    <Menu
                      id="couriers"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => handleClose()}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {
                        companies.map((company, i) => {
                          return (
                            <MenuItem
                              onClick={() => handleClose(company._id)}
                              key={company._id}
                            >
                              {company.title}
                            </MenuItem>
                          )
                        })
                      }
                    </Menu>
                    {
                      !location.companyId && (
                        <div className={'text-2xl font-bold text-red-400'}>
                          Company is not assign
                        </div>
                      )
                    }
                    {
                      location.companyId && (
                        <div className={'text-2xl font-bold'}>
                          Assigned to company: {location.companyName}
                        </div>
                      )
                    }
                  </Box>
                  <Box className={'mb-2'}>
                    <Box className={'text-xl font-bold'}>Environment:</Box>
                    <Box className={'text-lg'}>
                      <p className={'ml-2'}>Title: { location.environment.title ? location.environment.title : "No data" }</p>
                      <p className={'ml-2'}>AssetId: { location.environment.assetId ? location.environment.assetId : "No data" }</p>
                    </Box>
                  </Box>
                  <Box className={'mb-2'}>
                    <Box className={'text-xl font-bold'}>Presenter:</Box>
                    <Box className={'text-lg'}>
                      <p className={'ml-2'}>Title: { location.presenter.title ? location.presenter.title : "No data" }</p>
                      <p className={'ml-2'}>AssetId: { location.presenter.assetId ? location.presenter.assetId : "No data" }</p>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            <Box className={'flex justify-end w-full'}>
              {/*<Button*/}
              {/*  size="small"*/}
              {/*  color="success"*/}
              {/*  variant="outlined"*/}
              {/*  onClick={update}*/}
              {/*  sx={{ marginRight: '10px' }}*/}
              {/*>*/}
              {/*  UPDATE*/}
              {/*</Button>*/}
              <Button
                size="small"
                color="error"
                variant="outlined"
                onClick={() => setOpenDeleteDialog(true)}
              >
                DELETE
              </Button>
            </Box>
          </Box>
          <Dialog
            open={openDeleteDialog}
            onClose={() => handleCloseDeleteDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure to delete this location?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deleting this account will lead to its complete loss and it will be impossible to restore it
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => handleCloseDeleteDialog(false)}
              >
                NO
              </Button>
              <Button
                size="small"
                color="error"
                variant="outlined"
                onClick={() => handleCloseDeleteDialog(true)}
              >
                YES
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
}
