import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
  Box,
  Typography,
  List,
  ListItem,
  Divider,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  DialogContentText,
  TextField,
  Button
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { Select, Option, Chip } from "@mui/joy";
import { Delete } from "@mui/icons-material";
import ArticleIcon from '@mui/icons-material/Article';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useEffect, useState } from "react";
import {
  useAddFilesContentMutation, useDeleteContentMutation,
  useGetContentByIdQuery,
  useUpdateContentMutation
} from '../../store/api/contents/content';
import { IContent } from "../../store/api/contents/interfaces/content.interface";
import { useDropzone } from "react-dropzone";
import "./content.styles.css";
import { IContentFile } from "./interfaces/file.interface";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export function ContentPage() {
  const id = useParams<{ id: string }>().id as string;
  const { data: initContent } = useGetContentByIdQuery(id,
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  const navigate = useNavigate();

  const [files, setFiles] = useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [content, setContent] = useState<IContent>();

  const [uploadContent, uploadResponse] = useAddFilesContentMutation();
  const [updateContent, updateResponse] = useUpdateContentMutation();
  const [removeContent, deleteResponse] = useDeleteContentMutation();

  const { acceptedFiles, getRootProps, getInputProps }: any = useDropzone();

  useEffect(() => {
    if (initContent) {
      setContent(initContent);
    }
  }, [initContent]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach((f: any) => f.fileType = "2D");
      setFiles(acceptedFiles);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (uploadResponse.isSuccess) {
      setContent(uploadResponse.data);
      toast.success('Content added successfully');
      setFiles(null);
    }
    if (uploadResponse.isError) {
      const error = uploadResponse.error as undefined as any;
      toast.error(error.data.message);
      setFiles(null);
    }
  }, [
    uploadResponse.isSuccess,
    uploadResponse.data,
    uploadResponse.isError,
    uploadResponse.error
  ]);

  useEffect(() => {
    if (deleteResponse.isSuccess) {
      toast.success('Content deleted successfully');
      navigate('/contents');
    }
    if (deleteResponse.isError) {
      const error = deleteResponse.error as undefined as any;
      toast.error(error.data.message);
    }
  }, [
    deleteResponse.isSuccess,
    deleteResponse.isError,
    deleteResponse.error,
  ]);

  useEffect(() => {
    if (updateResponse.isSuccess) {
      setContent(updateResponse.data);
      toast.success('Content updated successfully');
    }
  }, [updateResponse]);

  function removeFileFromList(file: any) {
    if (files) {
      const filtered = files.filter((f: any) => f.path !== file.path);
      setFiles(filtered);
    }
  }

  function removeFile(file: IContentFile) {
    if (content) {
      const filtered = content.files.filter((f: any) => f.uuid !== file.uuid);
      updateContent({
        id,
        query: {
          files: filtered
        }
      })
    } else {
      toast.error('Has no content', {
        position: 'top-right',
      })
    }
  }

  function uploadFiles() {
    const formData = new FormData();
    if (files) {
      files.forEach((f: any) => {
        formData.append('files', f);
        formData.append('fileType', f.fileType);
      });

      uploadContent({
        contentId: id,
        files: formData
      });
    }
  }

  const updateFileType = ({ newValue, file }: any) => {
    if (files) {
      files.forEach((f: any) => {
        if (f.name === file.name) {
          f.fileType = newValue;
        }
      });
    }
  };

  const handleClose = (isDelete: boolean) => {
    if (isDelete) {
      setOpenDeleteDialog(false);
      removeContent(id);
    } else {
      setOpenDeleteDialog(false);
    }
  };

  const updateContentDetails = (newValue: string, key: string) => {
    setContent((prevContent) => {
      if (prevContent) {
        return { ...prevContent, [key]: newValue };
      }
      return prevContent;
    });
  }

  const update = () => {
    const payload = {
      title: content.title,
    }
    updateContent({
      id,
      query: payload,
    });
  }

  return (
    <>
      { content && (
        <Box className={'flex flex-row bg-gray-100 p-5 h-[87vh]'}>
          <Box className={'shadow-2xl mr-1 px-5 py-5 basis-4/12'}>
            <Typography paragraph>
              Content details:
            </Typography>
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={content.title}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <AssignmentIndIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={content.creatorId.firstName}
                  />
                </ListItemButton>
              </ListItem>
              <Divider/>
            </List>
          </Box>
          <Box className={'shadow-2xl ml-1 px-5 py-5 basis-8/12 flex flex-col justify-between items-center'}>
            <Box className={'flex w-full'}>
              <Box className={'basis-6/12 pr-5'}>
                <Box>
                  <TextField
                    size='small'
                    type="text"
                    id="content-title"
                    fullWidth
                    label="Edit content title"
                    variant="outlined"
                    value={content.title}
                    onChange={(e) => updateContentDetails(e.target.value, 'title')}
                    sx={{ mb: "2rem", width: "300px" }}
                  />
                </Box>
                Uploaded files:
                <ul className={'h-[370px] overflow-auto'}>
                  { content.files.map((file: IContentFile) => (
                    <li key={file.uuid} className={'file-wrapper'}>
                      <div>
                        {file.name} - {file.fileSize} bytes
                      </div>
                      <div className={'flex justify-between items-center'}>
                        <Chip
                          size="md"
                          variant="soft"
                        >
                          {file.type}
                        </Chip>
                        <div
                          className={'file-icon ml-5'}
                          onClick={() => removeFile(file)}
                        >
                          <Delete />
                        </div>
                      </div>
                    </li>
                  )) }
                </ul>
              </Box>
              <Box className={'basis-6/12 mt-20'}>
                <Box {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </Box>
                <div>
                  { files && files.length > 0 && (
                    <>
                      <div className={'flex justify-between items-center mb-5'}>
                        <h4>Files:</h4>
                        <LoadingButton
                          size="small"
                          color="success"
                          variant="outlined"
                          loading={uploadResponse.isLoading}
                          onClick={() => uploadFiles()}
                        >
                          UPLOAD
                        </LoadingButton>
                      </div>
                      <ul className={'h-[330px] overflow-auto'}>
                        { files.map((file: any) => (
                          <li key={file.path} className={'file-wrapper'}>
                            <div>
                              {file.path} - {file.size} bytes
                            </div>
                            <div className={'flex justify-between items-center'}>
                              <div className={'mr-2'}>
                                <Select
                                  color="primary"
                                  size="sm"
                                  variant="soft"
                                  defaultValue="2D"
                                  onChange={(e, newValue) => updateFileType({ newValue, file })}
                                >
                                  <Option value="2D">2D</Option>
                                  <Option value="3D">3D</Option>
                                  <Option value="360">360</Option>
                                  <Option value="DB">DB</Option>
                                </Select>
                              </div>
                              <div
                                className={'file-icon'}
                                onClick={() => removeFileFromList(file)}
                              >
                                <Delete />
                              </div>
                            </div>
                          </li>
                        )) }
                      </ul>
                    </>
                  )}
                </div>
              </Box>
            </Box>
            <Box></Box>
            <Box className={'flex justify-end w-full'}>
              <Button
                size="small"
                color="success"
                variant="outlined"
                onClick={update}
                sx={{ marginRight: '10px' }}
              >
                UPDATE
              </Button>
              <Button
                size="small"
                color="warning"
                variant="outlined"
                onClick={() => setOpenDeleteDialog(true)}
              >
                DELETE
              </Button>
            </Box>
          </Box>
          <Dialog
            open={openDeleteDialog}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure to delete this content?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deleting this data will lead to its complete loss and it will be impossible to restore it
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleClose(false)}
                sx={{
                  backgroundColor: '#15112B',
                  color: '#D1B657'
                }}
              >
                NO
              </Button>
              <Button
                onClick={() => handleClose(true)}
                sx={{
                  backgroundColor: '#15112B',
                  color: '#D1B657'
                }}
              >
                YES
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
}
