import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from "../customFetchBase";
import { IMeeting } from "./interfaces/meeting.interface";
import {IUpdateMeeting} from "./interfaces/update-meeting.interface";
import {IInviteDeclineEmail} from "./interfaces/invite-decline-email.interface";

export const meetingsApi = createApi({
  reducerPath: 'meetingsApi',
  baseQuery: customFetchBase,
  tagTypes: ['Meetings'],
  endpoints: (builder) => ({
    createMeeting: builder.mutation<IMeeting, any>({
      query(meeting) {
        return {
          url: '/web-meetings',
          method: 'POST',
          credentials: 'include',
          body: meeting,
        };
      },
      transformResponse(meeting: IMeeting) {
        meeting.id = meeting._id;
        return meeting;
      }
    }),
    updateMeeting: builder.mutation<IMeeting, { id: string; query: IUpdateMeeting }>(
      {
        query({ id, query }) {
          return {
            url: `/web-meetings/${id}`,
            method: 'PATCH',
            credentials: 'include',
            body: query,
          };
        },
      }
    ),
    updateMeetingTimelines: builder.mutation<IMeeting, { id: string; query: IUpdateMeeting }>(
      {
        query({ id, query }) {
          return {
            url: `/web-meetings/timelines/${id}`,
            method: 'PATCH',
            credentials: 'include',
            body: query,
          };
        },
      }
    ),
    getMeetingById: builder.query<IMeeting, string>({
      query(id) {
        return {
          url: `/web-meetings/${id}`,
          credentials: 'include',
        };
      },
    }),
    getNextMeetingForUser: builder.query<IMeeting, string | undefined>({
      query(id: string) {
        return {
          url: `/web-meetings/for-user/${id}`,
          credentials: 'include',
        };
      },
    }),
    meetingList: builder.query<IMeeting[], void>({
      query() {
        return {
          url: `/web-meetings`,
          credentials: 'include',
        };
      },
      transformResponse(meetings: IMeeting[]): IMeeting[] {
        meetings.forEach((m) => {
          m.id = m._id;
        })
        return meetings;
      }
    }),
    meetingListByUserID: builder.query<IMeeting[], string>({
      query(userId: string) {
        return {
          url: `/web-meetings/meeting-list-by-user/${userId}`,
          credentials: 'include',
        };
      },
      transformResponse(meetings: IMeeting[]): IMeeting[] {
        meetings.forEach((m) => {
          m.id = m._id;
        })
        return meetings;
      }
    }),
    deleteMeeting: builder.mutation<IMeeting, string>({
      query(id) {
        return {
          url: `/web-meetings/${id}`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
    }),
    addOrRemoveUser: builder.mutation<IMeeting, IInviteDeclineEmail>({
      query(payload: IInviteDeclineEmail) {
        return {
          url: '/web-meetings/add-or-remove-user/',
          method: 'POST',
          credentials: 'include',
          body: payload
        };
      },
    }),
  }),
});

export const {
  useCreateMeetingMutation,
  useDeleteMeetingMutation,
  useMeetingListByUserIDQuery,
  useUpdateMeetingMutation,
  useUpdateMeetingTimelinesMutation,
  useGetMeetingByIdQuery,
  useGetNextMeetingForUserQuery,
  useMeetingListQuery,
  useAddOrRemoveUserMutation
} = meetingsApi;
