import * as React from 'react';
import { toast } from "react-toastify";
import { Box, Typography, Modal, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Button, ModalDialog } from "@mui/joy";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "@mui/material/styles";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import { LocationsTableComponent } from "./components/table.page";
import { useCreateLocationMutation } from "../../../store/api/locations/location";
import { CreateLocationInput, createLocationSchema } from "../../../validation-schemas/create-location.validation";
import { ILocation } from "../../../store/api/locations/interfaces/location.interface";

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: #15112B;
  color: #D1B657;

  &:hover {
    background-color: #15112B;
    transform: translateY(-1px);
  }
`;

export function LocationsPage() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [createLocation, { isLoading, isError, error, isSuccess, data: newLocation }] = useCreateLocationMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success('Location created successfully');
      setOpen(false);
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((error as any).data.message), {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const methods = useForm<CreateLocationInput>({
    resolver: zodResolver(createLocationSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmitHandler: SubmitHandler<CreateLocationInput> = (data) => {
    const newLocation: ILocation = {
      title: data.title,
      previewImage: data.previewImage,
      environment: {
        title: data.environmentTitle,
        assetId: data.environmentAssetId
      },
      presenter: {
        title: data.presenterTitle ? data.presenterTitle : "",
        assetId: data.presenterAssetId ? data.presenterAssetId : "",
      }
    }
    createLocation(newLocation);
  };

  const errorMessage = (methods: any, key: string) => {
    if(methods.formState.errors[key]) {
      return methods.formState.errors[key].message;
    }
    return '';
  }

  return (
    <>
      <div>
        <Box className={'flex justify-between items-center mb-5'}>
          <p className={'text-2xl font-bold'}>
            Locations list
          </p>
          <Button
            variant="outlined"
            color="neutral"
            startDecorator={<Add />}
            onClick={() => setOpen(true)}
          >
            New location
          </Button>
        </Box>
        <LocationsTableComponent newLocation={newLocation} />
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: 500, overflow: 'auto' }}
        >
          <FormProvider {...methods}>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmitHandler)}
              noValidate
              autoComplete='off'
              maxWidth='27rem'
              width='100%'
              sx={{
                backgroundColor: '#fff',
                p: { xs: '1rem', sm: '2rem' },
                borderRadius: 1,
              }}
            >
              <Typography
                textAlign='center'
                component='h1'
                sx={{
                  fontWeight: 600,
                  fontSize: "34px",
                  mb: 2,
                  color: '#15112B',
                  letterSpacing: 1,
                }}
              >
                Create new location
              </Typography>
              <TextField
                type="text"
                id="Title"
                fullWidth
                label="Title"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("title")}
                error={!!methods.formState.errors.title}
                helperText={errorMessage(methods, "title")}
              />
              <TextField
                type="text"
                id="previewImage"
                fullWidth
                label="Preview image url"
                variant="outlined"
                sx={{ mb: "2rem" }}
                {...methods.register("previewImage")}
                error={!!methods.formState.errors.previewImage}
                helperText={errorMessage(methods, "previewImage")}
              />
              <div className={'ml-5'}>
                <TextField
                  size="small"
                  type="text"
                  id="environmentTitle"
                  fullWidth
                  label="Environment title"
                  variant="outlined"
                  sx={{ mb: "2rem" }}
                  {...methods.register("environmentTitle")}
                  error={!!methods.formState.errors.environmentTitle}
                  helperText={errorMessage(methods, "environmentTitle")}
                />
                <TextField
                  size="small"
                  type="text"
                  id="environmentAssetId"
                  fullWidth
                  label="Environment assetId"
                  variant="outlined"
                  sx={{ mb: "2rem" }}
                  {...methods.register("environmentAssetId")}
                  error={!!methods.formState.errors.environmentAssetId}
                  helperText={errorMessage(methods, "environmentAssetId")}
                />
                <TextField
                  size="small"
                  type="text"
                  id="presenterTitle"
                  fullWidth
                  label="Presenter title"
                  variant="outlined"
                  sx={{ mb: "2rem" }}
                  {...methods.register("presenterTitle")}
                  error={!!methods.formState.errors.presenterTitle}
                  helperText={errorMessage(methods, "presenterTitle")}
                />
                <TextField
                  size="small"
                  type="text"
                  id="presenterAssetId"
                  fullWidth
                  label="Presenter assetId"
                  variant="outlined"
                  sx={{ mb: "2rem" }}
                  {...methods.register("presenterAssetId")}
                  error={!!methods.formState.errors.presenterAssetId}
                  helperText={errorMessage(methods, "presenterAssetId")}
                />
              </div>

              <LoadingButton
                variant='contained'
                sx={{ mt: 1 }}
                fullWidth
                disableElevation
                type='submit'
                loading={isLoading}
              >
                Submit
              </LoadingButton>
            </Box>
          </FormProvider>
        </ModalDialog>
      </Modal>
    </>
  );
}
