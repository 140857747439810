import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Divider,
  Tooltip,
  Select,
  MenuItem,
  SelectChangeEvent,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button, TextField
} from "@mui/material";
import {Add, FilePresent, ManageAccounts, Remove} from "@mui/icons-material";
import PlaceIcon from '@mui/icons-material/Place';
import RoomIcon from '@mui/icons-material/Room';
import TitleIcon from "@mui/icons-material/Title";
import { useEffect, useState } from "react";
import { ITemplate } from "../../store/api/tempates/interfaces/template.interface";
import {
  useDeleteTemplateMutation,
  useGetTemplateByIdQuery,
  useUpdateTemplateMutation
} from "../../store/api/tempates/template";
import { toast } from "react-toastify";
import { useLocationsListQuery } from "../../store/api/locations/location";
import { useContentListQuery } from "../../store/api/contents/content";
import { ILocation } from "../../store/api/locations/interfaces/location.interface";
import { IContent } from "../../store/api/contents/interfaces/content.interface";
import { ITemplateLocation } from "../../store/api/tempates/interfaces/template-location.interface";
import { IUpdateTemplate } from "../../store/api/tempates/interfaces/update-template.interface";
import ToastMessages from "../../constants/toast.messages";

export function TemplatePage() {
  const id = useParams<{ id: string }>().id as string;

  const [template, setTemplate] = useState<ITemplate>();
  const [locations, setLocations] = useState<ILocation[]>();
  const [mainLocation, setTemplateMainLocation] = useState<ILocation>();
  const [secondLocations, setTemplateSecondLocations] = useState<ILocation[]>();
  const [contents, setContents] = useState<IContent[]>();

  const [removeTemplate, deleteResponse] = useDeleteTemplateMutation();

  const GetTemplatesResponse = useGetTemplateByIdQuery(id, { refetchOnMountOrArgChange: true });
  const GetLocationsResponse = useLocationsListQuery(undefined, { refetchOnMountOrArgChange: true });
  const GetContentsResponse = useContentListQuery(undefined, { refetchOnMountOrArgChange: true });

  const [updateTemplate, UpdateTemplateResponse] = useUpdateTemplateMutation();

  const navigate = useNavigate();

  useEffect(() => {
    setTemplate(GetTemplatesResponse.data);
    setLocations(GetLocationsResponse.data);
    setContents(GetContentsResponse.data);
    if (deleteResponse.isSuccess) {
      toast.success('Template deleted successfully');
      navigate('/templates');
    }
  }, [
    GetContentsResponse.data,
    GetLocationsResponse.data,
    GetTemplatesResponse.data,
    deleteResponse.isSuccess,
    navigate,
  ]);

  useEffect(() => {
    if (UpdateTemplateResponse.isSuccess) {
      setTemplate(UpdateTemplateResponse.data);
      toast.success(ToastMessages.success);
    }
  }, [
    UpdateTemplateResponse.isSuccess,
    UpdateTemplateResponse.data
  ]);

  useEffect(() => {
    const mainLocation: ILocation = template?.locations.find((location: ITemplateLocation) => location.isMain).locationId;
    const secondLocation: ILocation[] = template?.locations.filter((location: ITemplateLocation) => !location.isMain).map((l: ITemplateLocation) => l.locationId);
    setTemplateMainLocation(mainLocation);
    setTemplateSecondLocations(secondLocation);
  }, [
    template
  ]);

  const remove = () => {
    removeTemplate(id);
  }

  const updateContent = (event: SelectChangeEvent<string>) => {
    const { target: { value }} = event;
    updateTemplate({
      id,
      query: {
        contentId: value
      }
    });
  }

  const updateMain = (event: SelectChangeEvent<string>) => {
    const { target: { value }} = event;
    const locations: any[] = [];
      template?.locations.forEach((l: any) => {
      if (!l.isMain) {
        locations.push({
          locationId: l.locationId._id,
          isMain: false
        });
      }
    })
    locations.push({ locationId: value, isMain: true });
    updateTemplate({
      id,
      query: { locations }
    })
  }

  const addSecondLocation = (locationId: string) => {
    if (locationId && secondLocations && template) {
      let secondIds = []

      secondLocations.forEach((l: ILocation) => {
        secondIds.push({ locationId: l._id, isMain: false });
      });

      secondIds.push({
        locationId,
        isMain: false
      });
      const mainLocation = template.locations.filter((l: ITemplateLocation) => l.isMain);
      secondIds.unshift({
        locationId: mainLocation[0].locationId._id,
        isMain: true,
      });

      updateTemplate({
        id,
        query: {
          locations: secondIds
        }
      });
    }
  }

  const removeSecondLocation = (locationId: string) => {
    if (locationId && secondLocations && template) {
      const secondLocations = template.locations.filter((l: ITemplateLocation) =>  !l.isMain);
      const mainLocations = template.locations.filter((l: ITemplateLocation) =>  l.isMain);
      const filteredSecondLocations = secondLocations.filter((l: ITemplateLocation) => l.locationId._id !== locationId);
      const merge = [...mainLocations, ...filteredSecondLocations];

      let payload: { isMain: boolean; locationId: string; }[] = [];
      merge.forEach((l: ITemplateLocation) => {
        payload.push({
          isMain: l.isMain,
          locationId: l.locationId._id
        });
      });

      updateTemplate({
        id,
        query: {
          locations: payload
        }
      });
    }
  }

  const updateTemplateDetails = (newValue: string, key: string) => {
    setTemplate((prevTemplate) => {
      if (prevTemplate) {
        return { ...prevTemplate, [key]: newValue };
      }
      return prevTemplate;
    });
  };

  const update = () => {
    const payload: IUpdateTemplate = {
      title: template.title,
    }
    updateTemplate({
      id,
      query: payload,
    });
  }

  return (
    <>
      { template && (
        <Box className={'flex flex-row bg-gray-100 p-5 h-[87vh]'}>
          <Box className={'shadow-2xl mr-1 px-5 py-5 basis-4/12'}>
            <Typography
              component='p'
              sx={{ fontWeight: 900, marginRight: '10px' }}
              paragraph
            >
              Template details:
            </Typography>
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <Tooltip title="Template title">
                    <ListItemIcon>
                      <TitleIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={template.title ? template.title : 'No title'} />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding>
                <ListItemButton>
                  <Tooltip title="Created by...">
                    <ListItemIcon>
                      <ManageAccounts />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={template.createdBy ? template.createdBy?.firstName : 'No data'} />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding>
                <ListItemButton>
                  <Tooltip title="Content title">
                    <ListItemIcon>
                      <FilePresent />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={template.contentId ? template.contentId?.title : 'No data'} />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding>
                <ListItemButton>
                  <Tooltip title="Main location">
                    <ListItemIcon>
                      <PlaceIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={mainLocation ? mainLocation?.title : 'No data'} />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding>
                <ListItemButton>
                  <Tooltip title="Second locations">
                    <ListItemIcon>
                      <RoomIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={secondLocations && secondLocations.length ? secondLocations.map((l: ILocation) => l.title).join(' / ') : 'No data'} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
          <Box className={'shadow-2xl ml-1 px-5 py-5 basis-8/12 flex flex-col justify-between overflow-auto'}>
            <Box>
              <Typography
                component='p'
                sx={{ fontWeight: 900, marginRight: '10px' }}
                paragraph
              >
                Editing details:
              </Typography>
              <Box className={'flex w-full h-full'}>
                <Box className={'flex flex-col w-full'}>
                  {/*Title*/}
                  <Box className={'flex pb-5'}>
                    <TextField
                      size='small'
                      type="text"
                      id="template-title"
                      fullWidth
                      label="Edit template title"
                      variant="outlined"
                      value={template.title}
                      onChange={(e) => updateTemplateDetails(e.target.value, 'title')}
                      sx={{ mb: "2rem", width: "300px" }}
                    />
                  </Box>
                  {/*Content*/}
                  <Box className={'flex pb-5'}>
                    <Typography
                      component='p'
                      sx={{ fontWeight: 600, marginRight: '10px', width: '100px' }}
                    >
                      Content:
                    </Typography>
                    <Select
                      sx={{ height: "30px", width: '150px' }}
                      fullWidth
                      onChange={(e) => updateContent(e)}
                      value={template.contentId ? template.contentId._id : ''}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      { contents && contents.map((content) => (
                        <MenuItem
                          key={content._id}
                          value={content._id}
                        >
                          {content.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  {/*Locations*/}
                  <Box className={'flex flex-col pb-5'}>
                    {/*Main locations*/}
                    <Box className={'flex flex-col pb-2'}>
                      <Typography
                        component='p'
                        sx={{ fontWeight: 600, marginBottom: '1rem' }}
                      >
                        Main location:
                      </Typography>
                      <Card sx={{ maxWidth: 300 }}>
                        <CardMedia
                          sx={{ height: 140 }}
                          image={mainLocation?.previewImage ? mainLocation.previewImage : 'https://placehold.co/600x400'}
                          title="Main location"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {mainLocation ? mainLocation?.title : 'No data'}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            It is the main location for your important and cool meeting
                          </Typography>
                        </CardContent>
                        <CardActions className={'flex justify-between'}>
                          <Box></Box>
                          <Box></Box>
                          <Select
                            sx={{ height: "30px", width: '150px' }}
                            fullWidth
                            onChange={(e) => updateMain(e)}
                            value={template.locations.find((l: any) => l.isMain)?.locationId?._id ? template.locations.find((l: any) => l.isMain)?.locationId?._id : ''}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            { locations && locations.map((location) => (
                              <MenuItem
                                key={location._id}
                                value={location._id}
                              >
                                {location ? location?.title : 'No data'}
                              </MenuItem>
                            ))}
                          </Select>
                        </CardActions>
                      </Card>
                    </Box>
                    {/*Second locations*/}
                    <Box className={'flex flex-col pb-2'}>
                      <>
                        <Typography component='p' sx={{ fontWeight: 600, marginBottom: '1rem' }} >
                          Second locations:
                        </Typography>
                        <Box className={'flex w-full'}>
                          { locations && locations.map((location: ILocation) => (
                            <Card key={location._id} className={'mr-2 basis-4/12 flex flex-col justify-between'}>
                              <CardMedia
                                sx={{ height: 140 }}
                                image={location?.previewImage ? location.previewImage : 'https://placehold.co/600x400'}
                                title="Main location"
                              />
                              <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                  {location ? location.title : 'No data'}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  It is the main location for your important and cool meeting
                                </Typography>
                              </CardContent>
                              { secondLocations && (
                                <CardActions>
                                  <Box className={'w-full'}>
                                    { !secondLocations.map((i: ILocation) => i._id).includes(location._id) && (
                                      <Button
                                        fullWidth
                                        color='success'
                                        variant="outlined"
                                        endIcon={<Add />}
                                        onClick={(e) => addSecondLocation(location._id)}
                                      >
                                        Add
                                      </Button>
                                    )}
                                    { secondLocations.map((i: ILocation) => i._id).includes(location._id) && (
                                      <Button
                                        fullWidth
                                        color='error'
                                        variant="outlined"
                                        endIcon={<Remove />}
                                        onClick={(e) => removeSecondLocation(location._id)}
                                      >
                                        Remove
                                      </Button>
                                    )}
                                  </Box>
                                </CardActions>
                              )}
                            </Card>
                          ))}
                        </Box>
                      </>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={'flex justify-end w-full'}>
              <Button
                size="small"
                color="success"
                variant="outlined"
                onClick={update}
                sx={{ marginRight: '10px' }}
              >
                UPDATE
              </Button>
              {/*<Button*/}
              {/*  size="small"*/}
              {/*  color="error"*/}
              {/*  variant="outlined"*/}
              {/*  onClick={remove}*/}
              {/*>*/}
              {/*  DELETE*/}
              {/*</Button>*/}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
