import { object, string, TypeOf } from 'zod';

const createLocationSchema = object({
  title: string()
    .min(1, 'Title is required')
    .max(20, 'Too long'),
  environmentTitle: string()
    .min(1, 'Environment title is required')
    .max(100, 'Too long'),
  environmentAssetId: string()
    .min(1, 'Environment assetId is required')
    .max(50, 'Too long'),
  presenterTitle: string()
    .optional(),
  presenterAssetId: string()
    .optional(),
  previewImage: string()
    .min(1, 'Preview image is required')
    .max(500, 'Too long'),
});

export type CreateLocationInput = TypeOf<typeof createLocationSchema>;

export { createLocationSchema };
