import { object, string, TypeOf } from 'zod';

const createCompanyAdminSchema = object({
  firstName: string()
    .min(1, 'Name is required')
    .max(20, 'Too long'),
  email: string()
    .min(1, 'Email address is required')
    .email('Email Address is invalid'),
  phone: string().optional(),
  password: string()
    .min(1, 'Password is required')
    .min(6, 'Password must be more than 6 characters')
    .max(50, 'Password must be less than 32 characters'),
});

export type CreateCompanyAdminInput = TypeOf<typeof createCompanyAdminSchema>;

export { createCompanyAdminSchema };
