import React, { useEffect, useState } from "react";
import { useCreateTemplateMutation, useTemplateListQuery } from "../../store/api/tempates/template";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Add } from "@mui/icons-material";
import { Button, ModalDialog } from "@mui/joy";
import { Box, Modal, TextField, Typography, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "@mui/material/styles";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { CreateTemplateInput, createTemplateSchema } from "../../validation-schemas/create-template.validation";
import { toast } from "react-toastify";
import { useLocationsListQuery } from "../../store/api/locations/location";
import { useContentListQuery } from "../../store/api/contents/content";
import { ILocation } from "../../store/api/locations/interfaces/location.interface";
import { IContent } from "../../store/api/contents/interfaces/content.interface";
import { ITemplate } from "../../store/api/tempates/interfaces/template.interface";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ITemplateLocation } from "../../store/api/tempates/interfaces/template-location.interface";
import { useNavigate } from "react-router-dom";

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: #15112B;
  color: #D1B657;

  &:hover {
    background-color: #15112B;
    transform: translateY(-1px);
  }
`;

const columns: GridColDef[] = [
  { field: 'title', headerName: 'title', width: 150 },
  {
    field: 'createdBy',
    headerName: 'Creator',
    width: 150,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.createdBy.firstName}</div>;
    },
  },
  {
    field: 'mainLocations',
    headerName: 'Main locations',
    width: 250,
    renderCell: (params) => {
      if (params.row.locations.find((l: ITemplateLocation) => l.isMain).locationId) {
        return <div className="rowitem">{params.row.locations.find((l: ITemplateLocation) => l.isMain).locationId.title}</div>;
      }
      return <div className="rowitem">No locations</div>;
    },
  },
  {
    field: 'secondLocations',
    headerName: 'Selected',
    width: 150,
    renderCell: (params) => {
      const secondLocations = params.row.locations.filter((l: ITemplateLocation) => !l.isMain);

      if (secondLocations.length > 0) {
        const text = secondLocations.map((i: any) => i.locationId).map((l: any) => l.title).join(',');
        return <div className="rowitem">{text}</div>;
      }
      return <div className="rowitem">No locations</div>;
    },
  },
  {
    field: 'contentId',
    headerName: 'Content title',
    width: 150,
    renderCell: (params) => {
      if (params.row.contentId) {
        return <div className="rowitem">{params.row.contentId?.title}</div>;
      }
      return <div className="rowitem">No content</div>;
    },
  },
];

export function TemplatesPage() {
  const navigate = useNavigate();

  const [openDialog, setOpen] = React.useState<boolean>(false);
  const [locations, setLocations] = useState<ILocation[]>();
  const [contents, setContents] = useState<IContent[]>();
  const [mainTemplateLocation, setMainTemplateLocation] = useState<string>('');
  const [secondTemplateLocations, setSecondTemplateLocation] = useState<string[]>([]);
  const [templateContent, setTemplateContent] = useState<string>('');

  const GetLocationsResponse = useLocationsListQuery(undefined, { refetchOnMountOrArgChange: true });
  const GetContentsResponse = useContentListQuery(undefined, { refetchOnMountOrArgChange: true });
  const GetTemplatesResponse = useTemplateListQuery(undefined, { refetchOnMountOrArgChange: true });

  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [createTemplate, CreateTemplateResponse] = useCreateTemplateMutation();

  useEffect(() => {
    if (GetLocationsResponse.isSuccess && GetLocationsResponse.data) {
      setLocations(GetLocationsResponse.data);
    }
  }, [GetLocationsResponse.isSuccess, GetLocationsResponse.data]);

  useEffect(() => {
    if (GetContentsResponse.isSuccess && GetContentsResponse.data) {
      setContents(GetContentsResponse.data);
    }
  }, [GetContentsResponse.isSuccess, GetContentsResponse.data]);

  useEffect(() => {
    if (GetTemplatesResponse.isSuccess && GetTemplatesResponse.data) {
      setTemplates(GetTemplatesResponse.data);
    }

    if (GetTemplatesResponse.isError) {
      if (Array.isArray((GetTemplatesResponse.error as any).data.error)) {
        (GetTemplatesResponse.error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((GetTemplatesResponse.error as any).data.message), {
          position: 'top-right',
        });
      }
    }
  }, [
    GetTemplatesResponse.isSuccess,
    GetTemplatesResponse.data,
    GetTemplatesResponse.isError,
    GetTemplatesResponse.error
  ]);

  const onSubmitHandler: SubmitHandler<CreateTemplateInput> = (template: any) => {
    if (locations && contents) {
      const mainLocation = locations.find((l) => l.title === mainTemplateLocation);
      const content = contents.find((c) => c.title === templateContent);
      if (mainLocation) {
        const payload: ITemplate = {
          title: template.title,
          locations: [
            {
              locationId: mainLocation._id,
              isMain: true,
            },
          ],
          contentId: content?._id || null,
        }
        secondTemplateLocations.forEach((location) => {
          const secondLocation = locations.find((l) => l.title === location);
          if (secondLocation) {
            payload.locations.push({
              locationId: secondLocation._id,
              isMain: false,
            });
          }
        });
        createTemplate(payload);
      } else {
        if (!mainLocation) {
          toast.error("Main location is required");
        }
      }
    }
  };

  const methods = useForm<CreateTemplateInput>({
    resolver: zodResolver(createTemplateSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (CreateTemplateResponse.isSuccess && CreateTemplateResponse.data) {
      toast.success('Template created successfully');
      setOpen(false);
      setTemplates([...templates, CreateTemplateResponse.data]);
    }

    if (CreateTemplateResponse.isError) {
      if (Array.isArray((CreateTemplateResponse.error as any).data.error)) {
        (CreateTemplateResponse.error as any).data.error.forEach((el: any) =>
          toast.error(JSON.stringify(el.message), {
            position: 'top-right',
          })
        );
      } else {
        toast.error(JSON.stringify((CreateTemplateResponse.error as any).data.message), {
          position: 'top-right',
        });
      }
    }
  }, [
    CreateTemplateResponse.isSuccess,
    CreateTemplateResponse.data,
    CreateTemplateResponse.isError,
    CreateTemplateResponse.error
  ]);

  const errorMessage = (methods: any, key: string) => {
    if(methods.formState.errors[key]) {
      return methods.formState.errors[key].message;
    }
    return '';
  }

  const setMainLocation = (event: SelectChangeEvent<typeof mainTemplateLocation>) => {
    const { target: { value }} = event;
    setMainTemplateLocation(value);
  }
  const setSecondLocation = (event: SelectChangeEvent<typeof secondTemplateLocations>) => {
    const { target: { value }} = event;
    setSecondTemplateLocation(typeof value === 'string' ? value.split(',') : value);
  };

  const setContent = (event: SelectChangeEvent<typeof templateContent>) => {
    const { target: { value }} = event;
    setTemplateContent(value);
  }

  return (
    <>
      <div className={'flex flex-col'}>
        <div className={'flex justify-between items-center bg-gray-100 px-5 py-3'}>
          <div className={'text-2xl header-title'}>
            Template list:
          </div>
          <div className={'add-template'}>
            <Button
              variant="outlined"
              color="neutral"
              startDecorator={<Add />}
              onClick={() => setOpen(true)}
            >
              Create template
            </Button>
          </div>
        </div>
        <div className={'bg-gray-100 px-5 pb-3 h-[80vh]'}>
          <div className={'parallax-table'}>
            { templates && (
              <DataGrid
                rows={templates}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                onCellClick={(e) => {
                  navigate(`/template/${e.row._id}`);
                }}
              />
            ) }
          </div>
        </div>
      </div>
      <Modal open={openDialog} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: 500, overflow: 'auto' }}
        >
          <FormProvider {...methods}>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmitHandler)}
              noValidate
              autoComplete='off'
              maxWidth='27rem'
              width='100%'
              sx={{
                backgroundColor: '#fff',
                p: { xs: '1rem', sm: '2rem' },
                borderRadius: 1,
              }}
            >
              <Typography
                textAlign='center'
                component='h2'
                sx={{
                  fontSize: "26px",
                  mb: 2,
                  color: '#15112B',
                  letterSpacing: 1,
                }}
              >
                New template
              </Typography>
              <TextField
                type="text"
                id="Name"
                fullWidth
                label="Name"
                variant="outlined"
                sx={{ mb: "1rem" }}
                {...methods.register("title")}
                error={!!methods.formState.errors.title}
                helperText={errorMessage(methods, "title")}
              />
              { locations && contents && (
                <>
                  <Select
                    sx={{ mb: "1rem" }}
                    fullWidth
                    onChange={setMainLocation}
                    value={mainTemplateLocation}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="">
                      <em>Select main location...</em>
                    </MenuItem>
                    {locations.map((content) => (
                      <MenuItem
                        key={content.title}
                        value={content.title}
                      >
                        {content.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    sx={{ mb: "1rem" }}
                    fullWidth
                    multiple
                    displayEmpty
                    onChange={setSecondLocation}
                    value={secondTemplateLocations}
                    renderValue={(selected: any) => {
                      if (selected.length === 0) {
                        return <em>Select second location...</em>;
                      }
                      return selected.join(', ');
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {locations.map((content) => (
                      <MenuItem
                        key={content.title}
                        value={content.title}
                      >
                        {content.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    sx={{ mb: "1rem" }}
                    fullWidth
                    onChange={setContent}
                    value={templateContent}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="">
                      <em>Select content...</em>
                    </MenuItem>
                    {contents.map((content) => (
                      <MenuItem
                        key={content.title}
                        value={content.title}
                      >
                        {content.title}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}

              <LoadingButton
                variant='contained'
                sx={{ mt: 1 }}
                fullWidth
                disableElevation
                type='submit'
                loading={CreateTemplateResponse.isLoading}
              >
                Create
              </LoadingButton>
            </Box>
          </FormProvider>
        </ModalDialog>
      </Modal>
    </>
  );
}
