import React, { useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  InputAdornment,
  IconButton,
  TextField
} from '@mui/material';
import { LoadingButton as _LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLoginUserMutation } from "../../store/api/auth/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { loginSchema, LoginInput } from "../../validation-schemas/login.validation";
import './login.css';
import {RoleEnum} from "../../shared/role.enum";

const LoadingButton = styled(_LoadingButton)`
  padding: 0.6rem 0;
  background-color: #15112B;
  color: #D1B657;

  &:hover {
    background-color: #15112B;
    transform: translateY(-1px);
  }
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  color: #2363eb;
  &:hover {
    text-decoration: underline;
  }
`;

export function LoginPage() {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const methods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  const navigate = useNavigate();
  const location = useLocation();

  const [loginUser, LoginUserResponse] = useLoginUserMutation();

  useEffect(() => {
    if (LoginUserResponse.isSuccess) {
      toast.success('You successfully logged in');
      if (LoginUserResponse.data.role === RoleEnum.ADMIN) {
        navigate('/admin');
      } else if(LoginUserResponse.data.role === RoleEnum.COMPANY_ADMIN) {
        navigate('/managers');
      } else {
        navigate('/');
      }
    }
    if (LoginUserResponse.isError) {
      const error = LoginUserResponse.error as any;
      toast.error(error.data.message);
    }
  }, [
    LoginUserResponse.isSuccess,
    LoginUserResponse.isError,
    LoginUserResponse.error
  ]);

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      methods.reset();
    }
  }, [methods.formState.isSubmitSuccessful]);

  const errorMessage = (methods: any, key: string) => {
    if(methods.formState.errors[key]) {
      return methods.formState.errors[key].message;
    }
    return '';
  }

  const onSubmitHandler: SubmitHandler<LoginInput> = (values) => {
    loginUser(values);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#2363eb',
      }}
      className={"login-wrapper"}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          component='form'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
          noValidate
          autoComplete='off'
          maxWidth='27rem'
          width='100%'
          sx={{
            backgroundColor: '#fff',
            p: { xs: '1rem', sm: '2rem' },
            borderRadius: 1,
          }}
        >
          <Typography
            textAlign='center'
            component='h1'
            sx={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: "34px",
              mb: 2,
              letterSpacing: 1,
            }}
          >
            Login
          </Typography>
          <TextField
            type="email"
            id="email-input"
            fullWidth
            label="Email"
            variant="outlined"
            sx={{ mb: "2rem" }}
            {...methods.register("email")}
            error={!!methods.formState.errors.email}
            helperText={errorMessage(methods, "email")}
          />
          <TextField
            type={showPassword ? 'text' : 'password'}
            id="password-input"
            fullWidth
            label="Password"
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>,
            }}
            sx={{ mb: "2rem" }}
            {...methods.register("password")}
            error={!!methods.formState.errors.password}
            helperText={errorMessage(methods, "password")}
          />
          <LoadingButton
            variant='contained'
            fullWidth
            disableElevation
            type='submit'
            loading={LoginUserResponse.isLoading}
          >
            Login
          </LoadingButton>

          <Typography
            sx={{ fontSize: '0.9rem', mt: '1.5rem', textAlign: 'center' }}
          >
            <LinkItem to='/forgot-password' style={{ color: '#333' }}>
              Forgot Password?
            </LinkItem>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
