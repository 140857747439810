import { Unity, useUnityContext } from "react-unity-webgl";
import { FullScreenLoaderComponent } from "../../../components/full-screen-loader.component";
import { useEffect } from "react";

export interface IUnityBuildData {
  DataFile: string;
  JSFile: string;
  LoaderFile: string;
  WasmFile: string;
}

export const UnityContainerComponent = ({ unityBuildFilesList, customEvent, finishMeeting }: any) => {
  const baseUrl = 'https://mivr-builds.s3.eu-north-1.amazonaws.com/WebGL/production/Build/'
  const {
    unityProvider,
    isLoaded,
    unload,
  } = useUnityContext({
    loaderUrl: `${baseUrl}${unityBuildFilesList.LoaderFile}`,
    dataUrl: `${baseUrl}${unityBuildFilesList.DataFile}`,
    frameworkUrl: `${baseUrl}${unityBuildFilesList.JSFile}`,
    codeUrl: `${baseUrl}${unityBuildFilesList.WasmFile}`,
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  useEffect(() => {
    if (customEvent) {
      console.log('Unloading...');
      unload().then(() => {
        console.log('Container was unloaded');
        finishMeeting();
      });
    }
  }, [
    customEvent,
  ]);

  return (
    <div className="flex justify-center items-center w-full h-[100vh]">
      {isLoaded === false && (
        <FullScreenLoaderComponent />
      )}
      <Unity
        className={'w-full h-[100vh]'}
        unityProvider={unityProvider}
      />
    </div>
  );
};
