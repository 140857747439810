import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector} from "react-redux";

// API`s
import { accountApi } from "./api/account/account";
import { authApi } from "./api/auth/auth";
import { usersApi } from "./api/users/users";
import { companiesApi } from "./api/companies/company";
import { contentsApi } from "./api/contents/content";
import { templatesApi } from "./api/tempates/template";
import { meetingsApi } from "./api/meetings/meeting";
import { locationsApi } from "./api/locations/location";
import { meetingAccessesApi } from "./api/meeting-accesses/meeting-accesses";

// features
import userReducer from './features/userSlice';
import appReducer from './features/appSlice';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,
    [contentsApi.reducerPath]: contentsApi.reducer,
    [meetingsApi.reducerPath]: meetingsApi.reducer,
    [locationsApi.reducerPath]: locationsApi.reducer,
    [meetingAccessesApi.reducerPath]: meetingAccessesApi.reducer,
    userState: userReducer,
    appState: appReducer,
  },

  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      accountApi.middleware,
      usersApi.middleware,
      companiesApi.middleware,
      templatesApi.middleware,
      contentsApi.middleware,
      meetingsApi.middleware,
      locationsApi.middleware,
      meetingAccessesApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
