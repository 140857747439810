import * as React from 'react';
import { useParams } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemButton,
  ListItemIcon, Button, TextField, Select, MenuItem
} from "@mui/material";
import { ManageAccounts } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ITransformedUser } from '../../store/api/users/interfaces/transformed-user.interface';
import {useGetUserByIdQuery, useUpdateUserMutation} from '../../store/api/users/users';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import BadgeIcon from '@mui/icons-material/Badge';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import FaceIcon from '@mui/icons-material/Face';
import { IUpdateManager } from "../../store/api/users/interfaces/update-manager.interface";
import { toast } from "react-toastify";
import { IAvatar } from "../users/users.page";
import { UserMeetingsTablePage } from "../users/components/user-meetings-table.page";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';

export function ManagerPage() {
  const id = useParams<{ id: string }>().id as string;

  const [manager, setManager] = useState<ITransformedUser>();

  const GetManagerResponse = useGetUserByIdQuery(id);
  const [updateManager, UpdateManagerResponse] = useUpdateUserMutation();
  const [avatars] = React.useState<IAvatar[]>([
    { id: 1, label: 'Atelia', value: 'https://models.readyplayer.me/6501a7b246ad17f8b73279f8.glb', icon: 'https://readyplayer.me/gallery/6501a7b246ad17f8b73279f8' },
    { id: 2, label: 'Aurilia', value: 'https://models.readyplayer.me/6501a8ae46ad17f8b7327c59.glb', icon: 'https://readyplayer.me/gallery/6501a8ae46ad17f8b7327c59' },
    { id: 3, label: 'Dmitriy', value: 'https://models.readyplayer.me/6501a0f3613d018699346ac4.glb', icon: 'https://readyplayer.me/gallery/6501a0f3613d018699346ac4' },
    { id: 4, label: 'Derek', value: 'https://models.readyplayer.me/65019c03613d018699346389.glb', icon: 'https://readyplayer.me/gallery/65019c03613d018699346389' },
  ]);

  useEffect(() => {
    if (GetManagerResponse.data) {
      setManager(GetManagerResponse.data);
    }
    if (UpdateManagerResponse.data) {
      setManager(UpdateManagerResponse.data);
      toast.success('Manager details was updated successfully');
    }
  }, [GetManagerResponse.data, UpdateManagerResponse.data]);

  const update = () => {
    const payload: IUpdateManager = {
      firstName: manager.firstName,
      nickName: manager.nickName,
      avatar: manager.avatar,
      phone: manager.phone,
    }
    updateManager({
      id,
      query: payload,
    });
  }

  const block = () => {
    const payload: IUpdateManager = {
      isBlock: !manager.isBlock,
    }
    updateManager({
      id,
      query: payload,
    });
  }

  const updateManagerDetails = (newValue: string, key: string) => {
    setManager((prevManager) => {
      if (prevManager) {
        return { ...prevManager, [key]: newValue };
      }
      return prevManager;
    });
  };

  const handleAvatarChange = (e: any) => {
    updateManager({
      id,
      query: { avatar: e.target.value },
    });
  }

  return (
    <>
      { manager && (
        <Box className={'flex flex-row bg-gray-100 p-5 h-[87vh]'}>
          <Box className={'shadow-2xl mr-1 px-5 py-10 basis-4/12'}>
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ManageAccounts />
                  </ListItemIcon>
                  <ListItemText
                    primary={manager.firstName}
                  />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ContactMailIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={manager.email}
                  />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <BadgeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={manager.nickName}
                  />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ContactPhoneIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={manager.phone}
                  />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <FaceIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={avatars.find((a) => a.value === manager.avatar) ? avatars.find((a) => a.value === manager.avatar)?.label : 'Custom avatar'}
                  />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    { manager.isBlock ? <PersonOffIcon /> : <PersonIcon /> }
                  </ListItemIcon>
                  <ListItemText
                    primary={manager.isBlock ? "Blocked" : "Active"}
                  />
                </ListItemButton>
              </ListItem>
              <Divider/>
            </List>
          </Box>
          <Box className={'shadow-2xl ml-1 px-5 py-5 basis-8/12 flex flex-col justify-between overflow-auto'}>
            <Box>
              <Box className={'flex w-full h-full'}>
                <Box className={'flex flex-col w-full'}>
                  <Box className={'flex flex-row mb-5'}>
                    <Box className={'basis-6/12 pr-1'}>
                      <TextField
                        size='small'
                        type="text"
                        id="user-first-name"
                        fullWidth
                        label="Edit user first name"
                        variant="outlined"
                        value={manager.firstName ? manager.firstName : 'No data'}
                        onChange={(e) => updateManagerDetails(e.target.value, 'firstName')}
                      />
                    </Box>
                    <Box className={'basis-6/12 pl-1'}>
                      <TextField
                        size='small'
                        type="text"
                        id="user-nick-name"
                        fullWidth
                        label="Edit user nick name"
                        variant="outlined"
                        value={manager.nickName ? manager.nickName : 'No data'}
                        onChange={(e) => updateManagerDetails(e.target.value, 'nickName')}
                      />
                    </Box>
                  </Box>
                  <Box className={'flex flex-row mb-5'}>
                    <Box className={'basis-6/12 pr-1'}>
                      <TextField
                        size='small'
                        type="text"
                        id="user-phone"
                        fullWidth
                        label="Edit user contact"
                        variant="outlined"
                        value={manager.phone ? manager.phone : 'No data'}
                        onChange={(e) => updateManagerDetails(e.target.value, 'phone')}
                      />
                    </Box>
                    <Box className={'basis-6/12 pl-1'}>
                      <Select
                        size='small'
                        fullWidth
                        value={avatars.find((a) => a.value === manager.avatar) ? avatars.find((a) => a.value === manager.avatar)?.value : ""}
                        onChange={handleAvatarChange}
                      >
                        { avatars && avatars.map((avatar) => (
                          <MenuItem
                            key={avatar.id}
                            value={avatar.value}
                          >
                            {avatar?.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                  <Box className={'flex flex-row mb-5'}>
                    <Box className={'basis-6/12 pr-1'}>
                      <TextField
                        size='small'
                        type="text"
                        id="custom-avatar"
                        fullWidth
                        label="Custom avatar"
                        variant="outlined"
                        value={manager.avatar ? manager.avatar : 'No data'}
                        onChange={(e) => updateManagerDetails(e.target.value, 'avatar')}
                      />
                    </Box>
                    <Box className={'basis-6/12 pl-1'}>
                    </Box>
                  </Box>
                  <Box>
                    <UserMeetingsTablePage />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={'flex justify-end w-full mt-5'}>
              <Button
                size="small"
                color="success"
                variant="outlined"
                onClick={update}
                sx={{ marginRight: '10px' }}
              >
                UPDATE
              </Button>
              <Button
                size="small"
                color={manager.isBlock ? "warning" : "error"}
                variant="outlined"
                onClick={block}
              >
                { manager.isBlock ? "UNBLOCK" : "BLOCK" }
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
