import * as React from "react";
import { useState } from "react";
import { FullScreenLoaderComponent } from "../../components/full-screen-loader.component";
import { NoDevicePage } from "../guest/no-device.page";
import { MeetingContainerPage } from "./meeting-container.page";


export function MeetingContainerCheckDevicesPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [isUserHaveDevices, setDevices] = useState<boolean>(null);

  Promise.all([
    navigator.permissions.query({ name: 'microphone' as PermissionName }),
    navigator.permissions.query({ name: 'camera' as PermissionName })
  ]).then(([microphonePermission, cameraPermission]) => {
    if (microphonePermission.state === 'granted' && cameraPermission.state === 'granted') {
      setDevices(true);
      setLoading(false);
    } else {
      navigator.mediaDevices.getUserMedia({ audio: true, video: true })
        .then((stream) => {
          setDevices(true);
          setLoading(false);
        })
        .catch((error) => {
          setDevices(false);
          setLoading(false);
        });
    }
  });
  // navigator.mediaDevices.enumerateDevices()
  //   .then(devices => {
  //     const videoInputDevices = devices.filter(device => device.kind === 'videoinput' && device.deviceId && device.groupId);
  //     const audioInputDevices = devices.filter(device => device.kind === 'audioinput' && device.deviceId && device.groupId);
  //
  //     if (videoInputDevices.length === 0 && audioInputDevices.length === 0) {
  //       setDevices(false);
  //       setLoading(false);
  //     } else {
  //       setDevices(true);
  //       setLoading(false);
  //     }
  //   });

  return (
    <>
      { loading ? (
        <FullScreenLoaderComponent />
      ) : (
        <>
          { isUserHaveDevices ? (
            <MeetingContainerPage />
          ) : (
            <NoDevicePage />
          )}
        </>
      )}
    </>
  );
}
