import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAppState {
  loading: boolean;
}

const initialState: IAppState = {
  loading: false,
};

export const appSlice = createSlice({
  initialState,
  name: 'appSlice',
  reducers: {
    reset: () => initialState,
    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export default appSlice.reducer;

export const {
  reset,
  toggleLoading
} = appSlice.actions;
