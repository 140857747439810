import { object, string, TypeOf } from 'zod';

const createUserSchema = object({
  firstName: string()
    .min(1, 'Name is required')
    .max(20, 'Too long'),
  nickName: string()
    .min(1, 'Name is required')
    .max(20, 'Too long'),
  phone: string().optional(),
  email: string()
    .min(1, 'Email address is required')
    .email('Email Address is invalid'),
});

export type CreateUserInput = TypeOf<typeof createUserSchema>;

export { createUserSchema };
