import { Outlet } from 'react-router-dom';
import HeaderComponent from './header.component';
import "../../pages/home/home.css";

export function Layout() {
  return (
    <div className={"home-wrapper"}>
      <HeaderComponent />
      <div className={'outlet-wrapper'}>
        <Outlet />
      </div>
    </div>
  );
}
