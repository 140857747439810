import { object, string, TypeOf } from 'zod';

const createNpcSchema = object({
  nickName: string()
    .min(1, 'Name is required')
    .max(50, 'Too long'),
  npcRole: string()
    .min(1, 'Name is required')
    .max(50, 'Too long'),
  npcRules: string()
    .min(1, 'Name is required')
    .max(50, 'Too long'),
});

export type CreateNpcInput = TypeOf<typeof createNpcSchema>;

export { createNpcSchema };
