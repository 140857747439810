import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemButton,
  ListItemIcon, Button, TextField, Tooltip, DialogContentText, MenuItem, Select
} from "@mui/material";
import { Email, ManageAccounts } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ITransformedUser } from '../../store/api/users/interfaces/transformed-user.interface';
import { useCompaniesListQuery } from "../../store/api/companies/company";
import { useDeleteUserMutation, useGetUserByIdQuery, useUpdateUserMutation } from '../../store/api/users/users';
import { ICompany } from "../../store/api/companies/interfaces/company.interface";
import BadgeIcon from '@mui/icons-material/Badge';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { IUpdateUser } from "../../store/api/users/interfaces/update-user.interface";
import ToastMessages from "../../constants/toast.messages";
import { toast } from "react-toastify";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { UserMeetingsTablePage } from "./components/user-meetings-table.page";
import {IAvatar} from "./users.page";

export function UserPage() {
  const id = useParams<{ id: string }>().id as string;
  const navigate = useNavigate();

  const [user, setUser] = useState<ITransformedUser>();
  const [companies, setCompanies] = useState<ICompany[]>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const GetUserResponse = useGetUserByIdQuery(id, { refetchOnMountOrArgChange: true });
  const GetCompaniesResponse = useCompaniesListQuery(undefined, { refetchOnMountOrArgChange: true });

  const [updateUser, UpdateUserResponse] = useUpdateUserMutation();
  const [removeUser, RemoveUserResponse] = useDeleteUserMutation();

  const [avatars] = React.useState<IAvatar[]>([
    { id: 1, label: 'Atelia', value: 'https://models.readyplayer.me/6501a7b246ad17f8b73279f8.glb', icon: 'https://readyplayer.me/gallery/6501a7b246ad17f8b73279f8' },
    { id: 2, label: 'Aurilia', value: 'https://models.readyplayer.me/6501a8ae46ad17f8b7327c59.glb', icon: 'https://readyplayer.me/gallery/6501a8ae46ad17f8b7327c59' },
    { id: 3, label: 'Dmitriy', value: 'https://models.readyplayer.me/6501a0f3613d018699346ac4.glb', icon: 'https://readyplayer.me/gallery/6501a0f3613d018699346ac4' },
    { id: 4, label: 'Derek', value: 'https://models.readyplayer.me/65019c03613d018699346389.glb', icon: 'https://readyplayer.me/gallery/65019c03613d018699346389' },
  ]);

  useEffect(() => {
    if (GetUserResponse.isSuccess) {
      setUser(GetUserResponse.data);
    }
    if (GetCompaniesResponse.isSuccess) {
      setCompanies(GetCompaniesResponse.data);
    }
    if (UpdateUserResponse.isSuccess) {
      toast.success(ToastMessages.success);
      setUser(UpdateUserResponse.data);
    }
    if (RemoveUserResponse.isSuccess) {
      navigate('/users');
    }
  }, [
    GetUserResponse.isSuccess,
    GetUserResponse.data,
    GetCompaniesResponse.isSuccess,
    GetCompaniesResponse.data,
    UpdateUserResponse.isSuccess,
    UpdateUserResponse.data,
    RemoveUserResponse.isSuccess,
  ]);

  const update = () => {
    const payload: IUpdateUser = {
      firstName: user.firstName,
      nickName: user.nickName,
      phone: user.phone,
      avatar: user.avatar,
    }
    updateUser({
      id,
      query: payload,
    });
  }

  const updateUserDetails = (newValue: string, key: string) => {
    setUser((prevUser) => {
      if (prevUser) {
        return { ...prevUser, [key]: newValue };
      }
      return prevUser;
    });
  };

  const handleAvatarChange = (e: any) => {
    updateUser({
      id,
      query: { avatar: e.target.value },
    });
  }

  const handleClose = (isDelete: boolean) => {
    if (isDelete) {
      setOpenDeleteDialog(false);
      removeUser(id);
    } else {
      setOpenDeleteDialog(false);
    }
  };

  return (
    <>
      { user && companies && (
        <Box>
          <Box className={'flex flex-row bg-gray-100 p-5 h-[87vh]'}>
            <Box className={'shadow-2xl mr-1 px-5 py-10 basis-4/12'}>
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="User first name">
                      <ListItemIcon>
                      <ManageAccounts />
                    </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={user.firstName ? user.firstName : 'No data'}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="User nick name">
                      <ListItemIcon>
                      <BadgeIcon />
                    </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={user.nickName ? user.nickName : 'No data'}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="User email">
                      <ListItemIcon>
                      <Email />
                    </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={user.email ? user.email : 'No data'}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="User phone number">
                      <ListItemIcon>
                      <ContactPhoneIcon />
                    </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={user.phone ? user.phone : 'No data'}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Tooltip title="User phone number">
                      <ListItemIcon>
                        <ContactPhoneIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={avatars.find((a) => a.value === user.avatar) ? avatars.find((a) => a.value === user.avatar)?.label : 'Custom avatar'}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider/>
              </List>
            </Box>
            <Box className={'shadow-2xl ml-1 px-5 py-5 basis-8/12 flex flex-col justify-between overflow-auto'}>
              <Box>
                <Box className={'flex w-full h-full'}>
                  <Box className={'flex flex-col w-full'}>
                    <Box className={'flex flex-row mb-5'}>
                      <Box className={'basis-6/12 pr-1'}>
                        <TextField
                          size='small'
                          type="text"
                          id="user-first-name"
                          fullWidth
                          label="Edit user first name"
                          variant="outlined"
                          value={user.firstName ? user.firstName : 'No data'}
                          onChange={(e) => updateUserDetails(e.target.value, 'firstName')}
                        />
                      </Box>
                      <Box className={'basis-6/12 pl-1'}>
                        <TextField
                          size='small'
                          type="text"
                          id="user-nick-name"
                          fullWidth
                          label="Edit user nick name"
                          variant="outlined"
                          value={user.nickName ? user.nickName : 'No data'}
                          onChange={(e) => updateUserDetails(e.target.value, 'nickName')}
                        />
                      </Box>
                    </Box>
                    <Box className={'flex flex-row mb-5'}>
                      <Box className={'basis-6/12 pr-1'}>
                        <TextField
                          size='small'
                          type="text"
                          id="user-phone"
                          fullWidth
                          label="Edit user contact"
                          variant="outlined"
                          value={user.phone ? user.phone : 'No data'}
                          onChange={(e) => updateUserDetails(e.target.value, 'phone')}
                        />
                      </Box>
                      <Box className={'basis-6/12 pl-1'}>
                        <Select
                          size='small'
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={avatars.find((a) => a.value === user.avatar) ? avatars.find((a) => a.value === user.avatar)?.value : ""}
                          label="Avatar"
                          onChange={handleAvatarChange}
                        >
                          { avatars && avatars.map((avatar) => (
                            <MenuItem
                              key={avatar.id}
                              value={avatar.value}
                            >
                              {avatar?.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                    <Box className={'flex flex-row mb-5'}>
                      <Box className={'basis-6/12 pr-1'}>
                        <TextField
                          size='small'
                          type="text"
                          id="custom-avatar"
                          fullWidth
                          label="Custom avatar"
                          variant="outlined"
                          value={user.avatar ? user.avatar : 'No data'}
                          onChange={(e) => updateUserDetails(e.target.value, 'avatar')}
                        />
                      </Box>
                      <Box className={'basis-6/12 pl-1'}>
                      </Box>
                    </Box>
                    <Box>
                      <UserMeetingsTablePage />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={'flex justify-end w-full mt-5'}>
                <Button
                  size="small"
                  color="success"
                  variant="outlined"
                  onClick={update}
                  sx={{ marginRight: '10px' }}
                >
                  UPDATE
                </Button>
                <Button
                  size="small"
                  color="error"
                  variant="outlined"
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  DELETE
                </Button>
              </Box>
            </Box>
          </Box>
          <Dialog
            open={openDeleteDialog}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure to delete this this user?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deleting this user will lead to its complete loss and it will be impossible to restore it
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleClose(false)}
                sx={{
                  backgroundColor: '#15112B',
                  color: '#D1B657'
                }}
              >
                NO
              </Button>
              <Button
                onClick={() => handleClose(true)}
                sx={{
                  backgroundColor: '#15112B',
                  color: '#D1B657'
                }}
              >
                YES
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
}
